import React, { FC } from 'react'
import { Link } from 'gatsby-plugin-intl'
import { GatsbyImage } from 'gatsby-plugin-image'

import Hero from 'src/components/Hero'

import { title } from '../styles/styled'
import * as S from '../styles/quiz'

import { getArtDirection } from 'src/utils/shared'

import { QuizSectionProps } from './types/quiz'

const INTL_TAG = 'homepage.quiz'

const Quiz: FC<QuizSectionProps> = ({ intl, img }) => (
  <Hero hero="is-clipped" container="has-text-centered" heroBodyStyles={S.heroBodyStyles}>
    <S.QuizSectionTitle className="title is-inline-block" css={title('orange')}>
      {intl.formatMessage({ id: `${INTL_TAG}.header` })}
    </S.QuizSectionTitle>

    <p
      className="has-margin-bottom-2 has-padding-horizontal-2__mobile has-text-left-mobile"
      css={{ whiteSpace: 'pre-line' }}
    >
      {intl.formatMessage({ id: `${INTL_TAG}.desc` })}
    </p>

    <S.ImageSection>
      <GatsbyImage css={S.bannerImgStyles} image={getArtDirection(img.image)} alt={img.alt} />
      <S.ImageContent className="container">
        <S.ImageTextDesc>{intl.formatMessage({ id: `${INTL_TAG}.find_your_vegan` })} <br/>
        {intl.formatMessage({ id: `${INTL_TAG}.haircare_routine` })}
        </S.ImageTextDesc>
      </S.ImageContent>
    </S.ImageSection>

    <p className="has-margin-top-2 has-padding-horizontal-2__mobile">
      <Link
        data-test="home-start-quiz"
        to="/hair-quiz/"
        className="button is-outlined is-primary is-uppercase is-fullwidth-mobile"
      >
        {intl.formatMessage({ id: `${INTL_TAG}.button_start_quiz` })}
      </Link>
    </p>
  </Hero>
)

export default Quiz
