import { css } from '@emotion/react'

import { mq } from 'src/themes'

export const staticPos = css`
  .container {
    ${mq({
      position: [undefined, 'static']
    })}
  }
`

export const gatsbyImage = css`
  ${mq({
    margin: ['0 -1.5rem -4.5rem 1.5rem', '0 0 -4.5rem 0', '0 0 -5.75rem 0']
  })}
`

export const handsImg = css`
  ${mq({
    margin: ['-1.5rem -1.5rem 0.75rem -1.5rem', '-3rem 0'],
    width: [undefined, '45%', '45%', '40%', 615],
    position: [undefined, 'absolute !important'],
    right: [undefined, 0]
  })}
`

export const titleGR = css`
  :lang(el-gr) {
    ${mq({
      marginTop: ['4.5rem', '6rem']
    })}
  }
`

export const svgIcons = css`
  ${mq({
    textAlign: ['center', 'left']
  })}
`
