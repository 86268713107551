const DISABLED_MENU_ITEMS = [
  {
    // weTalk
    langcode: 'en-gb',
    uri: 'entity:node/74'
  }
]

const DISABLED_ROOT_PATHS = ['/wetalk/']
const UNFILLED_NODE_URI_ALIAS = '/node/'

export const getMenuItems = ({ navItems, bannerUri, locale }) => {
  if (DISABLED_MENU_ITEMS.find((item) => item.uri === bannerUri && locale === item.langcode)) return {}

  const banner = navItems.find(
    ({ link }) =>
      link.uri === bannerUri && !link.parent && (link.uri === 'entity:node/1' || !link.uri_alias?.includes('/node/'))
  )
  const items = navItems.filter(
    (node) => node.link.uri_alias.startsWith(banner?.link.uri_alias) && node.id !== banner?.id && !node.link.uri_alias?.includes('/node/')
  )

  return {
    bannerTitle: banner?.title,
    bannerLink: banner?.link.uri_alias,
    items
  }
}

const verifyDataPresence = (alias) => !alias?.includes(UNFILLED_NODE_URI_ALIAS)

export const findyByLangcode = ({ array, locale }) =>
  array.filter(({ langcode, link }) => langcode === locale && verifyDataPresence(link?.uri_alias))

export const redirectToLang = ({ langCode, pageUrls }) => {
  const properUrl = pageUrls.find(({ hreflang }) => hreflang === langCode)?.href
  const isDisabledOnRoot = !!DISABLED_ROOT_PATHS.find(
    (path) => path === pageUrls.find((url) => url.hreflang === 'en-gb')?.href
  )

  switch (true) {
    case typeof window === 'undefined':
      break
    case !properUrl || isDisabledOnRoot:
      window.location.pathname = langCode
      break
    default:
      window.location.href = `/${langCode}${properUrl}`
  }
}
