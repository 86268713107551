import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { breakpoints } from 'src/themes'

export const ImageSection = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
`

export const bannerImgStyles = css`
  @media (max-width: ${breakpoints.tablet}) {
    height: 0 !important;
    padding-bottom: 68%;
  }
`

export const textStyles = css`
    z-index: 2;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height:410px;
    justify-content: center;
    @media (max-width: ${breakpoints.tablet}) {
    position: initial;
    height:auto;
    }
`

export const heroBodyStyles = css`
  @media (max-width: ${breakpoints.tablet}) {
    padding: 1.5rem 0;
  }
`