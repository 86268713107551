import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import _debounce from 'lodash.debounce'
import PropTypes from 'prop-types'

import CounterHero from 'src/components/CounterHero'



const BottleCounter = () => {
  const intl = useIntl()

  const { partnerImgLocalFile } = useStaticQuery(
    graphql`
      query BottleCounter {
        partnerImgLocalFile: file(relativePath: { eq: "plasticbank.png" }) {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 359
              quality: 50
              webpOptions: { quality: 55 }
              avifOptions: { quality: 48 }
              outputPixelDensities: [0.25, 0.5, 0.6, 0.75, 1]
            )
          }
        }
      }
    `
  )

  const partnerImage = {
    localFile: partnerImgLocalFile
  }

  useEffect(() => {
    const postBodySize = () => {
      window.parent.postMessage(
        {
          type: 'resize',
          value: window.document.body.offsetHeight + 30
        },
        '*'
      )
    }
    const trackBodyHeight = _debounce(postBodySize)

    window.addEventListener('resize', trackBodyHeight)

    const sendTimedOutHeight = setTimeout(() => {
      postBodySize()
    }, 100)

    return () => {
      window.removeEventListener('resize', trackBodyHeight)
      clearTimeout(sendTimedOutHeight)
    }
  }, [])

  return (
    <>
      <CounterHero intl={intl} image={partnerImage} />
    </>
  )
}

BottleCounter.propTypes = {
  pageContext: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default BottleCounter
