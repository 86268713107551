/* eslint-disable no-console */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'
import SearchForm from './components/SearchForm'

import useTracking from 'src/hooks/useTracking'
import { createPageData } from 'src/utils/tracking'
import { getMetaTitle } from 'src/utils/shared'

const Search = ({ pageContext, data }) => {
  const { locale, formatMessage } = useIntl()

  const {
    nodePage: { title, field_breadcrumb: breadcrumbs, field_metatag_normalized: metaTags }
  } = data

  useTracking({
    data: createPageData({
      pageId: 'search',
      breadCrumbs: 'home:search',
      pageType: 'search page',
      instanceName: 'search page',
      primaryCategory: 'search',
      language: locale,
      pageTitle: getMetaTitle(metaTags),
      pageName: pageContext.slug
    })
  })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
        <div className="columns has-margin-bottom-2">
          {title && <h1 className="column is-3-tablet is-2-widescreen title__page">{title}</h1>}
        </div>

        <SearchForm formatMessage={formatMessage} locale={locale} />
      </Hero>
    </Layout>
  )
}

Search.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    nodePage: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.shape({ value: PropTypes.string }),
      field_breadcrumb: PropTypes.arrayOf(PropTypes.object),
      relationships: PropTypes.shape({
        field_sections: PropTypes.arrayOf(
          PropTypes.shape({
            relationships: PropTypes.shape({
              field_r_related_page: PropTypes.arrayOf(PropTypes.object)
            })
          })
        )
      }),
      field_metatag_normalized: PropTypes.arrayOf(PropTypes.object)
    })
  })
}

export const query = graphql`
  query Search($slug: String!, $langKey: String) {
    nodePage(field_component: { eq: "search" }, path: { alias: { eq: $slug } }, langcode: { eq: $langKey }) {
      ...PageDataFragment
    }
  }
`

export default Search
