import { css } from '@emotion/react'

import { breakpoints, colors, lineHeights, sizes, frame, zIndexes } from 'src/themes'

export const title = (titleColor) => css`
  white-space: pre-line;
  z-index: ${zIndexes.zIndex5};
  font-size: ${sizes.size2};
  line-height: ${lineHeights.small};
  ${frame({ color: colors[titleColor] })}

  @media(min-width: ${breakpoints.desktop}) {
    font-size: ${sizes.size1};
  }
`
