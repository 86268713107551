import React, { FC } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import { Link } from 'gatsby-plugin-intl'

import { formImageBaseData } from 'src/utils/shared'
import useDeviceDetect from 'src/hooks/useDeviceDetect'
import { findProductImage } from './utils'

import * as S from './styled'
import { cardCSS } from 'src/themes'

import { ProductCardProps, CardWrapperProps } from './types'

const CardWrapper: FC<CardWrapperProps> = ({ children, className = '', isClickable, path, disableLinking }) =>
  isClickable && !disableLinking ? (
    <Link css={cardCSS} to={path.alias} className={`card ${className}`} data-test="product-card">
      {children}
    </Link>
  ) : (
    <S.Card className={`card ${className}`}>{children}</S.Card>
  )

const ProductCard: FC<ProductCardProps> = ({
  title,
  field_p_subtitle,
  relationships,
  className = '',
  alternativeStyle,
  path,
  isClickable,
  intl,
  disableLinking,
  drupal_internal__nid
}) => {
  const { isMobile } = useDeviceDetect()
  const getBtnClassName = classNames({
    'button is-outlined is-uppercase is-fullwidth': true,
    'is-white-alpha': alternativeStyle,
    'is-black': !alternativeStyle
  })

  const imageData = formImageBaseData({
    image: findProductImage(relationships?.field_sections[0]?.relationships?.field_p_variant)?.relationships
      .field_pv_image[0], //eslint-disable-line max-len
    sizes: '(min-width: 1008px) 25vw, (min-width: 768px) 33.3vw, 50vw'
  })

  const hoverImageData = formImageBaseData({
    image: relationships?.field_p_image_on_hover,
    sizes: '(min-width: 1008px) 25vw, (min-width: 768px) 33.3vw, 50vw'
  })

  const shouldRenderHover = !isMobile && !!hoverImageData

  const locale = intl.locale

  return (
    <CardWrapper className={className} isClickable={isClickable} path={path} disableLinking={disableLinking}>
      {!!imageData && (
        <div className="is-flex is-flex-centered">
          {shouldRenderHover && <GatsbyImage css={S.primaryImageStyles} {...hoverImageData} />}
          <GatsbyImage css={shouldRenderHover && S.secondaryImageStyles} {...imageData} />
        </div>
      )}
      <S.CardContent className="card-content" data-test="product-card">
        <div className="media-content">
          <S.CardTitle alternativeStyle={alternativeStyle}>{title}</S.CardTitle>
          <S.CardDescription alternativeStyle={alternativeStyle}>{field_p_subtitle}</S.CardDescription>
        </div>
      </S.CardContent>
      {!disableLinking && (
        <S.CardFooter className="card-footer">
          {isClickable ? (
            <button className={getBtnClassName}> {intl.formatMessage({ id: `shared.product_card_btn` })}</button>
          ) : (
            <Link to={path.alias} className={getBtnClassName}>
              {intl.formatMessage({ id: `shared.product_card_btn` })}
            </Link>
          )}
        </S.CardFooter>
      )}
    </CardWrapper>
  )
}

export default ProductCard
