import React, { memo, useRef, useState, FC } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay, Navigation, Thumbs, Controller } from 'swiper'
import { WhyDidYouRenderOptions } from '@welldone-software/why-did-you-render'
import 'swiper/swiper.scss'

import Arrow from './Arrow'
import ProductPagination from './ProductPagination'
import useMatchMedia from 'src/hooks/useMatchMedia'

import { mapVideosToDotThumbnails } from './utils'

import { SwiperCarouselProps, CustomSwiperOptions } from './types'

import * as S from './styled'

SwiperCore.use([Pagination, Autoplay, Navigation, Thumbs, Controller])

const SwiperCarousel: FC<SwiperCarouselProps> & WhyDidYouRenderOptions = ({
  config = {},
  children,
  isBanner,
  isProductCarousel,
  isOurTeam,
  navLocation,
  useTracking,
  productImages = [],
  productVideos = []
}) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const prevThumbRef = useRef(null)
  const nextThumbRef = useRef(null)
  const isDesktop = useMatchMedia('(min-width: 769px)')

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>(null)

  const dotImages = [...productImages, ...mapVideosToDotThumbnails(productVideos)]

  const onInit = (swiper: SwiperCore): void => {
    if (typeof swiper.params.navigation !== 'boolean') {
      const navigation = swiper.params.navigation

      if (isProductCarousel) {
        navigation.prevEl = prevThumbRef.current
        navigation.nextEl = nextThumbRef.current

        return
      }

      navigation.prevEl = prevRef.current
      navigation.nextEl = nextRef.current
    }
  }

  const settings: CustomSwiperOptions = {
    loop: typeof window !== 'undefined' && children.length > 1,
    speed: 500,
    watchSlidesVisibility: true,
    wrapperTag: 'ul',
    slidesPerView: 1,
    threshold: 20,
    spaceBetween: 30,
    lazy: true,
    navigation: !isBanner && {
      prevEl: isProductCarousel ? prevThumbRef.current : prevRef.current,
      nextEl: isProductCarousel ? nextThumbRef.current : nextRef.current
    },
    onInit: isBanner ? () => {} : onInit,
    observer: true,
    thumbs: isProductCarousel
      ? {
          swiper: thumbsSwiper
        }
      : {},
    breakpoints: {
      480: {
        slidesPerView: 2,
        spaceBetween: 15
      },
      768: {
        slidesPerView: 3
      },
      1407: {
        slidesPerView: 4,
        spaceBetween: 30
      }
    },
    ...config
  }

  return (
    <div css={S.swiperStyle({ productBannerDesktop: isProductCarousel && isDesktop })}>
      {!!isProductCarousel && (
        <div css={S.thumbsCarouselWrapperStyles}>
          <Arrow
            direction={isDesktop ? 'up' : 'left'}
            ref={prevThumbRef}
            useTracking={useTracking}
            navLocation={navLocation}
            productGalleryNav
          />
          <Swiper
            onSwiper={setThumbsSwiper}
            watchSlidesVisibility
            watchSlidesProgress
            loop={false}
            slidesPerView={6}
            spaceBetween={0}
            direction="vertical"
            className="dots-container is-hidden-mobile"
            threshold={15}
            observer
          >
            {dotImages.map((image, i) => (
              <SwiperSlide key={i} className="swiper-dots">
                <ProductPagination index={i} productImage={image} navLocation={navLocation} PhotoDot={S.PhotoDot} />
              </SwiperSlide>
            ))}
          </Swiper>
          <Arrow
            direction={isDesktop ? 'down' : 'right'}
            ref={nextThumbRef}
            useTracking={useTracking}
            navLocation={navLocation}
            productGalleryNav
          />
        </div>
      )}
      <Swiper
        {...settings}
        observer={true}
        watchSlidesProgress
        css={S.mainSwiperStyles({ isProductCarousel, isDesktop })}
      >
        {!isBanner && !isProductCarousel && (
          <>
            <Arrow
              direction="left"
              ref={prevRef}
              useTracking={useTracking}
              navLocation={navLocation}
              className={`swiper-arrow ${isOurTeam ? 'swiper-prev-one-third' : 'swiper-prev'}`}
            />
            <Arrow
              direction="right"
              ref={nextRef}
              useTracking={useTracking}
              navLocation={navLocation}
              className={`swiper-arrow ${isOurTeam ? 'swiper-next-one-third' : 'swiper-next'}`}
            />
          </>
        )}
        {children}
      </Swiper>
    </div>
  )
}

export default memo(SwiperCarousel)

SwiperCarousel.whyDidYouRender = {
  logOnDifferentValues: true
}
