import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { useStaticQuery, graphql } from 'gatsby'

import { SocialIcon } from 'src/themes'

const renderIconsList = ({ data, iconsPath }) =>
  data.map(({ id, link, link_override }) => {
    const {
      uri,
      options: { fa_icon, attributes }
    } = link

    return (
      <a
        key={id}
        href={link_override?.uri || uri}
        {...attributes}
        className="is-inline-block is-size-4"
        rel="noopener noreferrer"
        tabIndex={isMobile ? '-1' : '0'}
        data-test="social-link"
      >
        <SocialIcon isYoutube={fa_icon.includes('youtube')} isFacebook={fa_icon.includes('facebook')}>
          <use xlinkHref={`${iconsPath}#${fa_icon}`} />
        </SocialIcon>
      </a>
    )
  })

const SocialMedia = ({ className = '', data }) => {
  const {
    file: { publicURL: iconsPath }
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "social-icons.svg" }) {
          publicURL
        }
      }
    `
  )

  return <nav className={className}>{renderIconsList({ data, iconsPath })}</nav>
}

export default SocialMedia

SocialMedia.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any).isRequired
}
