import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Link } from 'gatsby-plugin-intl'
import Hero from 'src/components/Hero'
import PictureCard from 'src/components/PictureCard'
import useIngredientsPDF from 'src/hooks/useIngredientsPDF'

import { StyledIcon } from 'src/themes'

const INTL_TAG = 'product_page.ingredients'

const Ingredients = ({ ingredients, intl }) => {
  const [isOpen, toggleDropdown] = useState(false)

  const pdfURL = useIngredientsPDF({ langcode: intl.locale })
  const {
    file: { publicURL: iconsPath }
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "sign-icons.svg" }) {
          publicURL
        }
      }
    `
  )

  const handleDropdownClick = () => {
    toggleDropdown((prevState) => !prevState)
  }

  const ingredientList = ingredients.find(({ field_p_ingredients_all }) => field_p_ingredients_all)
    ?.field_p_ingredients_all

  return (
    <Hero>
      <div className="is-one-quarter has-margin-bottom-2">
        <b className="is-uppercase is-hidden-tablet">{intl.formatMessage({ id: `${INTL_TAG}.hero_ingredient` })}</b>
        <b className="is-uppercase is-hidden-mobile">{intl.formatMessage({ id: `${INTL_TAG}.ingredients` })}</b>
      </div>
      <div className="columns is-vcentered">
        <div className="column">
          {ingredients.map(
            ({ relationships }) =>
              relationships?.field_p_ingredient_main &&
              (relationships?.field_p_ingredient_main?.hideBuild ? (
                <PictureCard
                  relationships={relationships?.field_p_ingredient_main?.relationships}
                  title={relationships?.field_p_ingredient_main?.title}
                  withBorder
                  alt={relationships?.field_p_ingredient_main?.field_i_image?.alt}
                />
              ) : (
                <Link to={relationships?.field_p_ingredient_main?.path?.alias}>
                  <PictureCard
                    relationships={relationships?.field_p_ingredient_main?.relationships}
                    title={relationships?.field_p_ingredient_main?.title}
                    withBorder
                    alt={relationships?.field_p_ingredient_main?.field_i_image?.alt}
                  />
                </Link>
              ))
          )}
        </div>
        <div className="column">
          <div className="is-hidden-mobile" dangerouslySetInnerHTML={{ __html: ingredientList.value }} />

          <div className="is-hidden-tablet has-border-top">
            <article className={`accordion message is-white ${isOpen ? 'is-opened' : ''}`}>
              <div
                className="message-header has-margin-vertical-1 has-padding-horizontal-0"
                onClick={handleDropdownClick}
              >
                <b className="is-uppercase">{intl.formatMessage({ id: `${INTL_TAG}.all_ingredients` })}</b>
                <StyledIcon css={{ width: '0.875em' }}>
                  <use xlinkHref={`${iconsPath}#chevron-down`} />
                </StyledIcon>
              </div>
              <div
                className="message-body has-padding-horizontal-0  has-padding-vertical-0"
                dangerouslySetInnerHTML={{ __html: ingredientList.value }}
              />
            </article>
          </div>
          {pdfURL && (
            <a
              href={pdfURL}
              target="_blank"
              rel="noopener noreferrer"
              className="button has-height-auto pre-lined is-primary is-uppercase has-margin-vertical-1"
            >
              {intl.formatMessage({ id: 'shared.know_more_ingredients' })}
            </a>
          )}
        </div>
      </div>
    </Hero>
  )
}

export default Ingredients

Ingredients.propTypes = {
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      field_p_ingredients_all: PropTypes.shape({
        value: PropTypes.string
      }),
      id: PropTypes.string,
      relationships: PropTypes.shape({
        field_p_ingredient_main: PropTypes.shape({
          title: PropTypes.string,
          relationships: PropTypes.object,
          path: PropTypes.shape({
            alias: PropTypes.string
          }),
          hideBuild: PropTypes.bool
        })
      })
    })
  ),
  intl: PropTypes.object
}
