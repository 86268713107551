import React, { useState, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import { Link } from 'gatsby-plugin-intl'
import { SwiperSlide } from 'swiper/react'

import Hero from 'src/components/Hero'
import SwiperCarousel from 'src/components/SwiperCarousel'
import Modal from 'src/components/Modal'
import Video from 'src/components/Video'

import useToggleModal from 'src/hooks/useToggleModal'
import trackAddToBasketClickEvent from 'src/dataLayer/ProductPage'
import useClientRerender from 'src/hooks/useClientRerender'

import BuyNowModal from './components/BuyNowModal'

import { formImageBaseData, getImageCarouselKey } from 'src/utils/shared'

import * as S from '../styles/productBanner'
import { playButtonStyles } from 'src/themes'

const INTL_TAG = 'product_page.banner'

const carouselConfig = {
  breakpoints: {
    481: {
      slidesPerView: 2,
      spaceBetween: 25,
      watchSlidesVisibility: true,
      centeredSlides: false
    },
    768: {
      slidesPerView: 1,
      watchSlidesVisibility: false,
      centeredSlides: true
    }
  }
}
const renderButton = ({ shopsList = [], intl, toggleModal, onClick }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleBtnClick = useCallback(() => {
    onClick(shopsList[0].field_wtb_link?.uri)
  }, [shopsList, onClick])

  if (!shopsList?.length) {
    return
  }

  if (shopsList?.length === 1) {
    return (
      <a
        onClick={handleBtnClick}
        href={shopsList[0]?.field_wtb_link?.uri}
        target="_blank"
        rel="noopener noreferrer"
        className="button is-size-6 is-primary is-uppercase is-fullwidth-mobile has-text-black"
        css={S.buttonStyle}
      >
        {intl.formatMessage({ id: `${INTL_TAG}.buy_now` })}
      </a>
    )
  }

  return (
    <button
      className="button is-size-6 is-primary is-uppercase is-fullwidth-mobile has-text-black"
      data-test="product-buy-now"
      onClick={toggleModal}
      css={S.buttonStyle}
    >
      {intl.formatMessage({ id: `${INTL_TAG}.buy_now` })}
    </button>
  )
}

const ProductBanner = ({
  title,
  description,
  productVariants = [],
  productVideos = [],
  crumbs,
  type,
  intl,
  id,
  sfPath
}) => {
  const {
    file: { publicURL: iconsPath }
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "sign-icons.svg" }) {
          publicURL
        }
      }
    `
  )

  const isClient = useClientRerender()
  const defaultVariant = productVariants.find(({ field_pv_default }) => field_pv_default) || productVariants[0]

  const [selectedProduct, setProduct] = useState(defaultVariant)
  const [videoUrl, setVideoUrl] = useState(null)

  const { isModalOpen, toggleModal } = useToggleModal()
  const { isModalOpen: isVideoOpen, toggleModal: toggleVideo } = useToggleModal()

  const handleVideoPlay = (videoUrl) => {
    if (!videoUrl) {
      return
    }

    setVideoUrl(videoUrl)
    toggleVideo()
  }

  if (!selectedProduct) {
    return null
  }

  const {
    field_pv_price: productPrice,
    field_pv_size: productSize,
    field_pv_image: imageAlt,
    relationships: { field_pv_image: productImages, field_pv_where_to_buy: shopsList }
  } = selectedProduct

  const handleDataLayer = (url) => {
    trackAddToBasketClickEvent({
      sku: id,
      name: title,
      size: productSize,
      price: productPrice,
      priCategory: crumbs[crumbs?.length - 2].crumbLabel,
      type,
      retailerName: url?.split('/')[2],
      retailerUrl: url
    })
  }
  return (
    <Hero hero="hero-product" heroBody="has-padding-top-4__nonmobile has-padding-top-2__mobile has-padding-bottom-2">
      <h1 className="title__page has-margin-bottom-2 is-hidden-tablet">{title}</h1>
      <div className="columns">
        <div className="column is-half">
          {!!productImages?.length && (
            <SwiperCarousel
              key={isClient}
              className="product-hero"
              productImages={productImages}
              productVideos={productVideos}
              isProductCarousel
              navLocation="product-page"
              config={carouselConfig}
            >
              {productImages.map((image, id) => (
                <SwiperSlide key={getImageCarouselKey(image)} className="swiper-product">
                  <GatsbyImage
                    {...formImageBaseData({ image })}
                    alt={imageAlt[id]?.alt}
                    style={{ width: '100%', display: 'inline-block' }}
                  />
                </SwiperSlide>
              ))}

              {productVideos.map((video) => (
                <SwiperSlide
                  key={getImageCarouselKey(video.relationships?.field_p_video_cover)}
                  className="swiper-product"
                >
                  {!video?.field_p_video_url?.uri ? (
                    <GatsbyImage
                      {...formImageBaseData({ image: video.relationships?.field_p_video_cover })}
                      alt={video.field_p_video_cover?.alt}
                      style={{ width: '100%', display: 'inline-block' }}
                    />
                  ) : (
                    <>
                      <GatsbyImage
                        {...formImageBaseData({ image: video.relationships?.field_p_video_cover })}
                        alt={video.field_p_video_cover?.alt}
                        style={{ width: '100%', display: 'inline-block' }}
                      />
                      <svg css={playButtonStyles} onClick={() => handleVideoPlay(video?.field_p_video_url?.uri)}>
                        <use xlinkHref={`${iconsPath}#play-circle-solid`} />
                      </svg>
                    </>
                  )}
                </SwiperSlide>
              ))}
            </SwiperCarousel>
          )}
        </div>
        <S.Description className="column">
          <h1 className="title__page has-margin-bottom-2 is-hidden-mobile" data-test="product-title">
            {title}
          </h1>
          <div className="has-margin-bottom-3__nonmobile" dangerouslySetInnerHTML={{ __html: description }} />
          <div className="buttons is-hidden-mobile has-margin-bottom-3">
            {productVariants.map(({ field_pv_size, ...rest }) => (
              <button
                key={field_pv_size}
                className={`button is-black is-size-6 ${
                  productSize !== field_pv_size && 'is-outlined has-text-weight-normal'
                }`}
                onClick={() => setProduct({ field_pv_size, ...rest })}
                data-test="product-size"
              >
                {field_pv_size}
              </button>
            ))}
          </div>

          <div className="columns is-mobile is-vcentered has-margin-bottom-1__mobile is-multiline">
            <div className="column is-hidden-tablet is-full-mobile">
              <div className="select is-fullwidth">
                <select
                  value={productSize}
                  onChange={(e) =>
                    setProduct(productVariants.find((variant) => variant.field_pv_size === e.target.value))
                  }
                >
                  {productVariants.map(({ field_pv_size }) => (
                    <option key={field_pv_size} value={field_pv_size}>
                      {field_pv_size}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="column column is-full-tablet is-full-mobile">
              <S.ButtonWrapper className="button-container">
                {renderButton({
                  shopsList,
                  intl,
                  toggleModal,
                  onClick: handleDataLayer
                })}

                {intl.locale === 'de-de' ? (
                  <button
                    className="button is-size-6 is-primary is-uppercase is-fullwidth-mobile has-text-black"
                    css={S.buttonStyle}
                  >
                    <a
                      className="is-primary is-uppercase is-fullwidth-mobile has-text-black"
                      href="https://salons.wedoact.com/salon-search?wedo-professional"
                    >
                      IM SALON KAUFEN
                    </a>
                  </button>
                ) : (
                  <Link
                    to={sfPath}
                    className="button is-size-6 is-primary is-uppercase is-fullwidth-mobile has-text-black"
                    data-test="product-buy-locally"
                    css={S.buttonStyle}
                  >
                    {intl.formatMessage({ id: `${INTL_TAG}.buy_locally` })}
                  </Link>
                )}
              </S.ButtonWrapper>
            </div>
          </div>
        </S.Description>
      </div>
      {isModalOpen && (
        <BuyNowModal
          toggleModal={toggleModal}
          intl={intl}
          intlTag={INTL_TAG}
          shopsList={shopsList}
          onClick={handleDataLayer}
        />
      )}
      {isVideoOpen && (
        <Modal onClick={toggleVideo} contentCSS={S.videoModalStyles}>
          <Video url={videoUrl} width={1024} height={768} muted={false} controls={true} playing={true} />
        </Modal>
      )}
    </Hero>
  )
}

export default ProductBanner

ProductBanner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  productVariants: PropTypes.arrayOf(PropTypes.object).isRequired,
  productVideos: PropTypes.arrayOf(PropTypes.object),
  crumbs: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  intl: PropTypes.object,
  sfPath: PropTypes.string,
  nid: PropTypes.number.isRequired
}

renderButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  shopsList: PropTypes.arrayOf(PropTypes.object),
  intl: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired
}
