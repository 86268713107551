import { GetProgressPercentage, Product, ProductData, IStates, AnswerData, QuestionData } from './types'
import { ImageLocalFile } from 'src/types'
import dispatch from 'src/utils/tracking/dispatch'
import { IntlShape } from 'gatsby-plugin-intl'

import { PRDUCT_CATEGORY_ID_ORDER_GROUPS } from './data/orderGroups'

const simpleVariantAnswerLimit = 4

export const handleRetakeQuizTracking = (): void => {
  dispatch({
    eventName: 'RetakeQuiz',
    detail: {
      eventAction: 'Retake Quiz',
      eventName: 'RetakeQuiz',
      eventType: 'userEvent',
      eventInfo: {
        quizType: 'hair quiz'
      }
    }
  })
}

export const handleQuizCompleteTracking = ({
  recommendationText,
  products
}: {
  recommendationText: string
  products: Product[]
}): void => {
  dispatch({
    eventName: 'QuizComplete',
    detail: {
      eventAction: 'Hair Quiz Complete ',
      eventName: 'QuizComplete',
      eventType: 'userEvent',
      eventInfo: {
        quizType: 'hair quiz',
        action: 'quiz complete',
        recommendationtext: recommendationText,
        result: products.map(({ title }) => title).join('|')
      }
    }
  })
}

export const handleQuizStartTracking = (): void => {
  dispatch({
    eventName: 'QuizStart',
    detail: {
      eventAction: 'Hair Quiz Start',
      eventName: 'QuizStart',
      eventType: 'userEvent',
      eventInfo: {
        action: 'quiz start',
        quizType: 'hair quiz'
      }
    }
  })
}

export const handlePreviousQuestionTracking = ({
  question,
  localizedQuestionData
}: {
  question: number
  localizedQuestionData: QuestionData[]
}): void => {
  dispatch({
    eventName: 'QuizPrevious',
    detail: {
      eventAction: 'Hair Quiz Previous ',
      eventName: 'QuizPrevious',
      eventType: 'userEvent',
      eventInfo: createQuizAnswerBase({ question, localizedQuestionData })
    }
  })
}

export const handleNextQuestionTracking = ({
  answer,
  question,
  localizedQuestionData
}: {
  question: number
  answer: AnswerData['id'] | AnswerData['id'][]
  localizedQuestionData: QuestionData[]
}): void => {
  dispatch({
    eventName: 'QuizAnswer',
    detail: {
      eventAction: 'Hair Quiz Answer',
      eventName: 'QuizAnswer',
      eventType: 'userEvent',
      eventInfo: {
        ...createQuizAnswerBase({ question, localizedQuestionData }),
        quizAnswer: mapAnswersToTrackingAnswers({
          answers: localizedQuestionData[question].answers,
          answerId: answer
        })
      }
    }
  })
}

export const createQuizAnswerBase = ({
  question,
  localizedQuestionData
}: {
  question: number
  localizedQuestionData: QuestionData[]
}): { [key: string]: string | number } => {
  const nextQuestion = question + 1

  return {
    quizType: 'hair quiz',
    question: localizedQuestionData[question]?.text,
    quizStep: nextQuestion,
    quizSection:
      getProgressPercentage({
        cap: localizedQuestionData.length,
        currentCheckpoint: nextQuestion
      }) + '%',
    quizIndex: `${nextQuestion} of ${localizedQuestionData.length}`
  }
}

export const getProductResultsBasedOnAnswers = ({
  productData,
  answers,
  products,
  locale
}: {
  productData: ProductData[]
  answers: (string | string[])[]
  products: Product[]
  locale: IntlShape['locale']
}): Product[] =>
  sortByWeightDescending({
    productData,
    products: sortByOrderGroup(
      filterAndMapIdsToProductData({
        products,
        productIds: getSuggestedProductIdsBasedOnAnswers({ productData, answers, locale })
      })
    )
  })

export const mapAnswersToTrackingAnswers = ({
  answers,
  answerId
}: {
  answers: AnswerData[]
  answerId: AnswerData['id'] | AnswerData['id'][]
}): string =>
  answers.reduce((answers, answer) => {
    if ((Array.isArray(answerId) && answerId.find((id) => id === answer.id)) || answer.id === answerId) {
      answers += answers.length ? `|${answer.text}` : answer.text
    }

    return answers
  }, '')

export const checkIfAnswerIsSelected = ({
  activeChoices,
  id
}: {
  activeChoices: string[] | string
  id: string
}): boolean => activeChoices.includes(id)

export const some = (arr: string[], arr2: string | string[]): boolean =>
  !!arr && !!arr2 ? arr.some((r) => arr2.indexOf(r) >= 0) : false

export const verifyConditions = (conditionArray: [string, ...string[][]], answers: string[]): boolean => {
  let flag = true

  conditionArray.forEach((condition) => {
    if (Array.isArray(condition) && !some(condition, answers)) {
      flag = false
    }
  })

  const result = answers.includes(conditionArray[0]) && flag

  return result
}

export const getSuggestedProductIdsBasedOnAnswers = ({
  productData,
  answers,
  locale
}: {
  productData: ProductData[]
  answers: (string | string[])[]
  locale: IntlShape['locale']
}): (string | number)[] => {
  const flattenedAnswers = answers.flat()

  const productIds = productData.reduce((filteredList: string[], product) => {

    // Ignore product if its property "exclude" contains any of the selected answer ids
    if (some(product.exclude, flattenedAnswers)) {
      return filteredList
    }

    // Ignore product if its property "displayForCountry" contains location other than current
    if (product.displayForCountry && product.displayForCountry !== locale) {
      return filteredList
    }

    // Add product if its property "include" contains any of the selected answer ids
    if ((!!product.include || !!product.exclude) && some(product.include, flattenedAnswers)) {
      if (!!product.excludeCondition && verifyConditions(product.excludeCondition, flattenedAnswers)) {
        return filteredList
      }

      return [...filteredList, product.drupalId]
    }

    // Add product if the first element of its property "condition"
    // and at least one of the second element's array contains any of the selected answer ids
    if (product.condition && verifyConditions(product.condition, flattenedAnswers)) {
      return [...filteredList, product.drupalId]
    }

    return filteredList
  }, [])

  return productIds
}

export const filterAndMapIdsToProductData = ({
  products,
  productIds
}: {
  products: Product[]
  productIds: (string | number)[]
}): Product[] =>
  products.filter(({ drupal_internal__nid }) => productIds.some((drupalId) => drupal_internal__nid === drupalId))

export const getAnswerImage = ({
  quizImages,
  imgName
}: {
  quizImages: ImageLocalFile[]
  imgName: string
}): ImageLocalFile => quizImages.find(({ name }) => name === imgName)

export const isSimpleVariant = (numOfAnswers: number): boolean => numOfAnswers <= simpleVariantAnswerLimit

export const getProgressPercentage = ({ cap, currentCheckpoint }: GetProgressPercentage): number =>
  Math.floor((currentCheckpoint / cap) * 100)

export const getStoredAnswers = ({
  windowGlobal,
  dataId
}: {
  windowGlobal: (Window & typeof globalThis) | undefined
  dataId: string
}): string[] => {
  try {
    const storedAnswers = windowGlobal && JSON.parse(windowGlobal.localStorage.getItem(dataId))

    if (!storedAnswers) {
      return []
    }

    return storedAnswers
  } catch (err) {
    return []
  }
}

export const getStates = (length: number): IStates => ({
  isStart: {
    action: 'start',
    question: 0
  },
  isEnd: {
    action: 'end',
    question: length
  },
  switchQuestion: {
    action: 'switch',
    question: 0
  }
})

export const sortByOrderGroup = (products: Product[]): Product[] =>
  products
    .reduce(
      (sortedProducts, product) => {
        const categoryPosition = PRDUCT_CATEGORY_ID_ORDER_GROUPS.indexOf(
          Number(product.relationships.field_p_category[0].drupal_internal__nid)
        )

        if (categoryPosition >= 0) {
          sortedProducts[categoryPosition].push(product)
        }

        return sortedProducts
      },
      Array.from(Array(PRDUCT_CATEGORY_ID_ORDER_GROUPS.length), () => [])
    )
    .flat()

export const findProductByDrupalId = ({ productData, id }: { productData: ProductData[]; id: number }): ProductData =>
  productData.find((product) => product.drupalId === id)

export const sortByWeightDescending = ({
  products,
  productData
}: {
  products: Product[]
  productData: ProductData[]
}): Product[] =>
  products.sort(
    (currProd, otherProd) =>
      findProductByDrupalId({ productData, id: otherProd.drupal_internal__nid }).displayWeight -
      findProductByDrupalId({ productData, id: currProd.drupal_internal__nid }).displayWeight
  )
