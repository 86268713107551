import { useEffect, useRef } from 'react'
import { pageLoaded, pageView } from 'src/utils/tracking'
import { trimByChar, isBrowser } from 'src/utils/shared'

const useTracking = ({ data }) => {
  const willMount = useRef(true)
  let evtData

  if (data) {
    const urlSplitted = trimByChar(data.page.pageInfo.pageURL, '/').split('/')

    evtData = {
      name: urlSplitted[urlSplitted.length - 1],
      description: data.page.pageInfo.pageId,
      pageurl: data.page.pageInfo.pageURL
    }
  }

  if (willMount.current && isBrowser && window.pageLoaded) {
    if (!window.digitalData || window.digitalData?.page?.pageInfo?.breadCrumbs !== data.page?.pageInfo?.breadCrumbs) {
      window.digitalData = data

      pageView(evtData)
    }
  }

  useEffect(() => {
    willMount.current = false

    if (isBrowser && !window.pageLoaded) {
      window.pageLoaded = true
      window.digitalData = data
      pageLoaded(evtData)
    }
  }, [data, evtData])
}

export default useTracking
