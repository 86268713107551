import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { SwiperSlide } from 'swiper/react'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'
import SwiperCarousel from 'src/components/SwiperCarousel'

import useTracking from 'src/hooks/useTracking'
import useClientRerender from 'src/hooks/useClientRerender'
import { createPageData, createContentPageDigitalData } from 'src/utils/tracking'
import { getMetaTitle, findByTypeName, toLowerKebabCase, formImageBaseData, isBrowser } from 'src/utils/shared'

import MemberCard from '../components/MemberCard'

import type { OurTeamProps } from './types'

const getCarouselConfig = (numberOfElements: number) => ({
  slidesPerView: 1,
  centeredSlides: true,
  spaceBetween: 20,
  loop: isBrowser && numberOfElements > 3,
  watchSlidesVisibility: false,
  breakpoints: {
    768: {
      slidesPerView: 3
    }
  }
})

const OurTeam: FC<OurTeamProps> = ({ pageContext, data }) => {
  const intl = useIntl()
  const isClient = useClientRerender()

  const {
    nodePage: {
      title,
      body: { value: description },
      field_breadcrumb: breadcrumbs,
      relationships: { field_sections },
      field_metatag_normalized: metaTags
    }
  } = data

  useTracking({
    data: createContentPageDigitalData({
      contentType: 'our-team',
      contentTitle: title,
      pageData: createPageData({
        pageId: title,
        breadCrumbs: `home:our-story:our-team`,
        pageType: 'our team',
        instanceName: 'our team',
        primaryCategory: 'our team',
        language: intl.locale,
        pageTitle: getMetaTitle(metaTags),
        pageName: pageContext.slug
      })
    })
  })

  const {
    relationships: { field_ps_person: carouselData }
  } = findByTypeName({
    arr: field_sections,
    typeName: 'paragraph__people_slider'
  })

  const {
    field_p2_text: paragraphText,
    field_p2_title: paragraphTitle,
    field_p2_link,
    relationships: pImg,
    field_p2_image: pImgAlt
  } = findByTypeName({
    arr: field_sections,
    typeName: 'paragraph__paragraph_2'
  })

  const paragaphImg = formImageBaseData({ image: pImg?.field_p2_image })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <Hero heroBody="has-padding-top-2 has-padding-bottom-4">
        <div className="columns has-margin-bottom-2">
          <h1 className="column is-3-tablet is-2-widescreen title__page">{title}</h1>
          <div className="column is-two-thirds-widescreen" dangerouslySetInnerHTML={{ __html: description }} />
        </div>

        <SwiperCarousel
          key={isClient}
          config={getCarouselConfig(carouselData?.length)}
          navLocation={toLowerKebabCase(title)}
          isOurTeam
        >
          {carouselData?.map(({ id, ...data }) => (
            <SwiperSlide key={id} tag="li">
              <MemberCard {...data} />
            </SwiperSlide>
          ))}
        </SwiperCarousel>
      </Hero>

      <Hero
        heroBody="has-padding-vertical-2__nonmobile has-padding-top-0__mobile"
        hero="is-secondary has-margin-top-3__mobile has-margin-top-7-tablet has-margin-top-2-desktop has-margin-top-4-widescreen has-margin-top-8-fullhd"
      >
        <div className="columns is-centered">
          <div className="column is-half-tablet is-one-third-desktop is-relative has-margin-top-8-negative__mobile">
            {paragaphImg && (
              <GatsbyImage
                className="is-absolute__nonmobile is-covered img-photo-fixed-left__mobile"
                {...paragaphImg}
                alt={pImgAlt?.alt}
              />
            )}
          </div>
          <div className="column is-half-tablet is-one-third-desktop">
            <h2 className="is-inline-block title has-frame has-frame__white has-text-right-mobile">
              {paragraphTitle.value}
            </h2>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-full-tablet is-two-thirds-desktop">
            <div className="has-margin-bottom-2" dangerouslySetInnerHTML={{ __html: paragraphText.value }} />
            {field_p2_link && (
              <p className="has-text-centered">
                <Link
                  to={field_p2_link.uri_alias}
                  className="button is-outlined is-white-alpha is-uppercase is-fullwidth-mobile"
                >
                  {field_p2_link.title}
                </Link>
              </p>
            )}
          </div>
        </div>
      </Hero>
    </Layout>
  )
}

export default OurTeam

export const query = graphql`
  query OurTeamPage($slug: String!, $langKey: String!) {
    nodePage(field_component: { eq: "our-team" }, path: { alias: { eq: $slug } }, langcode: { eq: $langKey }) {
      ...PageDataFragment

      relationships {
        field_sections {
          ... on paragraph__people_slider {
            __typename
            relationships {
              field_ps_person {
                id
                field_ps_name
                field_ps_position
                field_ps_text {
                  value
                }
                field_ps_image {
                  alt
                }
                relationships {
                  field_ps_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          quality: 75
                          width: 600
                          webpOptions: { quality: 78 }
                          formats: [JPG, WEBP]
                          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          ... on paragraph__paragraph_2 {
            __typename
            field_p2_link {
              title
              uri_alias
            }
            field_p2_text {
              value
            }
            field_p2_title {
              value
            }
            field_p2_image {
              alt
            }
            relationships {
              field_p2_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 50
                      webpOptions: { quality: 55 }
                      formats: [JPG, WEBP]
                      outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
