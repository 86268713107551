import { css } from '@emotion/react'

import { breakpoints, lineHeights, sizes, colors } from 'src/themes'

export const hero = css`
  @media (max-width: ${breakpoints.tablet}) {
    margin-top: 100px;
    padding-top: 100px;

    .hero-body {
      margin-top: -200px;
    }
  }

  .gatsby-image-wrapper {
    @media (max-width: ${breakpoints.tablet}) {
      margin-bottom: 0;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }
  }

  textarea {
    height: 180px;
    resize: none;
  }
  .column.is-8-widescreen {
    width: 55%;
  }
  .form .field a{
    color: ${colors.orange} !important;
    text-decoration: underline;
  }
  .no-form {
    .column.is-8-widescreen {
      width: 49.5%;
      margin-top: 5px;
      @media (max-width: ${breakpoints.tablet}) {
        width: 100%;
      }
      @media (max-width: ${breakpoints.laptop}) {
        width: 100%;
        margin-left: 0;
      }
    }
    .contact_long-text a{
      color: ${colors.orange} !important;
    }
    margin-top: 140px;
    .contact_title {
      font-weight: 600;
      font-size: ${sizes.size4};
      padding: 0 20px 0 12px;
    }
      .contact-element {
        padding: 12px 12px 0 12px;
        &_title {
          font-weight: 600;
        }
        &_description {
          line-height: ${lineHeights.medium};
        a {
          color: ${colors.orange} !important;
        }
      }
    }
  }

`

