import { getLocalizationCode } from 'src/utils/shared'

export const createSalonFinderScript = (locale: string): void => {
  const script = document.createElement('script')

  script.setAttribute('id', 'SalonFinderWidget')
  script.setAttribute('data-brands', '26')
  script.setAttribute('src', process.env.GATSBY_SALON_FINDER_HOST)
  script.setAttribute('data-localizationcode', getLocalizationCode(locale))

  document.body.appendChild(script)
}
