import React from "react"
import PropTypes from 'prop-types'

const LegalPopupDE = ({ toggleLegalPopup }) => {

    return (
        <div className="legalPopup">
            <div className="legalPopup__content">
                <h2 className="legalPopup__content__title">IMPRESSUM</h2>
                <div className="legalPopup__content__text">
                    <p>Wella Germany GmbH<br />
                        Berliner Allee 65-65a,<br />
                        64295 Darmstadt</p>
                    <p>Geschäftsführer:<br />Dr. Henrik Rolf Haverkamp, Thomas Bär</p>
                    <p>Vorsitzende des Aufsichtsrats: Ilkan Eryagci<br /> Sitz in Darmstadt - Amtsgericht: Darmstadt HRB
                        94871, Ust.ID: DE 304310603</p>
                </div>
                <p className="legalPopup__content__close" onClick={toggleLegalPopup}>×</p>
            </div>
        </div>
    )
}

LegalPopupDE.propTypes = {
    toggleLegalPopup: PropTypes.func
}

export default LegalPopupDE
