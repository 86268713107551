import React, { Fragment, FC } from 'react'

import Checkbox from 'src/components/Form/Checkbox'

import { AgreementsComponentProps, Agreement } from './types'

const Agreements: FC<AgreementsComponentProps> = ({ agreements, handleCheckboxChange, checkboxState, errorState }) => (
  <Fragment>
    {agreements.map(({ id, name, required, errorMessage, label }: Agreement) => (
      <Checkbox
        key={id}
        name={name}
        label={label}
        required={required}
        onChange={handleCheckboxChange}
        value={checkboxState[name]}
        isInvalid={required && (checkboxState[name] === false || errorState[name])}
        errorMessage={errorMessage}
        fieldClass="is-size-7 has-margin-vertical-2"
      />
    ))}
  </Fragment>
)

export default Agreements
