import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'src/layouts'
import Hero from 'src/components/Hero'
import ProductCard from 'src/components/ProductCard'
import SEO from 'src/components/SEO'

import useTracking from 'src/hooks/useTracking'
import { createPageData } from 'src/utils/tracking'
import { getMetaTitle } from 'src/utils/shared'

const ProductList = ({ data, pageContext }) => {
  const intl = useIntl()

  const {
    allNodeProduct: { nodes },
    sectionHeader: {
      title,
      body: { value: headerDesc },
      field_metatag_normalized: metaTags
    }
  } = data

  const {breadcrumbs} = pageContext

  useTracking({
    data: createPageData({
      pageId: title,
      breadCrumbs: `home:products:${title}`,
      pageType: 'products listing',
      instanceName: 'products listing',
      primaryCategory: 'products',
      language: intl.locale,
      pageTitle: getMetaTitle(metaTags),
      pageName: pageContext.slug
    })
  })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
        <div className="columns has-margin-bottom-2">
          <h1 className="column is-3-tablet is-2-widescreen title__page">{title}</h1>
          <div className="column is-half-widescreen" dangerouslySetInnerHTML={{ __html: headerDesc }} />
        </div>
        <div className="columns is-mobile is-multiline">
          {nodes.length &&
            nodes.map(({ id, ...card }) => (
              <div key={id} className="column is-half-mobile is-one-third-tablet is-one-quarter-desktop">
                <ProductCard {...card} intl={intl} isClickable />
              </div>
            ))}
        </div>
      </Hero>
    </Layout>
  )
}

export default ProductList

ProductList.propTypes = {
  data: PropTypes.shape({
    allNodeProduct: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          field_p_subtitle: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          relationships: PropTypes.shape({
            field_sections: PropTypes.arrayOf(
              PropTypes.shape({
                relationships: PropTypes.shape({
                  field_pv_image: PropTypes.arrayOf(
                    PropTypes.shape({
                      alt: PropTypes.string,
                      localFile: PropTypes.shape({
                        childImageSharp: PropTypes.shape({
                          gatsbyImageData: PropTypes.object.isRequired
                        })
                      })
                    })
                  )
                })
              })
            )
          })
        })
      )
    }),
    sectionHeader: PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.shape({
        value: PropTypes.string.isRequired
      }),
      field_metatag_normalized: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.string,
          name: PropTypes.string
        })
      ),
      field_breadcrumb: PropTypes.arrayOf(
        PropTypes.shape({
          crumbLabel: PropTypes.string,
          pathname: PropTypes.string
        })
      ).isRequired
    })
  }),
  pageContext: PropTypes.any
}

export const query = graphql`
  query ProductList(
    $slug: String!
    $langKey: String!
    $productVariantHeight: Int! = 483
    $productVariantWidth: Int! = 364
    $productVariantQuality: Int! = 80
    $isRelatedProductsIngredient: Boolean! = false
    $includeProductCategoryDrupalId: Boolean! = false
  ) {
    allNodeProduct(
      filter: {
        langcode: { eq: $langKey }
        relationships: { field_p_category: { elemMatch: { path: { alias: { eq: $slug } } } } }
        status: { eq: true }
      }
      sort: { fields: created, order: DESC }
    ) {
      ...RelatedProductsFragment
    }
    sectionHeader: nodeProductCategory(path: { alias: { eq: $slug } }, langcode: { eq: $langKey }) {
      ...PageDataFragment
    }
  }
`
