import React, { FC } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-plugin-intl'

import BlogList from 'src/components/BlogList'
import Hero from 'src/components/Hero'
import ProductCard from 'src/components/ProductCard'

import { getArtDirection } from 'src/utils/shared'

import * as S from './styles/finalScreen'

import { FinalScreenInterface } from './types/finalScreen'

const INTL_TAG = 'quiz.final_screen'

const FinalScreen: FC<FinalScreenInterface> = ({
  intl,
  blogList,
  resetQuiz,
  newsletterImage,
  resultsToRender,
  disableLinking
}) => (
  <>
    <S.RetakeButton data-test="quiz-retake-button" onClick={resetQuiz} className="button">
      {intl.formatMessage({ id: `${INTL_TAG}.retake_button` })}
    </S.RetakeButton>
    <S.MainHeader className="title has-frame">{intl.formatMessage({ id: `${INTL_TAG}.main_header` })}</S.MainHeader>
    <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
      <section
        css={S.sectionPadding}
        className="columns is-mobile is-multiline has-text-left has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile"
      >
        {resultsToRender?.length &&
          resultsToRender.map(({ drupal_id, ...card }) => (
            <div key={drupal_id} className="column is-half-mobile is-one-third-tablet is-one-quarter-desktop">
              <ProductCard data-test="product-card" {...card} intl={intl} isClickable disableLinking={disableLinking} />
            </div>
          ))}
      </section>
    </Hero>

    {!disableLinking && (
      <>
        {newsletterImage && (
          (intl.locale !== 'ru-ru' &&
          <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
            <S.ImageSection data-test="quiz-newsletter" css={S.sectionPadding}>
              <Link to={intl.formatMessage({ id: `${INTL_TAG}.newsletter_link` })}>
                <GatsbyImage
                  alt={intl.formatMessage({ id: `images.quiz.newsletter` })}
                  image={getArtDirection(newsletterImage)}
                  css={S.bannerImgStyles}
                />
                <S.ImageContent className="container">
                  <S.ImageTextTitle textLength={intl.formatMessage({ id: `${INTL_TAG}.newsletter_label` }).length}>
                    {intl.formatMessage({ id: `${INTL_TAG}.newsletter_label` })}
                  </S.ImageTextTitle>
                  <S.ImageButton
                    textLength={intl.formatMessage({ id: `${INTL_TAG}.newsletter_button` }).length}
                    className="button"
                  >
                    {intl.formatMessage({ id: `${INTL_TAG}.newsletter_button` })}
                  </S.ImageButton>
                </S.ImageContent>
              </Link>
          </S.ImageSection>
          </Hero>
        ))}

        {blogList && (
          <section css={S.sectionPadding} className="has-text-left">
            <BlogList
              data-test="blog-list"
              list={blogList}
              header={intl.formatMessage({ id: `${INTL_TAG}.blog_tag` })}
              isQuizBlogList
            />
          </section>
        )}
      </>
    )}
  </>
)

export default FinalScreen
