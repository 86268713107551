import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { SwiperSlide } from 'swiper/react'

import SwiperCarousel from 'src/components/SwiperCarousel'
import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'
import ProductCard from 'src/components/ProductCard'

import useTracking from 'src/hooks/useTracking'
import useClientRerender from 'src/hooks/useClientRerender'
import { createPageData, createContentPageDigitalData } from 'src/utils/tracking'
import { getMetaTitle, findByTypeName, toLowerKebabCase, formImageBaseData } from 'src/utils/shared'

import { handsImageStyles } from '../styles/components'

import type { OurBrandProps } from './types'

const OurBrand: FC<OurBrandProps> = ({ pageContext, data }) => {
  const intl = useIntl()
  const isClient = useClientRerender()

  const {
    nodePage: {
      title,
      body: { value: description },
      field_breadcrumb: breadcrumbs,
      relationships: { field_sections: paragraphs },
      field_metatag_normalized: metaTags
    },
    handsImgMobile,
    handsImg,
    allNodeProduct: { nodes: carouselData }
  } = data

  useTracking({
    data: createContentPageDigitalData({
      contentType: 'our-brand',
      contentTitle: title,
      pageData: createPageData({
        pageId: title,
        breadCrumbs: `home:our-story:our-brand`,
        pageType: 'our brand',
        instanceName: 'our brand',
        primaryCategory: 'our brand',
        language: intl.locale,
        pageTitle: getMetaTitle(metaTags),
        pageName: pageContext.slug
      })
    })
  })

  const {
    field_p1_text: paragraph1Text,
    field_p1_title: paragraph1Title,
    relationships: paragraph1Image,
    field_p1_image: paragraph1ImageAlt
  } = findByTypeName({
    arr: paragraphs,
    typeName: 'paragraph__paragraph_1'
  })
  const {
    field_p2_text: paragraph2Text,
    field_p2_title: paragraph2Title,
    relationships: paragraph2Image,
    field_p2_link: paragraph2Link,
    field_p2_image: paragraph2ImageAlt
  } = findByTypeName({
    arr: paragraphs,
    typeName: 'paragraph__paragraph_2'
  })
  const {
    field_p1_text: paragraph3Text,
    field_p1_title: paragraph3Title,
    relationships: paragraph3Image,
    field_p1_image: paragraph3ImageAlt
  } = paragraphs[2] || {}

  const handsImage = {
    image: withArtDirection(handsImg?.childImageSharp?.gatsbyImageData, [
      {
        media: '(max-width: 1215px)',
        image: handsImgMobile?.childImageSharp?.gatsbyImageData
      }
    ]),
    alt: intl.formatMessage({ id: 'images.our_brand.hands' }),
    style: { maxWidth: '769px', display: 'block' }
  }

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
        <div className="columns has-margin-bottom-2">
          <h1 className="column is-3-tablet is-2-desktop title__page">{title}</h1>
          <div className="column is-two-thirds-desktop" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </Hero>
      <Hero hero="is-third is-marginless is-paddingless hands-image" heroBody="is-marginless is-paddingless">
        <div className="columns has-margin-vertical-4-negative__nonmobile is-centered">
          <div className="column is-three-quarters-tablet is-half-desktop has-padding-vertical-0__mobile ">
            <GatsbyImage css={handsImageStyles} {...handsImage} />
          </div>
        </div>
      </Hero>

      <Hero
        heroBody="has-padding-vertical-2__nonmobile  has-padding-top-0__mobile"
        hero="has-margin-top-6__nonmobile has-margin-bottom-4__mobile"
      >
        <div className="columns change-direction__nonmobile is-centered">
          <div className="column is-half-tablet is-one-third-desktop is-relative has-margin-top-8-negative__mobile">
            <GatsbyImage
              className="is-absolute__nonmobile is-covered img-photo-fixed-right__mobile"
              {...formImageBaseData({ image: paragraph1Image?.field_p1_image })}
              alt={paragraph1ImageAlt?.value}
            />
          </div>
          <div className="column is-half-tablet is-one-third-desktop">
            <h2 className="is-inline-block title has-frame">{paragraph1Title?.value}</h2>
          </div>
        </div>
        <div className="columns is-centered ">
          <div
            className="column is-full-tablet is-two-thirds-desktop"
            dangerouslySetInnerHTML={{ __html: paragraph1Text.value }}
          />
        </div>
      </Hero>

      <Hero
        heroBody="has-padding-vertical-2__nonmobile has-padding-top-0__mobile"
        hero="is-secondary has-margin-top-7-tablet has-margin-top-4-desktop has-margin-top-7-widescreen has-margin-top-10-fullhd "
      >
        <div className="columns is-centered">
          <div className="column is-half-tablet is-one-third-desktop is-relative has-margin-top-8-negative__mobile">
            <GatsbyImage
              className="is-absolute__nonmobile is-covered img-photo-fixed-left__mobile"
              {...formImageBaseData({ image: paragraph2Image?.field_p2_image })}
              alt={paragraph2ImageAlt?.value}
            />
          </div>
          <div className="column is-half-tablet is-one-third-desktop">
            <h2 className="is-inline-block title has-frame has-frame__white has-text-right-mobile">
              {paragraph2Title.value}
            </h2>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-full-tablet is-two-thirds-desktop">
            <div className="has-margin-bottom-2" dangerouslySetInnerHTML={{ __html: paragraph2Text.value }} />
            {paragraph2Link && (
              <p className="has-text-centered">
                <Link
                  to={paragraph2Link.uri}
                  className="button is-outlined is-white-alpha is-uppercase is-fullwidth-mobile"
                >
                  {paragraph2Link.title}
                </Link>
              </p>
            )}
          </div>
        </div>
      </Hero>

      {!!paragraph3Text && (
        <Hero
          heroBody="has-padding-vertical-2__nonmobile  has-padding-top-0__mobile"
          hero="has-margin-top-4__nonmobile has-margin-top-2__mobile has-margin-bottom-4__mobile"
        >
          <div className="columns change-direction__nonmobile is-centered">
            <div className="column is-half-tablet is-one-third-desktop is-relative has-margin-top-8-negative__mobile">
              <GatsbyImage
                className="is-absolute__nonmobile is-covered img-photo-fixed-right__mobile"
                {...formImageBaseData({ image: paragraph3Image?.field_p1_image })}
                alt={paragraph1ImageAlt?.value}
              />
            </div>
            <div className="column is-half-tablet is-one-third-desktop">
              <h2 className="is-inline-block title has-frame">{paragraph3Title?.value}</h2>
            </div>
          </div>
          <div className="columns is-centered ">
            <div
              className="column is-full-tablet is-two-thirds-desktop"
              dangerouslySetInnerHTML={{ __html: paragraph3Text?.value }}
            />
          </div>
        </Hero>
      )}

      <Hero>
        <div className="has-margin-bottom-2">
          <div className="is-uppercase has-text-weight-bold">{intl.formatMessage({ id: 'shared.you_may_like' })}</div>
        </div>
        <SwiperCarousel key={isClient} navLocation={toLowerKebabCase(title)}>
          {carouselData?.map(({ id, ...card }) => (
            <SwiperSlide tag="li" key={id}>
              <ProductCard {...card} isClickable intl={intl} />
            </SwiperSlide>
          ))}
        </SwiperCarousel>
      </Hero>
    </Layout>
  )
}

export default OurBrand

export const query = graphql`
  query OurBrandPage(
    $slug: String!
    $langKey: String!
    $productVariantHeight: Int! = 506
    $productVariantWidth: Int! = 382
    $productVariantQuality: Int! = 75
    $isRelatedProductsIngredient: Boolean! = false
    $includeProductCategoryDrupalId: Boolean! = false
  ) {
    nodePage(field_component: { eq: "our-brand" }, path: { alias: { eq: $slug } }, langcode: { eq: $langKey }) {
      ...PageDataFragment

      relationships {
        __typename
        field_sections {
          ... on paragraph__paragraph_1 {
            __typename
            field_p1_text {
              value
            }
            field_p1_title {
              value
            }
            field_p1_image {
              alt
            }
            relationships {
              field_p1_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 70
                      webpOptions: { quality: 72 }
                      outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
          ... on paragraph__paragraph_2 {
            __typename
            field_p2_link {
              title
              uri
            }
            field_p2_text {
              value
            }
            field_p2_title {
              value
            }
            field_p2_image {
              alt
            }
            relationships {
              field_p2_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 70
                      webpOptions: { quality: 72 }
                      outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }

    handsImgMobile: file(relativePath: { eq: "ourbrand-hands-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 770
          quality: 80
          webpOptions: { quality: 82 }
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
          formats: [JPG, WEBP]
        )
      }
    }
    handsImg: file(relativePath: { eq: "ourbrand-hands-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 660
          quality: 80
          webpOptions: { quality: 82 }
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
          formats: [JPG, WEBP]
        )
      }
    }

    allNodeProduct(
      filter: { field_p_show_on_homepage: { eq: true }, status: { eq: true }, langcode: { eq: $langKey } }
      sort: { fields: created, order: DESC }
    ) {
      ...RelatedProductsFragment
    }
  }
`
