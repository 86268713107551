import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { COUNTRIES_FLAGS } from '../consts'

import ShopList from './ShopList'

import { getSortedShops } from '../../utils'

import { FlagWrapper, Flag, NavbarLink } from '../../styles/components'

const CountryShopsList = ({ shops, handleShopClick, intl }) => {
  const [openList, handleOpenList] = useState(false)

  const countriesList = [...new Set(shops?.map(({ field_wtb_country }) => field_wtb_country))]
  const sortedShops = getSortedShops({ countriesList, shops })

  return (
    <Fragment>
      {sortedShops.map(({ country, shops }) => {
        const icon = COUNTRIES_FLAGS[country]

        return (
          <div key={country} className={`has-dropdown is-hoverable ${openList === country ? 'is-opened' : ''}`}>
            <NavbarLink
              className="navbar-link is-capitalized"
              onClick={() => handleOpenList((prevState) => (prevState && prevState === country ? false : country))}
              data-test="modal-country"
            >
              {intl.formatMessage({ id: `countries.${country}` })}
              {icon && (
                <FlagWrapper className="has-margin-right-1">
                  <Flag className="flag" xlinkHref={icon} />
                </FlagWrapper>
              )}
            </NavbarLink>
            {openList === country && <ShopList shops={shops} handleShopClick={handleShopClick} locale={intl.locale} />}
          </div>
        )
      })}
    </Fragment>
  )
}

export default CountryShopsList

CountryShopsList.propTypes = {
  shops: PropTypes.arrayOf(PropTypes.object),
  handleShopClick: PropTypes.func,
  intl: PropTypes.object.isRequired
}
