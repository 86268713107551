import React, { Fragment, FC } from 'react'

import Input from 'src/components/Form/Input'
import { EMAIL_REGEX } from 'src/consts'

import type { CustomerFieldsInterface } from '../../types'

const CustomerFields: FC<CustomerFieldsInterface> = ({
  onChange,
  intlTag,
  intl,
  SalonNameReq,
  City,
  PostalCode,
  SalonAddressReq,
  SalonPhoneReq,
  SalonManagerEmailReq,
  StylistNumbPerSalonReq,
  errorState
}) => {
  return (
    <Fragment>
      <Input
        label={`${intl.formatMessage({ id: `${intlTag}.salon_name` })}*`}
        name="SalonNameReq"
        type="text"
        onChange={onChange}
        required
        isInvalid={SalonNameReq === '' || errorState.SalonNameReq}
        errorMessage={intl.formatMessage({ id: `${intlTag}.required` })}
        value={SalonNameReq}
      />
      <Input
        label={`${intl.formatMessage({ id: `${intlTag}.salon_address` })}*`}
        name="SalonAddressReq"
        type="text"
        onChange={onChange}
        required
        isInvalid={SalonAddressReq === '' || errorState.SalonAddressReq}
        errorMessage={intl.formatMessage({ id: `${intlTag}.required` })}
        value={SalonAddressReq}
      />
      {intl.locale === 'it-it' && (
        <Input
          label={`${intl.formatMessage({ id: `${intlTag}.city_name` })}*`}
          name="City"
          type="text"
          onChange={onChange}
          required
          isInvalid={City === '' || errorState.City}
          errorMessage={intl.formatMessage({ id: `${intlTag}.required` })}
          value={City}
        />
      )}
      {intl.locale === 'it-it' && (
        <Input
          label={`${intl.formatMessage({ id: `${intlTag}.city_postal_code` })}*`}
          name="PostalCode"
          type="text"
          onChange={onChange}
          required
          isInvalid={PostalCode === '' || errorState.PostalCode}
          errorMessage={intl.formatMessage({ id: `${intlTag}.required` })}
          value={PostalCode}
        />
      )}
      <Input
        label={`${intl.formatMessage({ id: `${intlTag}.salon_number` })}*`}
        name="SalonPhoneReq"
        type="text"
        onChange={onChange}
        required
        isInvalid={SalonPhoneReq === '' || errorState.SalonPhoneReq}
        errorMessage={intl.formatMessage({ id: `${intlTag}.required` })}
        value={SalonPhoneReq}
      />
      <Input
        label={`${intl.formatMessage({ id: `${intlTag}.salon_owner_mail` })}*`}
        name="SalonManagerEmailReq"
        type="email"
        onChange={onChange}
        required
        isInvalid={
          SalonManagerEmailReq === '' ||
          errorState.SalonManagerEmailReq ||
          (!!SalonManagerEmailReq && !EMAIL_REGEX.test(SalonManagerEmailReq))
        }
        errorMessage={intl.formatMessage({
          id: !SalonManagerEmailReq ? `${intlTag}.required` : `${intlTag}.invalid_data`
        })}
        value={SalonManagerEmailReq}
      />
      <Input
        label={`${intl.formatMessage({ id: `${intlTag}.stylist_per_salon` })}*`}
        name="StylistNumbPerSalonReq"
        type="number"
        onChange={onChange}
        required
        isInvalid={StylistNumbPerSalonReq === '' || errorState.StylistNumbPerSalonReq}
        errorMessage={intl.formatMessage({ id: `${intlTag}.required` })}
        value={StylistNumbPerSalonReq}
      />
    </Fragment>
  )
}

export default CustomerFields
