import styled from '@emotion/styled'

import { mq, zIndexes } from 'src/themes'

export const Plasticbank = styled.div`
  position: relative;
  z-index: ${zIndexes.zIndex1};
  margin-bottom: -9.5rem !important;

  ${mq({
    height: ['100%', '36vw', 325, 391, 468]
  })}
`

export const PlaticIconWrapper = styled.svg`
  position: relative;
  box-sizing: content-box;
  margin-bottom: -5px;

  ${mq({
    height: ['60vw', '40vw', 325, 391, 468],
    width: ['90vw', '50vw']
  })}
`

export const PlasticIcon = styled.use`
  @media (orientation: landscape) {
    font-size: 580px;
  }

  ${mq({
    fontSize: ['90vw', '45vw', 468, 564, 575]
  })}
`
