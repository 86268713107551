import styled from '@emotion/styled'

import { colors, frame, breakpoints } from 'src/themes'

import { FrameColorController } from '../types'

const mainParagraphSpacing = '1.1rem'

export const ParagraphTitle = styled.h2<FrameColorController>`
  display: inline-block;
  ${({ whiteFrame }) => (whiteFrame ? frame({ color: colors.white }) : frame({ color: colors.orange }))};
`

export const MainTitle = styled.h1`
  ${frame({ color: colors.orange })};
  display: inline-block;
  padding: ${mainParagraphSpacing} 0;
  margin-bottom: ${mainParagraphSpacing};
`

export const ParagraphImage = styled.div`
  display: flex;
  justify-content: center;
`

export const ParagraphContent = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (min-width: ${breakpoints.desktop}) {
    width: 66%;
  }

  ul {
    list-style: disc;
  }
  p {
    &.yt-container {
      position: relative;
      width:100%;
    }
    &.yt-container::after {
      padding-top: 56.25%;
      display: block;
      content: '';
    }
    &.yt-container iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
`
