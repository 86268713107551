import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { colors, media, sizes } from 'src/themes'

import { AnswerStylesController } from '../../types'

const quizButtonColor = '#e49569'

export const answerImageStyles = css`
  margin-bottom: 5px;
  width: 100%;
`

export const AnswerContainer = styled.div<AnswerStylesController>`
  ${({ noImage }) =>
    noImage
      ? css`
          align-items: stretch;

          ${media({
            flexBasis: ['40%', undefined, '30%', '14%', '15%'],
            margin: ['15px 10px', undefined, '1em 0.5em']
          })};
        `
      : css`
          margin-top: 0.75rem;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          flex: 0 0 50%;
          max-width: 300px;
          padding: 0.5rem;

          ${media({
            padding: ['0.5rem', '1rem', '1rem 2rem', '0 1rem', '0 2rem', '0 3rem'],
            marginTop: [0, undefined, undefined, sizes.size8, 0]
          })};
        `}
`

export const AnswerButton = styled.button<AnswerStylesController>`
  border-color: ${quizButtonColor};
  color: ${colors.dark};
  flex-grow: 1;
  height: auto;
  letter-spacing: 0.5px;
  white-space: normal;

  @media (hover: none) {
    &:hover {
      color: ${colors.dark};
      background-color: inherit;
      border-color: ${quizButtonColor};
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    ${AnswerContainer}:hover & {
      background-color: rgba(228, 149, 105, 0.3);
      border-color: ${quizButtonColor};
      color: ${colors.dark};
    }
  }

  ${media({
    fontSize: ['2.8vw', '2vw', sizes.size8],
    padding: ['4px 0', '4px 1.5em', '0.5rem 1em']
  })};

  ${({ noImage, simpleVariant }) =>
    noImage &&
    css`
      height: 100%;
      line-height: 1.2;
      padding: 2px 2em;
      width: 100%;

      ${media({
        fontSize: ['3.6vw', '2vw', sizes.size8],
        padding: ['5px 10px', '5px 5.5em', '2px 2em']
      })};

      ${simpleVariant &&
      css`
        padding: 0 1em;
        height: 7rem;
      `}
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${quizButtonColor};
      color: ${colors.dark};

      @media (hover: none) {
        &:hover {
          color: ${colors.dark};
          background-color: ${quizButtonColor};
          border-color: ${quizButtonColor};
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        ${AnswerContainer}:hover & {
          background-color: ${quizButtonColor};
          color: ${colors.dark};
        }
      }
    `}
`
