import React, { FC } from 'react'

import { HeroProps } from './types'

const Hero: FC<HeroProps> = ({
  hero = '',
  heroBody = '',
  container = '',
  customCSS,
  children,
  heroBodyStyles,
  containerStyles
}) => (
  <section css={customCSS} className={`hero ${hero}`}>
    <div css={heroBodyStyles} className={`hero-body ${heroBody}`}>
      <div css={containerStyles} className={`container ${container}`}>
        {children}
      </div>
    </div>
  </section>
)

export default Hero
