import React, { FC } from 'react'

import { TextareaProps } from './types'

const Textarea: FC<TextareaProps> = ({
  label,
  name,
  value,
  onChange,
  placeholder = '',
  errorMessage = '',
  isInvalid,
  fieldClass = '',
  required
}) => (
  <div className={`field ${fieldClass}`}>
    {!!label && (
      <label className="label" htmlFor={name}>
        {label}
      </label>
    )}
    <div className="control">
      <textarea
        name={name}
        onChange={onChange}
        className={`textarea ${isInvalid ? 'is-danger' : ''}`}
        placeholder={placeholder}
        id={name}
        value={value || ''}
        required={required}
      />
    </div>
    {isInvalid && <p className="help is-danger">{errorMessage}</p>}
  </div>
)

export default Textarea
