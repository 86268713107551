import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import Hero from 'src/components/Hero'
import Video from 'src/components/Video'

import { formImageBaseData } from 'src/utils/shared'
import { INTL_TAG } from '../utils'

const VIDEO_CONFIG = {
  youtube: {
    playerVars: {
      autoPlay: 1,
      controls: 0,
      showInfo: 0
    }
  }
}

const renderVideoOrBtn = ({ field_htu_video, relationships }) => {
  if (field_htu_video?.uri) {
    return <Video url={field_htu_video.uri} config={VIDEO_CONFIG} light playing className="video video-responsive" />
  }

  if (!field_htu_video && relationships?.field_htu_image) {
    return <GatsbyImage {...formImageBaseData({ image: relationships?.field_htu_image })} />
  }

  return null
}

const HowToUse = ({ intl, field_htu_body, field_htu_title, field_htu_video, relationships }) => (
  <Hero hero="is-third has-olive has-olive__bottom-left">
    <div className="is-one-quarter">
      <b className="is-uppercase">{intl.formatMessage({ id: `${INTL_TAG}.how_to_use` })}</b>
    </div>
    <div className="columns is-vcentered">
      <div className="column">
        {field_htu_title && (
          <h2
            className="has-margin-bottom-2 has-frame is-inline-block is-size-3 has-text-weight-bold"
            dangerouslySetInnerHTML={{ __html: field_htu_title }}
          />
        )}
        {field_htu_body?.value && <div dangerouslySetInnerHTML={{ __html: field_htu_body.value }} />}
      </div>
      <div className="column">{renderVideoOrBtn({ field_htu_video, relationships })}</div>
    </div>
  </Hero>
)

export default HowToUse

HowToUse.whyDidYouRender = {
  logOnDifferentValues: true
}

HowToUse.propTypes = {
  intl: PropTypes.object,
  field_htu_body: PropTypes.shape({
    value: PropTypes.string.isRequired
  }),
  field_htu_title: PropTypes.string,
  field_htu_video: PropTypes.shape({
    uri: PropTypes.string.isRequired
  }),
  relationships: PropTypes.shape({
    field_htu_image: PropTypes.shape({
      alt: PropTypes.string,
      image: PropTypes.shape({
        localFile: PropTypes.shape({
          childImageSharp: PropTypes.object
        })
      })
    })
  })
}

renderVideoOrBtn.propTypes = {
  field_htu_video: PropTypes.shape({
    uri: PropTypes.string
  }),
  relationships: PropTypes.shape({
    field_htu_image: PropTypes.shape({
      alt: PropTypes.string,
      image: PropTypes.shape({
        localFile: PropTypes.shape({
          childImageSharp: PropTypes.object
        })
      })
    })
  })
}
