import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { breakpoints, colors, media, sizes } from 'src/themes'
import { fontSizeController } from '../types/finalScreen'

const buttonColor = '#fa9c76'

export const contentWidth = css`
  ${media({
    width: ['90%', '52vw', undefined, '45%', '35%']
  })};
`

export const MainHeader = styled.h1`
  font-size: 10vw;
  line-height: 1;
  margin: 2.5rem auto 0 !important;
  text-align: start;

  ${contentWidth};

  ${media({
    lineHeight: [1, 1.1],
    fontSize: ['10vw', sizes.size3]
  })};
`

export const sectionPadding = css`
  ${media({
    padding: [0, '0 3rem', '0.5rem']
  })};
`

export const sectionMargin = css`
  ${media({
    margin: [0, '0 5rem', '0 0.5rem']
  })};
`

export const RetakeButton = styled.button`
  ${contentWidth};

  border-color: ${buttonColor};
  color: ${buttonColor};

  &:hover {
    background-color: ${buttonColor};
    color: ${colors.white};
  }
`

export const bannerImgStyles = css`
  @media (max-width: ${breakpoints.tablet}) {
    height: 0 !important;
    padding-bottom: 37%;
  }
`

export const ImageSection = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`

export const ImageContent = styled.div`
  position: absolute;
  text-align: left;
  width: 50%;
  top: 0;
  left: 0;
  height: 100%;
  margin-left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const ImageTextTitle = styled.h2<fontSizeController>`
  color: ${colors.white};
  font-weight: 700;
  text-align: left;

  ${({ textLength }) =>
    textLength > 8
      ? media({
          fontSize: [`${73 / (textLength * 1.1)}vw`, `${55 / textLength}vw`, sizes.size3, sizes.size2, '2.85rem']
        })
      : media({
          fontSize: ['4.6vw', '4.2vw', sizes.size3, sizes.size2, '2.85rem']
        })};
`

export const ImageButton = styled.button<fontSizeController>`
  color: ${colors.orange};
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.2px;

  ${({ textLength }) =>
    textLength > 8
      ? media({
          fontSize: [`${65 / textLength}vw`, `${52 / textLength}vw`, '1.45vw', '0.95rem', sizes.size5, '1.5rem']
        })
      : media({
          fontSize: ['2.5vw', '2vw', '1.45vw', '0.95rem', sizes.size5, '1.5rem']
        })};

  ${media({
    height: ['20%', undefined, '1.75rem', '2.25rem', '3rem'],
    padding: ['0 5px', undefined, undefined, undefined, '1rem 5px']
  })};
`
