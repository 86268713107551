import React, { FC } from 'react'

import { applyIconStyles } from 'src/utils/shared'

import { iconSvgStyles, iconUseStyles } from 'src/themes'

import { IconWrapperProps } from './types'

const IconWrapper: FC<IconWrapperProps> = ({
  iconDesc,
  isActive = true,
  iconId,
  iconIdInactive,
  icons,
  isRadio,
  iconSize,
  ...rest
}) => (
  <div className="svg-icon">
    <svg css={applyIconStyles({ isRadio, iconSize })(iconSvgStyles)} className="icon-wrapper" {...rest}>
      <use
        css={applyIconStyles({ isRadio, iconSize })(iconUseStyles)}
        className="icon"
        xlinkHref={`${icons}#${isActive ? iconId : iconIdInactive}`}
      />
    </svg>

    {!!iconDesc && <p className="icon-description" dangerouslySetInnerHTML={{ __html: iconDesc }} />}
  </div>
)

export default IconWrapper
