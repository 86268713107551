import styled from '@emotion/styled'
import { css, SerializedStyles } from '@emotion/react'

import { backgrounds, breakpoints, colors, lineHeights, padding, sizes, transition } from 'src/themes'
import { QuizCardVariantController } from './types'

export const CategoryCardStyled = styled.div<QuizCardVariantController>`
  position: relative;
  overflow: hidden;

  ${({ isQuizCard, disableHoverEffects }) => css`
    ${isQuizCard &&
    css`
      @media (min-width: 560px) and (max-width: ${breakpoints.tablet}) and (orientation: portrait) {
        height: 65vh;
      }
    `}

    ${!disableHoverEffects &&
    css`
      &:hover {
        .gatsby-image-wrapper {
          transform: scale(1.1);

          @media (min-width: ${breakpoints.tablet}) {
            &:after {
              background: ${colors.orange};
              opacity: 0.85;
            }
          }
        }
      }
    `}
  `}
`

export const gatsbyImageWrapper = ({ artDirection }: { artDirection?: boolean }): SerializedStyles => css`
  transform: scale(1);
  transition: ${transition};
  max-width: 100%;
  max-height: 100%;
  display: block;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: ${backgrounds.blackAlphaLight};
    transition: ${transition};
    opacity: 1;
  }

  ${artDirection &&
  css`
    @media (max-width: ${breakpoints.tablet}) {
      height: 0;
      padding-bottom: 125%;
    }
  `}
`

export const Card = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${padding.x3} ${padding.x2};
`

export const CardHeader = styled.header`
  box-shadow: none;
  line-height: ${lineHeights.small};
`

export const centered = css`
  margin: auto;
  text-align: center;
  justify-content: center;
`

export const CardContent = styled.div`
  flex: auto;
  padding: 0;
  font-size: ${sizes.size7};
`
export const CardFooter = styled.div`
  border: none;
  display: flex;
  align-items: stretch;
`

export const CategoryCardButton = styled.button`
  ${({ onClick }) =>
    onClick &&
    css`
      @media (min-width: 769px) {
        visibility: hidden;
      }
    `}
`

export const cardTitleStyles = css`
  font-weight: bold;
  padding: 0 10%;
  font-size: 8.1vw;
  margin-top: 12vh;

  @media (min-width: 560px) {
    font-size: 9vw;
    padding: 0 8%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 2.2rem;
    padding: 0 19%;
    margin-top: 22%;
    line-height: ${lineHeights.medium};
  }
`
