import dispatch from 'src/utils/tracking/dispatch'

const signupCompleteEvent = ({ action }) => {
  const eventName = 'signupComplete'

  const detail = {
    eventName,
    eventAction: 'Newsletter-signup-submit',
    eventType: 'userEvent',
    eventInfo: {
      action
    }
  }

  dispatch({
    eventName,
    detail
  })
}

export default signupCompleteEvent
