import styled from '@emotion/styled'
import { css } from '@emotion/react'

import placeHolderImg from 'src/images/banner_placeholder.jpg'

import { breakpoints, colors, padding, frame, mq, zIndexes, sizes } from 'src/themes'

const iphoneXLandscape = '820px'

export const StyledBanner = styled.section`
  position: relative;
  background: transparent;
  overflow: hidden;
  min-height: 100vh;
  height: 100%;

  .video-background {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${zIndexes.zIndexNegative};
    background: ${colors.dark};
    width: 100% !important;
    height: 100% !important;

    &.is-loading {
      background: url(${placeHolderImg});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      > div {
        visibility: hidden;
      }
    }

    div,
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      pointer-events: none;
    }

    div {
      @media (min-aspect-ratio: 16/9) {
        height: 300% !important;
        top: -100%;
      }
      @media (max-aspect-ratio: 16/9) {
        width: 500% !important;
        left: -200%;
      }

      &:after {
        content: '';
        background-color: rgba(0, 0, 0, 0.35);
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .modal {
    &.is-active {
      .modal-content {
        ${mq({
          width: ['100%', '85%', '80%']
        })}
      }
    }
  }
  .swiper-wrapper .gatsby-image-wrapper img {
    @media (max-width: ${breakpoints.tablet}) {
      background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) );
      z-index: ${zIndexes.zIndexNegative};
      height: 100vh;
    }
  }
`

export const ImgContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;

  @media (orientation: portrait), (max-width: ${iphoneXLandscape}) and (orientation: landscape) {
    width: 100%;
  }
  @media (min-width: 700px) and (orientation: portrait) {
    margin-top: 50%;
  }
`

export const ScrollDown = styled.div`
  position: absolute;
  left: 50%;
  bottom: ${padding.x1}
  transform: translateX(-50%);
  cursor: pointer;

  &::before {
    width: 20px;
    height: 35px;
    border: 2px solid ${colors.white};
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
    display: block;
    content: '';
  }

  &:after {
    width: 4px;
    height: 12px;
    border-radius: 25%;
    background-color: ${colors.white};
    animation-name: scroll;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;
    display: block;
    position: absolute;
    top: 25%;
    left: 50%;
    content: '';
  }
`

export const HeroBody = styled.div`
  padding-top: 4.75rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  ${({ isVideo }) => ({
    alignItems: isVideo ? 'center' : 'flex-end'
  })};

  @media (max-width: ${breakpoints.tablet}) {
    align-items: center;
  }
  @media (orientation: landscape) {
    padding: 0 !important;
  }
  @media (min-width: ${breakpoints.desktop}) {
    padding-top: 5.75rem;
    padding-bottom: 3rem;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    min-height: 100vh;

    .container {
      width: 100%;
    }
  }
  @media (max-width: ${breakpoints.desktop}) {
    .icon {
      height: auto;
      width: auto;
      margin-left: 0 !important;

      .fa-play-circle {
        font-size: 40px;
      }
    }
  }
  span {
    .is-small {
      text-transform: lowercase !important;
    }
  }
`

export const PlayIconWrapper = styled.span`
  font-size: ${sizes.size1};

  @media (max-width: ${breakpoints.desktop}) {
    height: auto;
    width: auto;
    margin-left: 0 !important;
  }
`

export const playIconStyles = css`
  ${mq({
    width: [40, undefined, '1em']
  })};
`

export const gatsbyImage = css`
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  max-height: none;
  max-width: none;
  width: 100vw;
  objectfit: cover;

  @media (orientation: portrait) and (min-width: 700px) {
    height: 125%;
  }
`

export const ImgText = styled.div`
  max-width: 400px;
  width: 100%;
  line-height: 1;

  p {
    @media (max-width: ${breakpoints.tablet}) {
      text-align: center;
    }
    strong {
      color: ${colors.white} !important;
    }
  }
  span {
    @media (max-width: ${iphoneXLandscape}) {
      font-size: ${({ isGreek }) => (isGreek ? '1.5rem' : '1.9rem')} !important;
    }
  }
  h1 {
    strong {
      font-size: ${({ isGreek }) => (isGreek ? '1.75rem' : '2rem')};
    }
  }
`

export const modalStyle = css`
  .modal-content {
    ${mq({
      width: [undefined, '100%', '85%', '80%']
    })}
  }
`

export const hasFrame = css`
  h1 {
    ${frame({ color: 'white' })}
  }
`
