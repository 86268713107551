import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useIntl } from 'gatsby-plugin-intl'
import _get from 'lodash.get'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'

import { findyByLangcode } from 'src/utils/layout'
import useTracking from 'src/hooks/useTracking'
import useMatchMedia from '../../hooks/useMatchMedia'
import { createPageData, createProductDigitalData, getCategory } from 'src/utils/tracking'
import {
  getMetaTitle,
  findByTypeName,
  filterByTypeName
  // findLocalePageUrlData,
  // getPageUrl,
  // getImageForProductVariant
} from 'src/utils/shared'
import { INTL_TAG } from './utils'
// import { buildProductSchema } from './schema'
import { enableNaturalHairCare } from '../../consts/consts'
import ProductBanner from './sections/ProductBanner'
import Ingredients from './sections/Ingredients'
import HowToUse from './sections/HowToUse'
import RelatedProducts from './sections/RelatedProducts'
import Icons from './sections/Icons'
import HairCare from './sections/HairCare'

import * as S from './styles/productPage'

const createRetailerArr = (arr) =>
  arr?.reduce((p, c) => {
    return [...p, { retailerLink: c?.field_wtb_link?.uri }]
  }, [])

const ProductPage = ({ data, pageContext }) => {
  const intl = useIntl()
  const {
    nodeProduct: {
      id,
      title,
      drupal_internal__nid,
      body: { value: description },
      relationships,
      field_metatag_normalized: metaTags,
      field_breadcrumb: breadcrumbs
    },
    salonFinderData,
    mobileNhcPdpImg: {
      childImageSharp: { gatsbyImageData: mobileNhcPdpImg }
    },
    desktopNhcPdpImg: {
      childImageSharp: { gatsbyImageData: desktopNhcPdpImg }
    }
  } = data

  useMatchMedia('(min-width: 769px)')

  const sections = _get(relationships, 'field_sections')
  const categoryTitle = _get(relationships, 'field_p_category')[0].title

  const productVariants = findByTypeName({ arr: sections, typeName: 'paragraph__product_variants' })
  const productVideos = findByTypeName({ arr: sections, typeName: 'paragraph__product_videos' })
  const icons = findByTypeName({ arr: sections, typeName: 'paragraph__icons' })
  const ingredients = filterByTypeName({ arr: sections, typeName: 'paragraph__ingredients' })
  const howToUseData = findByTypeName({ arr: sections, typeName: 'paragraph__how_to_use' })
  const relatedProducts = findByTypeName({ arr: sections, typeName: 'paragraph__related_products' })

  const items = _get(productVariants, 'relationships.field_p_variant', [])
  const videos = _get(productVideos, 'relationships.field_p_video_item', [])

  useTracking({
    data: createProductDigitalData({
      instanceName: 'product',
      sku: id,
      primaryCategory: 'products',
      subCategory1: getCategory(categoryTitle),
      name: title,
      descFull: description,
      type: categoryTitle,
      packsize: items[0]?.field_pv_size,
      productImage:
        items[0]?.relationships?.field_pv_image[0]?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src, // eslint-disable-line max-len
      retailersInfo:
        intl.locale === 'de-de' ? undefined : createRetailerArr(items[0]?.relationships?.field_pv_where_to_buy),
      pageData: createPageData({
        pageId: title,
        breadCrumbs: `home:products:${categoryTitle}:${title}`,
        pageType: 'product page',
        instanceName: 'product page',
        primaryCategory: 'product',
        language: intl.locale,
        subCategory1: categoryTitle,
        pageTitle: getMetaTitle(metaTags),
        pageName: pageContext.slug
      })
    })
  })
  const nhcPdpImg = {
    image: [mobileNhcPdpImg, desktopNhcPdpImg],
    alt: intl.formatMessage({ id: 'images.homepage.nhc' })
  }

  const relatedProductsData = _get(relatedProducts, 'relationships.field_related_product', [])

  const sfPath = salonFinderData && findyByLangcode({ array: salonFinderData?.field_hreflang, locale: intl.locale })
  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs} disableFooterIcons>
      <SEO
        // schema={buildProductSchema({
        //   similarProducts: relatedProductsData,
        //   category: breadcrumbs[breadcrumbs.length - 2].crumbLabel,
        //   image: getImageForProductVariant(productVariants?.relationships),
        //   variants: items,
        //   name: title,
        //   url: getPageUrl(findLocalePageUrlData(intl.locale, pageContext.pageUrls))
        // })}
        pageUrls={pageContext.pageUrls}
        fetchedMetaTags={metaTags}
        lang={pageContext.intl.language}
      />
      {!!productVariants?.relationships && (
        <ProductBanner
          title={title}
          description={description}
          productVariants={items}
          productVideos={videos}
          crumbs={breadcrumbs}
          type={categoryTitle}
          id={id}
          nid={drupal_internal__nid}
          intl={intl}
          sfPath={sfPath && sfPath[0]?.href}
        />
      )}
      {icons?.relationships && <Icons intl={intl} productIcons={icons.relationships.field_i_icon} />}
      <S.Disclaimer className="container">
        <div className="columns">
          <div className="column has-padding-vertical-2__mobile has-padding-vertical-3 has-margin-bottom-4-negative__mobile has-margin-bottom-3-negative__nonmobile">
            <S.DisclaimerText className="has-text-justified has-padding-bottom-2 is-size-7">
              {intl.formatMessage({ id: `${INTL_TAG}.disclaimer_top` })}
            </S.DisclaimerText>
            <b className="is-uppercase is-size-7">{intl.formatMessage({ id: `${INTL_TAG}.disclaimer_mid` })}</b>
            <p className="has-text-justified has-padding-top-1 is-size-7">
              {intl.formatMessage({ id: `${INTL_TAG}.disclaimer_bottom` })}
            </p>
          </div>
        </div>
      </S.Disclaimer>
      {enableNaturalHairCare.includes(intl.locale) && <HairCare intl={intl} img={nhcPdpImg} />}
      {!!ingredients?.length && <Ingredients ingredients={ingredients} intl={intl} />}
      {howToUseData && <HowToUse intl={intl} {...howToUseData} />}

      {!!relatedProductsData?.length && <RelatedProducts intl={intl} data={relatedProductsData} />}
    </Layout>
  )
}

export default ProductPage

ProductPage.whyDidYouRender = {
  logOnDifferentValues: true
}

ProductPage.propTypes = {
  data: PropTypes.shape({
    nodeProduct: PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      drupal_internal__nid: PropTypes.number.isRequired,
      body: PropTypes.shape({
        value: PropTypes.string.isRequired
      }),
      relationships: PropTypes.shape({
        field_sections: PropTypes.arrayOf(
          PropTypes.shape({
            relationships: PropTypes.shape({
              field_pv_image: PropTypes.arrayOf(
                PropTypes.shape({
                  localFile: PropTypes.shape({
                    childImageSharp: PropTypes.object
                  })
                })
              )
            })
          })
        ),
        field_p_category: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string
          })
        )
      }),

      field_metatag_normalized: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.string,
          name: PropTypes.string
        })
      ),
      field_breadcrumb: PropTypes.arrayOf(
        PropTypes.shape({
          crumbLabel: PropTypes.string,
          pathname: PropTypes.string
        })
      ).isRequired
    }),
    sectionHeader: PropTypes.shape({
      title: PropTypes.string.isRequired,
      body: PropTypes.shape({
        value: PropTypes.string.isRequired
      })
    }),
    salonFinderData: PropTypes.shape({
      field_hreflang: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string.isRequired,
          langcode: PropTypes.string.isRequired
        })
      )
    }),
    mobileNhcPdpImg: PropTypes.shape({
      childImageSharp: PropTypes.arrayOf(
        PropTypes.shape({
          gatsbyImageData: PropTypes.object
        })
      )
    }),
    desktopNhcPdpImg: PropTypes.shape({
      childImageSharp: PropTypes.arrayOf(
        PropTypes.shape({
          gatsbyImageData: PropTypes.object
        })
      )
    })
  }),
  pageContext: PropTypes.object,
  location: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired
    })
  )
}

export const query = graphql`
  query ProductPage(
    $slug: String!
    $productVariantHeight: Int! = 565
    $productVariantWidth: Int! = 426
    $productVariantQuality: Int! = 75
    $langKey: String!
  ) {
    # NATURAL HAIR CARE IMAGES
    mobileNhcPdpImg: file(relativePath: { eq: "pdp-nhc-mobile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 600
          quality: 60
          webpOptions: { quality: 64 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.25, 0.35, 0.5, 0.75, 1]
        )
      }
    }
    desktopNhcPdpImg: file(relativePath: { eq: "pdp-nhc-desktop.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 660
          quality: 70
          webpOptions: { quality: 72 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.25, 0.35, 0.5, 0.75, 1]
        )
      }
    }

    salonFinderData: nodePage(field_component: { eq: "find-salon" }, langcode: { eq: $langKey }) {
      field_hreflang {
        href
        langcode: hreflang
      }
    }
    nodeProduct(path: { alias: { eq: $slug } }, status: { eq: true }, langcode: { eq: $langKey }) {
      ...PageDataFragment
      drupal_internal__nid
      relationships {
        field_p_category {
          title
        }
        field_sections {
          ... on paragraph__product_variants {
            __typename
            relationships {
              field_p_variant {
                relationships {
                  field_pv_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          quality: 70
                          webpOptions: { quality: 72 }
                          sizes: "(min-width: 1008px) 34vw, (min-width: 769px) 30vw, 100vw"
                          outputPixelDensities: [0.25, 0.35, 0.5, 0.75, 1]
                          formats: [JPG, WEBP]
                        )
                        dotImage: gatsbyImageData(
                          layout: FIXED
                          quality: 70
                          width: 50
                          webpOptions: { quality: 72 }
                          formats: [JPG, WEBP]
                        )
                      }
                    }
                  }
                  field_pv_where_to_buy {
                    field_wtb_country
                    field_wtb_link {
                      uri
                    }
                    relationships {
                      field_wtb_retailer_reference {
                        name
                        relationships {
                          field_logotype {
                            localFile {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
                field_pv_image {
                  alt
                }
                field_pv_size
                field_pv_default
              }
            }
          }
          ... on paragraph__product_videos {
            __typename
            relationships {
              field_p_video_item {
                field_p_video_url {
                  uri
                }
                relationships {
                  field_p_video_cover {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          quality: 80
                          webpOptions: { quality: 82 }
                          sizes: "(min-width: 1008px) 25vw, (min-width: 768px) 33.3vw, 50vw"
                          outputPixelDensities: [0.25, 0.35, 0.5, 0.75, 1]
                          formats: [JPG, WEBP]
                        )
                        dotImage: gatsbyImageData(layout: FIXED, quality: 70, width: 50, webpOptions: { quality: 72 })
                      }
                    }
                  }
                }
                field_p_video_cover {
                  alt
                }
              }
            }
          }
          ... on paragraph__icons {
            __typename
            relationships {
              field_i_icon {
                field_i_icon_name
                field_i_text {
                  value
                }
              }
            }
          }
          ... on paragraph__ingredients {
            __typename
            id
            field_p_ingredients_all {
              value
            }
            relationships {
              field_p_ingredient_main {
                title
                path {
                  alias
                }
                hideBuild: field_i_hide_on_ingredients_page
                relationships {
                  field_i_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          width: 660
                          quality: 90
                          webpOptions: { quality: 90 }
                          aspectRatio: 1.33
                          formats: [JPG, WEBP]
                        )
                      }
                    }
                  }
                }
                field_i_image {
                  alt
                }
              }
            }
          }
          ... on paragraph__how_to_use {
            __typename
            id
            field_htu_body {
              value
            }
            field_htu_title
            field_htu_video {
              uri
            }
            relationships {
              field_htu_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 768
                      quality: 75
                      webpOptions: { quality: 75 }
                      formats: [JPG, WEBP]
                    )
                  }
                }
              }
            }
            field_htu_image {
              alt
            }
          }
          ... on paragraph__related_products {
            __typename
            relationships {
              field_related_product {
                title
                field_p_subtitle
                id
                drupal_internal__nid
                path {
                  alias
                }
                relationships {
                  field_sections {
                    ...ProductVariantFragment
                  }
                  field_p_image_on_hover {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          width: 426
                          height: 565
                          quality: 75
                          webpOptions: { quality: 75 }
                          formats: [JPG, WEBP]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
