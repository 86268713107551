import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Modal from 'src/components/Modal'

import { COUNTRIES_FLAGS } from '../consts'
import CountryShopsList from './CountryShopsList'

import { getListToRender, getShopsPerCountry } from '../../utils'

import { textSmall } from '../../styles/components'

const BuyNowModal = ({ toggleModal, intl, intlTag, shopsList = [], onClick }) => {
  const hasCountryInList = shopsList?.find(({ field_wtb_country }) => field_wtb_country)
  const listToRender = getListToRender({ hasCountryInList, shopsList })
  const shopsPerCountry = getShopsPerCountry(shopsList)

  return (
    <Modal onClick={toggleModal}>
      <div className="box is-marginless has-padding-0-horizontal has-padding-2-vertical">
        <div className="columns is-vcentered is-marginless is-paddingless">
          <div className="column is-marginless is-paddingless">
            <div className="box">
              <h1
                css={`
                  ${intl.locale === 'el-gr' ? textSmall : ''}
                `}
              >
                {intl.formatMessage({
                  id: hasCountryInList ? `${intlTag}.modal_header_country` : `${intlTag}.modal_header_retailer`
                })}
              </h1>
            </div>
          </div>

          <div className="column countries-list">
            {shopsPerCountry.length ? (
              <CountryShopsList shops={shopsPerCountry} handleShopClick={onClick} intl={intl} />
            ) : (
              listToRender.map(({ field_wtb_country, relationships, field_wtb_link }) => {
                const Icon =
                  COUNTRIES_FLAGS[field_wtb_country] ||
                  relationships?.field_wtb_retailer_reference?.relationships?.field_logotype?.localFile?.url
                const handleShopClick = () => {
                  onClick(field_wtb_link.uri)
                }

                const optionText = field_wtb_country || relationships?.field_wtb_retailer_reference?.name

                return (
                  <a
                    data-key={optionText}
                    key={optionText}
                    href={field_wtb_link.uri}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-size-8 is-uppercase has-text-left is-light has-margin-bottom-1"
                    onClick={handleShopClick}
                    css={{ display: 'inline-flex', width: '100%' }}>
                    {Icon && <img src={Icon} width={`${intl.locale === 'ru-ru' ? 90 : 30}`} height="30" className="has-margin-right-3" />}
                    {optionText}
                  </a>
                )
              })
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default memo(BuyNowModal)

BuyNowModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  intlTag: PropTypes.string.isRequired,
  shopsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired
}
