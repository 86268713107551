import React, { memo, useEffect, FC } from 'react'

import { createSalonFinderScript } from './utils'

import Hero from 'src/components/Hero'
import { salonFinder } from './styled'

import type { SalonFinderProps } from './types'

const SalonFinder: FC<SalonFinderProps> = ({ hero = '', heroBody = '', children, locale }) => {
  useEffect(() => {
    // first run setup
    if (!window.sfInitReady) {
      createSalonFinderScript(locale)
      window.sfInitReady = true

      return
    }

    // after first run when script should be attached and ready
    if (window?.initializeSalonFinderWidget && window?.sfInitReady) {
      return window.initializeSalonFinderWidget()
    }
  }, [locale])

  return (
    <Hero
      hero={hero}
      heroBody={`has-padding-top-0 has-padding-bottom-0 has-padding-bottom-2__nonmobile ${heroBody}`}
      customCSS={salonFinder}
    >
      {children}
      <div css={salonFinder} id="SalonFinderWidgetAnchor" />
    </Hero>
  )
}

export default memo(SalonFinder)
