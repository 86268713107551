import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-plugin-intl'

import Hero from 'src/components/Hero'
import ProductCard from 'src/components/ProductCard'

import layoutIcons from 'src/assets/images/layout-icons.svg'

import { getArtDirection } from 'src/utils/shared'
import * as S from '../styles/whatWeDo'
import { title } from '../styles/styled'
import { iconSvgStyles, iconUseStyles } from 'src/themes'

const INTL_TAG = 'homepage.what_we_do'

const WhatWeDo = ({ intl, img, imgBg, data = [], btnLink }) => {
  return (
      <Hero hero="is-primary is-clipped wedo-what-we-create" customCSS={S.staticPos}>
        <GatsbyImage image={getArtDirection(imgBg.image)} alt={imgBg.alt} css={S.handsImg} className="is-hidden-touch" />
        <div className="columns is-multiline has-margin-bottom-4 img-packshot">
          <div className="is-offset-4-touch is-offset-2-desktop column is-8-touch is-5-desktop">
            <GatsbyImage image={getArtDirection(img.image)} alt={img.alt} css={S.gatsbyImage} />
          </div>
          <div className="column is-full-touch is-7-desktop">
            <h2 className="title is-inline-block" css={(S.titleGR, title('white'))}>
              {intl.formatMessage({ id: `${INTL_TAG}.header` })}
            </h2>
            <p
                className="has-margin-bottom-2"
                dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `${INTL_TAG}.desc` }) }}
            ></p>
            <div className="columns is-block is-flex-desktop is-flex-vcentered">
              <div className="column is-full-touch is-half-desktop has-margin-vertical-2 has-margin-vertical-0-desktop">
                <Link to={btnLink} className="button is-outlined is-white-alpha is-uppercase is-fullwidth is-half-widescreen">
                  {intl.formatMessage({ id: `${INTL_TAG}.button_view` })}
                </Link>
                
                {intl.formatMessage({id: `${INTL_TAG}.cruel_free_link`}).
                    localeCompare(`${INTL_TAG}.cruel_free_link`) !== 0 && (
                    <Link to={intl.formatMessage(
                        {id: `${INTL_TAG}.cruel_free_link`})} className="cruel-free-linkbutton button is-outlined is-white-alpha is-uppercase is-fullwidth is-half-widescreen has-margin-top-1">
                      {intl.formatMessage(
                          {id: `${INTL_TAG}.cruel_free_link_name`})}
                      <span className="is-sr-only">{intl.formatMessage({id: `${INTL_TAG}.cruel_free_link_sr_label`})}</span>
                    </Link>
                )}
              </div>
              <div className="column if-full-touch is-half-desktop has-margin-vertical-3 has-margin-vertical-0-desktop is-flex is-flex-centered" css={S.svgIcons}>
                <a
                    href="https://crueltyfreeinternational.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="has-margin-horizontal-1__mobile has-margin-horizontal-2__nonmobile"
                >
                  <svg css={iconSvgStyles('80px')} fill="#fff" className="icon-wrapper">
                    <use css={iconUseStyles('80px')} className="icon" xlinkHref={`${layoutIcons}#icon-bunny`} />
                  </svg>
                </a>
                <a
                    href="https://www.vegansociety.com/your-business/about-vegan-trademark"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="has-margin-horizontal-1__mobile has-margin-horizontal-2__nonmobile"
                >
                  <svg css={iconSvgStyles('85px')} fill="#fff" className="icon-wrapper vegan-wrapper">
                    <use
                        css={iconUseStyles('90px')}
                        className="icon icon-vegan"
                        xlinkHref={`${layoutIcons}#icon-vegan`}
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <GatsbyImage
            image={getArtDirection(imgBg.image)}
            alt={imgBg.alt}
            className="img-hands is-hidden-mobile is-hidden-tablet"
        />
        <div data-test="weblog-recent-section" className="columns is-multiline is-mobile">
          {data.map(({ id, ...card }) => (
              <div className="column is-half-mobile is-one-third-tablet is-one-quarter-widescreen" key={id}>
                <ProductCard intl={intl} isClickable className="has-text-white" {...card} alternativeStyle />
              </div>
          ))}
        </div>
      </Hero>
  )
}

export default WhatWeDo

WhatWeDo.propTypes = {
  img: PropTypes.shape({
    image: PropTypes.arrayOf(PropTypes.object).isRequired,
    alt: PropTypes.string
  }),
  imgBg: PropTypes.shape({
    image: PropTypes.arrayOf(PropTypes.object).isRequired,
    alt: PropTypes.string
  }),
  intl: PropTypes.object,
  data: PropTypes.arrayOf(
      PropTypes.shape({
        field_p_subtitle: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        relationships: PropTypes.shape({
          field_sections: PropTypes.arrayOf(
              PropTypes.shape({
                relationships: PropTypes.shape({
                  field_pv_image: PropTypes.arrayOf(
                      PropTypes.shape({
                        alt: PropTypes.string,
                        localFile: PropTypes.shape({
                          childImageSharp: PropTypes.object
                        })
                      })
                  )
                })
              })
          )
        })
      })
  ),
  btnLink: PropTypes.string.isRequired
}
