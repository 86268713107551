import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { sizes, mq, padding, transition, cardCSS } from 'src/themes'

import { AlternativeStyleControls } from './types'

const cardStyles =
  (alternativeStyle: boolean) =>
  ({ sizeMobile, size }: { sizeMobile: string; size: string }) =>
    alternativeStyle
      ? {
          textAlign: 'center',
          fontSize: [size]
        }
      : mq({
          textAlign: [undefined, 'center'],
          fontSize: [sizeMobile, size]
        })

const carouselStyles = css`
  .slick-slide & {
    opacity: 0;
    transition: ${transition};
  }

  .slick-active & {
    opacity: 1;
  }
`

export const CardTitle = styled.h3<AlternativeStyleControls>`
  font-weight: bold;
  ${({ alternativeStyle }) => cardStyles(alternativeStyle)({ sizeMobile: sizes.size8, size: 'initial' })};
`

export const CardDescription = styled.p<AlternativeStyleControls>`
  ${({ alternativeStyle }) => cardStyles(alternativeStyle)({ sizeMobile: sizes.size9, size: sizes.size7 })};
`
export const Card = styled.div`
  ${cardCSS}
`

export const CardFooter = styled.footer`
  border: none;
  ${carouselStyles};
`

export const CardContent = styled.div`
  flex: 1;
  padding-left: ${padding.x1};
  padding-right: ${padding.x1};
  ${carouselStyles};

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    flex: auto;
    flex-grow: 1;
  }
`

export const primaryImageStyles = css`
  position: absolute;
  margin: 0 1px;
`

export const secondaryImageStyles = css`
  transition: ${transition};

  @media (hover: hover) {
    &:hover {
      opacity: 0;
    }
  }
`
