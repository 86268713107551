import React, { FC } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { graphql } from 'gatsby'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'
import CategoryCard from 'src/components/CategoryCard'

import useTracking from 'src/hooks/useTracking'
import { createPageData, createContentPageDigitalData } from 'src/utils/tracking'
import { getMetaTitle } from 'src/utils/shared'

import { CategoryCardColumn } from 'src/themes'

import type { IngredientsProps } from './types'

const Ingredients: FC<IngredientsProps> = ({ data, pageContext }) => {
  const intl = useIntl()

  const {
    nodePage: {
      title,
      body: { value: description },
      field_breadcrumb: breadcrumbs,
      field_metatag_normalized: metaTags
    },
    allNodeIngredient: { nodes: ingredients }
  } = data

  useTracking({
    data: createContentPageDigitalData({
      contentType: 'our-ingredients',
      contentTitle: title,

      pageData: createPageData({
        pageId: title,
        breadCrumbs: `home:our-story:our-ingredients`,
        pageType: 'our ingredients',
        instanceName: 'our ingredients',
        primaryCategory: 'our ingredients',
        language: intl.locale,
        pageTitle: getMetaTitle(metaTags),
        pageName: pageContext.slug
      })
    })
  })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
        <div className="columns has-margin-bottom-2">
          <h1 className="column is-3-tablet is-2-widescreen title__page">{title}</h1>
          <div className="column is-two-thirds-widescreen" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className="columns is-multiline">
          {ingredients?.map(({ id, title, path, relationships, field_i_image }) => (
            <CategoryCardColumn className="column is-half-tablet is-one-third-desktop" key={id}>
              <CategoryCard
                isClickable
                title={title}
                buttonText={intl.formatMessage({ id: 'shared.explore' })}
                buttonUri={path.alias}
                image={relationships?.field_i_image}
                alt={field_i_image?.alt}
                testAttr="ingredient-card"
              />
            </CategoryCardColumn>
          ))}
        </div>
      </Hero>
    </Layout>
  )
}

export default Ingredients

export const query = graphql`
  query OurIngredients($slug: String!, $langKey: String!) {
    nodePage(field_component: { eq: "ingredients" }, path: { alias: { eq: $slug } }, langcode: { eq: $langKey }) {
      ...PageDataFragment
    }
    allNodeIngredient(
      filter: {
        path: { alias: { ne: null } }
        langcode: { eq: $langKey }
        field_i_hide_on_ingredients_page: { ne: true }
      }
    ) {
      nodes {
        id
        title
        path {
          alias
        }
        field_i_image {
          alt
        }
        relationships {
          field_i_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 768
                  quality: 75
                  webpOptions: { quality: 75 }
                  formats: [JPG, WEBP]
                  aspectRatio: 1
                )
              }
            }
          }
        }
      }
    }
  }
`
