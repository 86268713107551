import React, { useState, useCallback, useEffect, useRef, useMemo, FC } from 'react'

import { getAgreementsUrl, validateForm, getAgreementsLangCode, mapAgreementsPropertyNames } from 'src/utils/form'
import { fetchPost, createDefaultPortal } from 'src/utils/shared'
import useFormState from 'src/hooks/useFormState'
import useToggleModal from 'src/hooks/useToggleModal'
import { RECAPTCHA_KEY, EMAIL_REGEX, SUBSCRIBE_FETCH_URL } from 'src/consts'
import contactCompleteEvent from 'src/dataLayer/Contact'

import Modal from 'src/components/Modal'
import ModalContent from 'src/components/ModalContent'
import Input from 'src/components/Form/Input'
import Select from 'src/components/Form/Select'
import Textarea from 'src/components/Form/Textarea'
import Recaptcha from 'src/components/Form/Recaptcha'
import Agreements from 'src/components/Form/Agreements'
import Checkbox from 'src/components/Form/Checkbox';
import CustomerFields from '../components/CustomerFields'

import type { ErrorState } from 'src/types'
import type { FormProps, AgreementsData, FieldProps } from 'src/types'

const OPTION_TO_SHOW_EXTRA_INPUTS = 'Becomeacustomer'
const REQUIRED_FIELDS = [
  'Country',
  'ContactType',
  'FirstName',
  'LastName',
  'Email',
  'ConfirmEmailAddress',
  'Subject',
  'Message',
  'Response',
  'Site'
]
const REQUIRED_CUSTOMER_FIELDS = [
  'SalonNameReq',
  'SalonAddressReq',
  'SalonPhoneReq',
  'SalonManagerEmailReq',
  'StylistNumbPerSalonReq'
]
const INTL_TAG = 'contact_us'
const INTL_FORM_TAG = 'form'

const initialState = {
  FormCode: 'Wedo_Contact',
  Site: RECAPTCHA_KEY,
  Response: null,
  PhoneNumber: ''
}

const getEmailRepeatErrorMessage = ({ ConfirmEmailAddress, Email }) => {
  if (!ConfirmEmailAddress) {
    return `${INTL_FORM_TAG}.required`
  }

  if (!!ConfirmEmailAddress && ConfirmEmailAddress !== Email) {
    return `${INTL_FORM_TAG}.invalid_email_confirm`
  }

  return `${INTL_FORM_TAG}.invalid_data`
}

const Form: FC<FormProps> = ({ intl, countries, language, contactImgPopup }) => {
  const [errorState, setErrorState] = useState<ErrorState>({})
  const [isFetching, setFetchingState] = useState(false)

  const agreements = useRef(null)
  const recaptchaRef = useRef(null)

  const { isModalOpen, toggleModal } = useToggleModal()
  const {
    state: formState,
    handleInputChange,
    checkboxState,
    handleCheckboxChange,
    setCheckboxState,
    handleRecaptchaVerify,
    handleRecaptchaExpire,
    clearForm
  } = useFormState({
    initialState,
    errorState,
    setErrorState
  })
  // customer extra fields
  const {
    state: customerFieldsState,
    handleInputChange: handleCustomerInputChange,
    setFormState: setCustomerState
  } = useFormState({
    errorState,
    setErrorState
  })

  useEffect(() => {
    const url = getAgreementsUrl({
      formCode: 'Wedo_Contact',
      Language: getAgreementsLangCode(intl.locale)
    })

    fetch(url)
      .then((resp) => resp.json())
      .then(({ Data }: AgreementsData) => {
        agreements.current = mapAgreementsPropertyNames(Data.Agreements)
      })
      .then(() => {
        agreements.current.forEach(({ name, required }) => {
          if (required) {
            REQUIRED_FIELDS.push(name)
          }
        })

        const initialCheckboxState = agreements.current.reduce(
          (total, { name, required }) => ({
            ...total,
            [name]: required ? null : false
          }),
          {}
        )
  
      if (intl.locale == 'de-de') {
        agreements.current.find(f => f.name == 'Privacy').label = intl.formatMessage({id: `${INTL_TAG}.privacy_de_agrement`});
      }
      
      setCheckboxState(initialCheckboxState)
      })
      .catch(() => {
        setFetchingState(true)
      })
  }, [setCheckboxState, intl.locale])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      
      if (intl.locale == 'de-de') {
        REQUIRED_FIELDS.push('Terms');
      }
      
      const formError = validateForm({ formState: { ...formState, ...checkboxState }, requiredFields: REQUIRED_FIELDS })
      let customerFormError = {}
      
      if (intl.locale == 'it-it') {
        REQUIRED_CUSTOMER_FIELDS.push('City');
        REQUIRED_CUSTOMER_FIELDS.push('PostalCode');
      }
      
      if (formState.Subject === OPTION_TO_SHOW_EXTRA_INPUTS) {
        customerFormError = validateForm({
          formState: customerFieldsState,
          requiredFields: REQUIRED_CUSTOMER_FIELDS
        })
      }

      if (Object.values({ ...formError, ...customerFormError }).includes(true)) {
        setErrorState({ ...formError, ...customerFormError })
        contactCompleteEvent({ action: 'error' })
        return
      }

      const dataToFetch = {
        ...formState,
        AdditionalField: customerFieldsState,
        Agreements:
          agreements?.current &&
          agreements.current.map(({ id, name }) => ({
            Id: id,
            Value: checkboxState[name]
          }))
      }

      setFetchingState(true)

      try {
        const json = await fetchPost({ url: SUBSCRIBE_FETCH_URL, body: dataToFetch })

        if (!json.ErrorMessage) {
          toggleModal()
          contactCompleteEvent({ action: 'complete' })
        } else contactCompleteEvent({ action: 'error' })
      } finally {
        setFetchingState(false)
      }
    },
    [formState, customerFieldsState, checkboxState, toggleModal]
  )

  const handleModalClose = useCallback(() => {
    clearForm()
    setCustomerState({})
    recaptchaRef.current.reset()
    toggleModal()
  }, [clearForm, setCustomerState, toggleModal])

  const selectContactTypeOptions: FieldProps[] = useMemo(
    () => [
      { label: intl.formatMessage({ id: `${INTL_FORM_TAG}.professional` }), value: 'Professional' },
      { label: intl.formatMessage({ id: `${INTL_FORM_TAG}.consumer` }), value: 'Consumer' }
    ],
    [intl]
  )

  const selectSubjectOptions = useMemo(
    () => [
      { label: intl.formatMessage({ id: `${INTL_FORM_TAG}.product_treatment` }), value: 'ProductandTreatment' },
      { label: intl.formatMessage({ id: `${INTL_FORM_TAG}.after_sales` }), value: 'Aftersales' },
      { label: intl.formatMessage({ id: `${INTL_FORM_TAG}.where_to_order` }), value: 'Wheretoorder' },
      { label: intl.formatMessage({ id: `${INTL_FORM_TAG}.become_customer` }), value: 'Becomeacustomer' },
      { label: intl.formatMessage({ id: `${INTL_FORM_TAG}.training_and_seminar` }), value: 'TrainingandSeminar' },
      { label: intl.formatMessage({ id: `${INTL_FORM_TAG}.press` }), value: 'Press' },
      { label: intl.formatMessage({ id: `${INTL_FORM_TAG}.others` }), value: 'Others' }
    ],
    [intl]
  )

  const {
    Country,
    FirstName,
    LastName,
    Email,
    ConfirmEmailAddress,
    Subject,
    ContactType,
    Message,
    Response,
    PhoneNumber
  } = formState

  const {
    SalonNameReq,
    SalonAddressReq,
    City,
    PostalCode,
    SalonPhoneReq,
    SalonManagerEmailReq,
    StylistNumbPerSalonReq,
  } = customerFieldsState

  const isInvalidConfirmEmail =
    ConfirmEmailAddress === '' ||
    (!!ConfirmEmailAddress && ConfirmEmailAddress !== Email) ||
    (!!ConfirmEmailAddress && !EMAIL_REGEX.test(ConfirmEmailAddress)) ||
    errorState.ConfirmEmailAddress

  return (
    <form>
      <fieldset disabled={isFetching}>
        <div className="columns is-narrow">
          <div className="column is-7-tablet is-5-widescreen has-padding-bottom-0">
            <div className="has-margin-bottom-2">
              <h2 className="column is-inline-block title has-frame">
                {intl.formatMessage({ id: `${INTL_TAG}.get_in_touch` })}
              </h2>
            </div>
            <Select
              label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.choose_country` })}*`}
              name="Country"
              options={countries}
              onChange={handleInputChange}
              required
              value={Country}
              isInvalid={Country === '' || errorState.Country}
              errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
              placeholder={intl.formatMessage({ id: `${INTL_FORM_TAG}.please_select` })}
            />
            <Select
              label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.im` })}*`}
              name="ContactType"
              options={selectContactTypeOptions}
              onChange={handleInputChange}
              required
              value={ContactType}
              isInvalid={ContactType === '' || errorState.ContactType}
              errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
              placeholder={intl.formatMessage({ id: `${INTL_FORM_TAG}.please_select` })}
            />
          </div>
        </div>
        <Input
          label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.first_name` })}*`}
          required
          name="FirstName"
          onChange={handleInputChange}
          value={FirstName}
          isInvalid={FirstName === '' || errorState.FirstName}
          errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
        />
        <Input
          label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.last_name` })}*`}
          required
          name="LastName"
          onChange={handleInputChange}
          value={LastName}
          isInvalid={LastName === '' || errorState.LastName}
          errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
        />
        <Input
          label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.email` })}*`}
          required
          name="Email"
          onChange={handleInputChange}
          value={Email}
          isInvalid={Email === '' || (!!Email && !EMAIL_REGEX.test(Email)) || errorState.Email}
          errorMessage={intl.formatMessage({
            id: !Email ? `${INTL_FORM_TAG}.required` : `${INTL_FORM_TAG}.invalid_data`
          })}
        />
        <Input
          label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.confirm_email` })}*`}
          required
          name="ConfirmEmailAddress"
          onChange={handleInputChange}
          value={ConfirmEmailAddress}
          isInvalid={isInvalidConfirmEmail}
          errorMessage={intl.formatMessage({
            id: getEmailRepeatErrorMessage({ Email, ConfirmEmailAddress })
          })}
        />
        <Input
          label={intl.formatMessage({ id: `${INTL_FORM_TAG}.phone` })}
          name="PhoneNumber"
          type="tel"
          onChange={handleInputChange}
          value={PhoneNumber}
        />
        <div className="columns">
          <div className="column is-5 has-padding-bottom-0">
            <Select
              label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.subject` })}*`}
              name="Subject"
              options={selectSubjectOptions}
              onChange={handleInputChange}
              required
              value={Subject}
              isInvalid={Subject === '' || errorState.Subject}
              errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
              placeholder={intl.formatMessage({ id: `${INTL_FORM_TAG}.please_select` })}
            />
          </div>
        </div>
        {Subject === OPTION_TO_SHOW_EXTRA_INPUTS && (
          <CustomerFields
            intl={intl}
            intlTag={INTL_FORM_TAG}
            errorState={errorState}
            onChange={handleCustomerInputChange}
            SalonNameReq={SalonNameReq}
            SalonAddressReq={SalonAddressReq}
            City={City}
            PostalCode={PostalCode}
            SalonPhoneReq={SalonPhoneReq}
            SalonManagerEmailReq={SalonManagerEmailReq}
            StylistNumbPerSalonReq={StylistNumbPerSalonReq}
          />
        )}
        <Textarea
          name="Message"
          label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.message` })}*`}
          onChange={handleInputChange}
          required
          value={Message}
          isInvalid={Message === '' || errorState.Message}
          errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
        />
        <div className="field is-size-7" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `${INTL_TAG}.checkbox_desc` }) }}></div>
        {!!agreements.current && (
          <Agreements
            agreements={agreements.current}
            handleCheckboxChange={handleCheckboxChange}
            checkboxState={checkboxState}
            errorState={errorState}
          />
        )}
        {intl.locale === 'de-de' &&
          <Checkbox
            name={'Terms'}
            label={intl.formatMessage({id: `${INTL_TAG}.terms_cond_de_agrement`})}
            onChange={handleCheckboxChange}
            value={checkboxState['Terms']}
            isInvalid={(checkboxState['Terms'] === false || errorState['Terms'])}
            errorMessage={intl.formatMessage({id: `${INTL_FORM_TAG}.required`})}
            fieldClass="is-size-7 has-margin-vertical-2"
            required
          />
        }
        <Recaptcha
          sitekey={RECAPTCHA_KEY}
          isInvalid={Response === false || errorState.Response}
          errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
          onVerify={handleRecaptchaVerify}
          onExpire={handleRecaptchaExpire}
          lang={language}
          ref={recaptchaRef}
        />
        <div className="field">
          <button type="submit" className="button is-primary is-fullwidth-mobile" onClick={handleSubmit}>
            {intl.formatMessage({ id: `${INTL_TAG}.submit` })}
          </button>
        </div>
        <div className="field is-size-7">{intl.formatMessage({ id: `${INTL_FORM_TAG}.mandatory` })}</div>

        {isModalOpen &&
          createDefaultPortal(
            <Modal onClick={handleModalClose}>
              <ModalContent
                className="contact-us-modal-img"
                header={intl.formatMessage({ id: `${INTL_TAG}.modal_header` })}
                image={contactImgPopup}
                onClick={handleModalClose}
              />
            </Modal>
          )}
      </fieldset>
    </form>
  )
}

export default Form
