import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { cover, ellipsis } from 'polished'

import { colors, mq, boldWhite, frame, sizes } from 'src/themes'

import { CardAppearanceControls } from './types'

export const ContentWrapper = styled.div<CardAppearanceControls>`
  ${cover()}
  display: flex;
  flex-flow: column nowrap;
  justify-content: safe center;
  ${({ isBigCard }) => ({
    padding: isBigCard ? '2.25rem 1.5rem' : '1.5rem 0.75rem'
  })}
`

const fs = [0.75, 1, 1, 1.15, 1.25]

export const Category = styled.h3<CardAppearanceControls>`
  text-align: right;
  ${boldWhite}
  ${({ isBigCard }) =>
    mq({
      fontSize: fs.map((s) => `${isBigCard ? s * 1.25 : s * 0.85}rem`)
    })}
`

export const TitleWrapper = styled.div`
  margin: auto 0;
`

export const Title = styled.h2<CardAppearanceControls>`
  ${boldWhite}
  ${ellipsis(null, 3)}
  ${({ isBigCard }) =>
    mq({
      fontSize: fs.map((s) => `${isBigCard ? s * 1.75 : s}rem`),
      textAlign: isBigCard ? 'center' : 'left'
    })}
  ${({ isFrameless }) =>
    !isFrameless &&
    css`
      font-size: ${sizes.size6};
    `}
`

export const TitleFrame = styled.span`
  ${({ isFrameless }) =>
    !isFrameless &&
    css`
      ${frame({ color: colors.white })};
      display: block;
      width: 90%;
      margin: 0 auto;
    `}
`

export const Border = styled.div<CardAppearanceControls>(({ isBigCard, color }) => ({
  ...cover(),
  height: isBigCard ? 15 : 10,
  background: color
}))

export const animatedLink = css`
  position: relative;
  overflow: hidden;
  display: block;
  background-color: black;

  .gatsby-image-wrapper {
    opacity: 0.75;
    display: block;
    transition: all 0.2s ease-out;
    transition-delay: 0.075s;
  }

  &:hover {
    background-color: ${colors.orange};

    .gatsby-image-wrapper {
      transform: scale(1.1);
      opacity: 0.15;
    }
  }
`
