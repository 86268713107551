import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { breakpoints, colors, sizes } from 'src/themes'

export const SearchForm = styled.form`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`

export const BaseInput = styled.input`
  font-size: 16px;
  font-family: inherit;
`

export const inputStyle = css`
  max-width: 500px;
  width: 100%;
  padding: 10px;
  border: 1px solid #efefef;

  &:disabled {
    background: #efefef;
    pointer-events: none;
  }

  @media (max-width: ${breakpoints.mobile}) {
    max-width: none;
    margin-bottom: 10px;
  }
`

export const buttonStyle = css`
  display: inline-block;
  background: ${colors.orange};
  color: ${colors.white};
  padding: 10px 15px;
  border: none;
  cursor: pointer;

  &:hover,
  &:disabled {
    background: darken(${colors.orange}, 5%);
  }

  &:disabled {
    pointer-events: none;
  }
`

export const errorStyle = css`
  margin-top: 50px;
  font-size: ${sizes.size4};
  color: ${colors.red};
`
