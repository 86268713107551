import { IntlShape } from 'gatsby-plugin-intl'
import { AnswerData, QuestionData } from '../types'

const answers3 = (intl: IntlShape, INTL_TAG: string): AnswerData[] => [
  {
    questionId: 3,
    id: 'a3',
    text: intl.formatMessage({ id: `${INTL_TAG}.about_hair.answers.not_colored` }),
    image: 'quiz-not-colored-img',
    incompatibleWith: 'b3'
  },
  {
    questionId: 3,
    id: 'b3',
    text: intl.formatMessage({ id: `${INTL_TAG}.about_hair.answers.colored` }),
    image: 'quiz-colored-img',
    incompatibleWith: 'a3'
  },
  {
    questionId: 3,
    id: 'c3',
    text: intl.formatMessage({ id: `${INTL_TAG}.about_hair.answers.damaged` }),
    image: 'quiz-tools-img'
  }
]

export default (intl: IntlShape, INTL_TAG: string): QuestionData => ({
  id: 3,
  text: intl.formatMessage({ id: `${INTL_TAG}.about_hair.question` }),
  submessage: intl.formatMessage({ id: `${INTL_TAG}.about_hair.submessage` }),
  answers: answers3(intl, INTL_TAG),
  numberOfChoices: 2
})
