import React from 'react'
import Cookies from 'js-cookie'
// import PropTypes from 'prop-types'
// import { Provider } from 'unstated'

import './src/styles/main.scss'

// export const wrapRootElement = ({ element }) => {
//   return <Provider>{element}</Provider>
// }

// wrapRootElement.propTypes = {
//   element: PropTypes.node
// }

// export const onClientEntry = () => {
//   window.digitalData = {}
// }

export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.href)
  window.previousPath = window.locations[window.locations.length - 1]
}

export const onClientEntry = async () => {
  if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React)
  }

  // TODO: remove after geo test
  // eslint-disable-next-line no-constant-condition
  if (false && !Cookies.get('userGeo') && !Cookies.get('userGeoFail')) {
    try {
      const request = await fetch('https://geoip.coty.com/location.json')
      const data = await request.json()
      Cookies.set('userGeo', data.country_code)
    } catch {
      Cookies.set('userGeoFail', true)
    }
  }
}
