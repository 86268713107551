import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { getQueryParamValue, setQueryParamValue } from 'src/utils/shared'
import { getSearchResults } from '../../utils'
import * as S from '../../styles/searchForm'

import Results from './Results'
import Loader from './Loader'

const SEARCH_HREF = '/api/index/default?filter[fulltext]='

const SearchForm = ({ formatMessage, locale }) => {
  const [searchValue, setSearchValue] = useState(getQueryParamValue('query') || '')
  const [searchResults, setSearchResults] = useState([])
  const [searchState, setSearchState] = useState({ isLoading: !!searchValue, hasError: false, lastSearch: '' })

  useEffect(() => {
    handleSearch()
  }, [handleSearch])

  const handleSearch = useCallback(async () => {
    if (searchValue && searchValue !== searchState.lastSearch) {
      setSearchState((state) => ({ ...state, hasError: false, isLoading: true }))

      try {
        const results = await getSearchResults({
          hreflang: locale,
          href: SEARCH_HREF,
          query: searchValue
        })

        if (results) {
          setSearchResults(results.data)
        }

        setSearchState({ lastSearch: searchValue, hasError: false, isLoading: false })
      } catch (e) {
        setSearchState({ hasError: true, isLoading: false, lastSearch: '' })
      }
    }
  }, [searchValue, searchState, locale])

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      setQueryParamValue({ value: searchValue, name: 'query' })
      handleSearch()
    },
    [searchValue, handleSearch]
  )

  return (
    <>
      <S.SearchForm onSubmit={handleSubmit}>
        <S.BaseInput
          css={S.inputStyle}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          disabled={searchState.isLoading}
        />

        <S.BaseInput
          css={S.buttonStyle}
          type="submit"
          value={formatMessage({ id: 'search.button' })}
          disabled={searchState.isLoading}
          placeholder={formatMessage({ id: 'search.input_placeholder' })}
        />
      </S.SearchForm>

      {searchState.isLoading && <Loader />}

      {searchState.lastSearch && (
        <Results
          searchResults={searchResults}
          formatMessage={formatMessage}
          lastSearch={searchState.lastSearch}
          error={searchState.hasError}
        />
      )}

      {searchState.hasError && <p css={S.errorStyle}>{formatMessage({ id: 'search.error' })}</p>}
    </>
  )
}

SearchForm.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired
}

export default SearchForm
