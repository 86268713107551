import React, { useState, FC, useMemo } from 'react'

import FinalScreen from './components/FinalScreen'
import ProgressBar from './components/ProgressBar'
import Question from './components/Question'
import StartScreen from './components/StartScreen'

import { questionData, productData } from './data'

import { QuizProps, ActionWithAnswers, UpdateStateAction, StateProps, Action, Product } from './types'

import { isBrowser } from 'src/utils/shared'
import {
  getStoredAnswers,
  getStates,
  handleNextQuestionTracking,
  handleQuizStartTracking,
  handlePreviousQuestionTracking,
  handleQuizCompleteTracking,
  getProductResultsBasedOnAnswers,
  handleRetakeQuizTracking
} from './utils'

const windowGlobal = isBrowser && window

const Quiz: FC<QuizProps> = ({ data, intl, setFinalScreen, isIframe, iframeController }) => {
  const dataId = 'quizAnswers'
  const localizedQuestionData = questionData(intl)
  const {
    allNodeProduct: { nodes: products },
    startScreenImgMobile: {
      childImageSharp: { gatsbyImageData: startScreenMobile }
    },
    startScreenImgDesktop: {
      childImageSharp: { gatsbyImageData: startScreenDesktop }
    },
    newsletterMobile: { childImageSharp: { gatsbyImageData: newsletterMobile } = {} } = {},
    newsletterDesktop: { childImageSharp: { gatsbyImageData: newsletterDesktop } = {} } = {},
    quizImages: { nodes: quizImages },
    blogList: { nodes: blogList } = {}
  } = data
  const {locale} = intl

  const states = useMemo(() => getStates(localizedQuestionData.length), [localizedQuestionData.length])
  const initCurrentState = (answers: (string | string[])[]) =>
    answers.length >= localizedQuestionData.length ? states.isEnd : states.isStart

  const [answers, setAnswers] = useState<(string | string[])[]>(
    !isIframe ? getStoredAnswers({ windowGlobal, dataId }) : []
  )
  const [currentState, setCurrentState] = useState(initCurrentState(answers))
  const [results, setResults] = useState<Product[]>(
    getProductResultsBasedOnAnswers({ productData, answers, products, locale }))

  const transitions = {
    [states.isStart.action]: {
      START_QUIZ: states.switchQuestion,
      SHOW_RESULT: states.isEnd
    },
    [states.isEnd.action]: {
      RETAKE_QUIZ: states.isStart
    },
    [states.switchQuestion.action]: {
      NEXT_QUESTION: {
        ...currentState,
        question: currentState.question + 1
      },
      PREVIOUS_QUESTION: {
        ...currentState,
        question: currentState.question - 1
      },
      SHOW_RESULT: states.isEnd
    }
  }

  const transition = (currentState: StateProps, action: Action) =>
    transitions[currentState.action][action] || currentState

  const updateLocalStorage = (data?: (string | string[])[]) => {
    try {
      if (windowGlobal) {
        windowGlobal.localStorage.setItem(dataId, JSON.stringify(data))
      }

      !isIframe && setFinalScreen(true)
    } catch (err) {
      updateState({ action: 'RETAKE_QUIZ' })
    }
  }

  const updateState = ({ action, answer }: ActionWithAnswers | UpdateStateAction) => {
    const addAnswer = (answer?: string | string[]) => {
      if (!answer) return

      const arr = [...answers]

      arr[currentState.question] = answer

      return arr
    }

    setCurrentState((currentState) => transition(currentState, action))

    switch (action) {
      case 'RETAKE_QUIZ':
        if (windowGlobal && !isIframe) {
          windowGlobal.localStorage.removeItem(dataId)
        }

        handleRetakeQuizTracking()
        setAnswers([])
        iframeController && iframeController(-1)
        break
      case 'PREVIOUS_QUESTION': {
        handlePreviousQuestionTracking({ localizedQuestionData, question: currentState.question })
        iframeController && iframeController(currentState.question - 1)
        break
      }
      case 'NEXT_QUESTION': {
        handleNextQuestionTracking({ answer, localizedQuestionData, question: currentState.question })
        setAnswers(addAnswer(answer))
        iframeController && iframeController(currentState.question + 1)
        break
      }
      case 'SHOW_RESULT': {
        const currentAnswers = addAnswer(answer)
        const results = getProductResultsBasedOnAnswers({ productData, answers: currentAnswers, products, locale })

        handleNextQuestionTracking({ answer, localizedQuestionData, question: currentState.question })
        !isIframe && updateLocalStorage(currentAnswers)
        setAnswers(currentAnswers)
        setResults(results)
        handleQuizCompleteTracking({
          recommendationText: intl.formatMessage({ id: `quiz.final_screen.main_header` }),
          products: results
        })
        iframeController && iframeController(currentAnswers.length)
        break
      }

      default:
        return
    }
  }

  const compareState = (state: StateProps) => currentState.action === state.action

  if (compareState(states.isStart)) {
    return (
      <StartScreen
        intl={intl}
        startQuiz={() => {
          handleQuizStartTracking()
          updateState({ action: 'START_QUIZ' })
          iframeController && iframeController(0)
        }}
        mobileImg={startScreenMobile}
        desktopImg={startScreenDesktop}
      />
    )
  }

  return (
    <>
      <ProgressBar
        isIframe={isIframe}
        intl={intl}
        cap={localizedQuestionData.length}
        currentCheckpoint={answers.length}
      />

      {compareState(states.isEnd) && results && (
        <div className={`container ${isIframe ? 'has-text-centered' : ''}`}>
          <FinalScreen
            resetQuiz={() => updateState({ action: 'RETAKE_QUIZ' })}
            blogList={blogList}
            intl={intl}
            newsletterImage={[newsletterMobile, newsletterDesktop]}
            resultsToRender={results}
            disableLinking={isIframe}
          />
        </div>
      )}

      {compareState(states.switchQuestion) && (
        <div className={`container ${isIframe ? 'has-text-centered' : ''}`}>
          <Question
            key={localizedQuestionData[currentState.question].id}
            {...localizedQuestionData[currentState.question]}
            quizImages={quizImages}
            selectedAnswers={answers}
            updateState={updateState}
            questionNumber={currentState.question}
            isQuestionLast={currentState.question >= localizedQuestionData.length - 1}
            intl={intl}
          />
        </div>
      )}
    </>
  )
}

export default Quiz
