import React, { memo, useState, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Link } from 'gatsby-plugin-intl'
import classnames from 'classnames'

import { redirectToSearch } from './utils'

import { StyledIcon } from 'src/themes'

const Search = ({ locale, searchPath, formatMessage }) => {
  const [isActive, setIsActive] = useState(false)
  const [value, setValue] = useState('')
  const {
    file: { publicURL: iconsPath }
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "sign-icons.svg" }) {
          publicURL
        }
      }
    `
  )

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()

      if (value) {
        redirectToSearch({ locale, searchPath, value })
      }
    },
    [value, locale, searchPath]
  )

  return (
    <div
      className={classnames('layout-search', {
        active: isActive
      })}
    >
      <form onSubmit={handleSubmit} className="layout-search__form">
        <div className="layout-search__form-content">
          <button>
            <StyledIcon css={{ width: '1em' }}>
              <use xlinkHref={`${iconsPath}#search`} />
            </StyledIcon>
          </button>
          <input
            onChange={(e) => setValue(e.target.value)}
            value={value}
            placeholder={formatMessage({ id: 'search.input_placeholder' })}
          />
        </div>
      </form>

      <button className="layout-search__button show-desktop" onClick={() => setIsActive((isActive) => !isActive)}>
        <StyledIcon css={{ width: '1em' }}>
          <use xlinkHref={`${iconsPath}#${isActive ? 'times-circle' : 'search'}`} />
        </StyledIcon>
      </button>

      <Link to={searchPath} className="layout-search__button show-mobile">
        <StyledIcon css={{ width: '1em' }}>
          <use xlinkHref={`${iconsPath}#search`} />
        </StyledIcon>
      </Link>
    </div>
  )
}

Search.propTypes = {
  locale: PropTypes.string.isRequired,
  searchPath: PropTypes.string.isRequired,
  formatMessage: PropTypes.func
}

export default memo(Search)
