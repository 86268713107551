import styled from '@emotion/styled'

import { breakpoints, colors, media, sizes } from 'src/themes'

const startButtonColor = '#ff9f68'

export const StartButton = styled.button`
  border-color: ${startButtonColor};
  color: ${startButtonColor};
  display: block;
  height: initial;
  margin-top: ${sizes.size4};
  white-space: initial;
  width: 100%;

  &:hover {
    background-color: ${startButtonColor};
    color: ${colors.white};
  }

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`

export const Header = styled.h1`
  font-weight: bold;
  line-height: 1.2;

  ${media({
    fontSize: ['6vw', undefined, '2rem', '1.8rem'],
    marginBottom: [sizes.size8, undefined, sizes.size4, sizes.size6]
  })};
`

export const Description = styled.p`
  ${media({
    fontSize: ['4vw', undefined, '1rem']
  })};
`

export const ContentContainer = styled.div`
  text-align: left;
  padding: 1rem 2rem;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 2rem;
    text-align: center;
  }
`

export const MainWrapper = styled.section`
  max-width: 800px !important;
`
