import React, { createRef, memo, RefObject, FC } from 'react'
import ReactPlayer from 'react-player/youtube'
import { css } from '@emotion/react'
import { WhyDidYouRenderOptions } from '@welldone-software/why-did-you-render'

import { getPercentage } from 'src/utils/shared/'
import videoPlayEvent from 'src/dataLayer/Video'

import { VideoProps, MilestoreTracker } from './types'

import { videoStyles, videoHidden } from './styled'

const MILESTONE_POINTS = [25, 50, 75, 100]

const Video: FC<VideoProps> & WhyDidYouRenderOptions = ({ isVideoHidden, disableTracking, ...props }) => {
  const vidRef: RefObject<ReactPlayer> = createRef()
  const milestoneTracker: MilestoreTracker = {}
  let currentMilestone = 0

  const videoCSS = css`
    ${videoStyles};
    ${isVideoHidden ? videoHidden : ''};
  `

  const createData = () => {
    const current = vidRef.current

    if (current) {
      const duration = current.getDuration()
      const secPlayed = current.getCurrentTime()
      const internalPlayer = current.getInternalPlayer()
      const options = {
        name: internalPlayer.getVideoData().title,
        length: `${Math.floor(duration)}s`,
        url: props.url,
        played: `${Math.floor(secPlayed)}s`,
        milestone: currentMilestone
      }

      return { options, secPlayed, duration }
    }

    return {}
  }

  const handleProgress = () => {
    const { options, secPlayed, duration } = createData()

    if (options) {
      const milestone = getPercentage(secPlayed, duration)

      MILESTONE_POINTS.forEach((point) => {
        if (milestone >= point && !milestoneTracker[point]) {
          currentMilestone = point
          options.milestone = point
          milestoneTracker[point] = true

          videoPlayEvent({ ...options, evtName: `Media${point !== 100 ? point : 'Complete'}` })
        }
      })
    }
  }

  const handlePlay = () => {
    const { options } = createData()
    if (options) videoPlayEvent({ ...options, evtName: 'MediaPlay' })
  }

  return (
    <ReactPlayer
      ref={vidRef}
      {...props}
      {...(disableTracking ? {} : { onProgress: handleProgress, onPlay: handlePlay })}
      css={videoCSS}
    />
  )
}

Video.whyDidYouRender = {
  logOnDifferentValues: true
}

export default memo(Video)
