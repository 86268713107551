import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import Hero from 'src/components/Hero'
import { getArtDirection } from 'src/utils/shared'

const INTL_TAG = 'product_page.natural_hair'

const HairCare = ({ intl, img, }) => {
  return (
    <Hero hero="hero is-third mt-6">
      <div className="columns is-centered is-third is-block-tablet is-flex-desktop">
          <div className="is-flex is-justify-content-center is-align-items-center is-relative has-text-centered">
            <div className="column">
              <div className="is-center">
                <GatsbyImage image={getArtDirection(img.image)} alt={img.alt} />
              </div>
            </div>
          </div>
          <div className="column is-half-desktop">
            <h2 className="title has-text-left-mobile has-frame"><span className="">{intl.formatMessage({ id: `${INTL_TAG}.title` })}</span></h2>
            <div>
            {intl.locale === 'fr-fr' || intl.locale === 'fr-be' ?
              [1,2,3,4,5].map(number => {return <p key={number} className="has-padding-bottom-1 is-size-7">{intl.formatMessage({ id: `${INTL_TAG}.desc${number}` })}</p>}) :
              [1,2,3,4].map(number => {return <p key={number} className="has-padding-bottom-1 is-size-6">{intl.formatMessage({ id: `${INTL_TAG}.desc${number}` })}</p>})
            }
            </div> 
          </div>
        </div>
    </Hero>
  )
}

export default HairCare

HairCare.propTypes = {
  img: PropTypes.shape({
    image: PropTypes.arrayOf(PropTypes.object).isRequired,
    alt: PropTypes.string
  }),
  intl: PropTypes.object
}
