import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { mq, breakpoints } from 'src/themes'

export const Description = styled.div`
  ${mq({
    display: ['flex', 'block'],
    flexDirection: ['column-reverse', 'none']
  })}

  ul {
    list-style: initial;
    padding-left: 20px;
  }
`

export const ButtonWrapper = styled.div`
  ${mq({
    margin: ['0', '-10px']
  })}
`

export const buttonStyle = css`
  ${mq({
    margin: ['10px 0', '10px']
  })}
`

export const videoModalStyles = css`
  max-width: ${breakpoints.desktop};
  max-height: none;
  width: 100%;
  overflow: initial;
  ${mq({
    padding: [0, '5%', undefined, 0]
  })}

  @media (orientation: landscape) and (max-width: ${breakpoints.tablet}) {
    padding: 10%;
  }
`
