import { useState, useCallback } from 'react'

import type { FormStateHookProps, UseFormState, IFormState, CheckboxState } from './useFormState.types'

// hook made for handling (gsdb) form inputs.
// errorState is a local useState hook.
// Wer're not sending values from checkboxes like input ones (agreements - api requirements), so they're separated.
// setFormState & setCheckboxState are also returned
// because of custom fields (recaptcha etc) or functions (custom clear etc).

const useFormState = ({ initialState = {}, errorState, setErrorState }: FormStateHookProps): UseFormState => {
  const [state, setFormState] = useState<IFormState>(initialState)
  const [checkboxState, setCheckboxState] = useState<CheckboxState>({})

  const handleInputChange = useCallback(
    (e) => {
      const {
        target: { name, value }
      } = e

      setFormState((prevState) => ({ ...prevState, [name]: value }))
      if (errorState[name]) {
        setErrorState((prevErrState) => ({ ...prevErrState, [name]: false }))
      }
    },
    [errorState, setErrorState]
  )
  const handleCheckboxChange = useCallback(
    (e) => {
      const {
        target: { name }
      } = e

      setCheckboxState((prevState) => ({ ...prevState, [name]: !prevState[name] }))
      if (errorState[name]) {
        setErrorState((prevErrState) => ({ ...prevErrState, [name]: false }))
      }
    },
    [errorState, setErrorState]
  )

  // recaptcha handlers
  const handleRecaptchaVerify = (response: boolean) => {
    setFormState((prevState) => ({ ...prevState, Response: response }))
    setErrorState((prevErrState) => ({ ...prevErrState, Response: false }))
  }

  const handleRecaptchaExpire = () => {
    setFormState((prevState) => ({ ...prevState, Response: false }))
    setErrorState((prevErrState) => ({ ...prevErrState, Response: true }))
  }

  const clearForm = () => {
    setFormState(initialState)
    setCheckboxState({})
    setErrorState({})
  }

  return {
    state,
    checkboxState,
    handleInputChange,
    handleCheckboxChange,
    setFormState,
    setCheckboxState,
    handleRecaptchaVerify,
    handleRecaptchaExpire,
    clearForm
  }
}

export default useFormState
