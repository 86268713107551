import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'
import { Link } from 'gatsby-plugin-intl'
import useDeviceDetect from 'src/hooks/useDeviceDetect'

import * as S from '../../styles/banner'

const BannerImg = ({ link, text, imageData, intl, alt, altMobile }) => {
  const { desktop, mobile } = imageData

  const images = withArtDirection(desktop.image, [
    {
      media: '(orientation: portrait)',
      image: mobile.image
    }
  ])
  const { isMobile } = useDeviceDetect()
  return (
    <S.HeroBody className="hero-body is-relative" style={{ height: '100%' }}>
      {!!imageData && <GatsbyImage css={S.gatsbyImage} image={images} alt={isMobile ? altMobile : alt} />}
      <S.ImgContent>
        <S.ImgText isGreek={intl.locale === 'el-gr'}>
          {!!text && (
            <div
              css={S.hasFrame}
              className="column is-uppercase has-text-white has-padding-bottom-0 has-text-left-desktop is-full-mobile is-full is-size-2-desktop has-margin-top-3__nonmobile has-padding-bottom-3-desktop has-padding-horizontal-1 is-size-3-mobile"
              dangerouslySetInnerHTML={{ __html: text.value }}
            />
          )}
          {!!link && (
            <div
              //eslint-disable-next-line max-len
              className={`column is-full-mobile is-full-tablet has-margin-vertical-3_mobile has-padding-horizontal-1 has-margin-bottom-5 ${
                !text ? 'no-text' : ''
              }`}
            >
              {link.uri_alias ? (
                <Link
                  className="button button is-outlined is-uppercase is-white-alpha is-fullwidth"
                  to={link.uri_alias}
                >
                  {link.title}
                </Link>
              ) : (
                <a className="button button is-outlined is-uppercase is-white-alpha is-fullwidth" href={link.uri}>
                  {link.title}
                </a>
              )}
            </div>
          )}
          {/* <span className="button is-borderless is-size-5" onClick={handleToggleModal}>
          <span className="icon is-size-1">
            <i className="far fa-play-circle" />
          </span>
          <span>{intl.formatMessage({ id: `${intlTag}.play_video` })}</span>
        </span> */}
        </S.ImgText>
      </S.ImgContent>
    </S.HeroBody>
  )
}

export default BannerImg

BannerImg.propTypes = {
  imageData: PropTypes.object,
  link: PropTypes.shape({
    title: PropTypes.string,
    uri_alias: PropTypes.string,
    uri: PropTypes.string
  }).isRequired,
  text: PropTypes.shape({
    value: PropTypes.string
  }),
  intl: PropTypes.object,
  alt: PropTypes.string,
  altMobile: PropTypes.string
}
