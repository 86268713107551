import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { breakpoints, colors, sizes } from 'src/themes'

export const Summary = styled.p`
  font-size: ${sizes.size2};
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 35px;
    font-size: ${sizes.size3};
  }
`

export const NoResult = styled.p`
  font-size: ${sizes.size3};

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${sizes.size4};
  }
`
export const ResultItem = styled.div`
  margin-bottom: 50px;
`

export const ResultHeader = styled.h3`
  font-size: ${sizes.size3};
  margin-bottom: 15px;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${sizes.size4};
  }
`

export const resultButton = css`
  display: inline-block;
  margin-top: 20px;
  color: ${colors.orange};
  padding: 10px 15px;
  border: 1px solid ${colors.orange};

  &:hover {
    background: ${colors.orange};
    color: ${colors.white};
  }
`
