import React, { useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import Cookies from 'js-cookie'

import { findyByLangcode, redirectToLang } from 'src/utils/layout'

import Header from './components/Header'
import ScrollToTop from './components/ScrollToTop'
import Footer from './components/Footer'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import layoutIcons from 'src/assets/images/layout-icons.svg'

import { isBrowser } from 'src/utils/shared'

const getLangToRedirect = (lang) => {
  switch (lang) {
    case 'DE':
      return 'de-de'
    case 'GR':
      return 'el-gr'
    default:
      return 'en'
  }
}

const redirectByGeoLocation = async ({ pageUrls }) => {
  Cookies.set('isRedirected', true)

  const userGeo = Cookies.get('userGeo')

  if (userGeo) {
    redirectToLang({ pageUrls, langCode: getLangToRedirect(userGeo) })
  }
}

const Layout = ({
  children,
  isNavAlpha,
  breadcrumbs,
  containerClass,
  pageUrls,
  disableFooterIcons,
  hideBreadcrubsOnMobile
}) => (
  <StaticQuery
    render={(data) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { locale } = useIntl()

      // TODO: remove after geo test
      // eslint-disable-next-line no-constant-condition
      if (false && isBrowser && !Cookies.get('isRedirected')) {
        redirectByGeoLocation({ locale, pageUrls })
      }

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        if (!isNavAlpha && !!document) {
          document.documentElement.classList.add('has-navbar-fixed-top')
        }

        return () => {
          if (!isNavAlpha && !!document) {
            document.documentElement.classList.remove('has-navbar-fixed-top')
          }
        }
      }, [])

      const {
        allMenuLinkContentMenuLinkContent: { nodes: navItems },
        SocialMediaContent: { nodes: socialMediaData },
        exploreData: { nodes: exploreData },
        legalData: { nodes: legalData },
        salonFinderData,
        searchData
      } = data

      const localedNavItem = [...new Set(findyByLangcode({ array: navItems, locale }))]
      const localedsocialMediaData = findyByLangcode({ array: socialMediaData, locale })
      const localedExploreData = findyByLangcode({ array: exploreData, locale })
      const localedLegalData = findyByLangcode({ array: legalData, locale })

      const sfPath = findyByLangcode({ array: salonFinderData?.field_hreflang, locale })
      const searchPath = findyByLangcode({ array: searchData?.field_hreflang, locale })

      return (
        <>
          <div className="content-wrapper ">
            {isBrowser && (
              <Header
                navItems={localedNavItem}
                socialMediaData={localedsocialMediaData}
                isNavAlpha={isNavAlpha}
                pageUrls={pageUrls}
                sfPath={sfPath && sfPath[0]?.href}
                searchPath={searchPath[0]?.href}
                iconLogo={`${layoutIcons}#icon-logo-wedo`}
              />
            )}
            <div className={`content-container ${containerClass || ''}`}>
              {!!breadcrumbs && (
                <span className={hideBreadcrubsOnMobile ? 'is-hidden-mobile' : ''}>
                  <Breadcrumb data-test="breadcrumbs" crumbs={breadcrumbs} />
                </span>
              )}
              {children}
            </div>
            <ScrollToTop />
            <Footer
              socialMediaData={localedsocialMediaData}
              exploreData={localedExploreData}
              legalData={localedLegalData}
              disableFooterIcons={disableFooterIcons}
              layoutIcons={layoutIcons}
            />
          </div>
        </>
      )
    }}
    query={graphql`
      query LayoutQuery {
        # Header
        # TODO: filter content_translation_status: { eq: true } był tu, upewnić się od czego on jest
        allMenuLinkContentMenuLinkContent(
          filter: { menu_name: { eq: "main" }, enabled: { eq: true } }
          sort: { fields: weight, order: ASC }
        ) {
          nodes {
            title
            id
            langcode
            drupal_id
            content_translation_status
            link {
              uri
              uri_alias
              options {
                attributes {
                  target
                }
              }
              parent
            }
          }
        }
        # Social Media
        SocialMediaContent: allMenuLinkContentMenuLinkContent(
          filter: { menu_name: { eq: "follow-us" }, enabled: { eq: true } }
          sort: { fields: weight, order: ASC }
        ) {
          nodes {
            title
            langcode
            link_override {
              uri
              title
            }
            link {
              uri
              uri_alias
              options {
                fa_icon
                attributes {
                  target
                }
              }
            }
            id
            weight
          }
        }
        # footer EXPLORE
        exploreData: allMenuLinkContentMenuLinkContent(
          filter: { menu_name: { eq: "explore" }, enabled: { eq: true } }
          sort: { fields: weight, order: ASC }
        ) {
          nodes {
            title
            langcode
            link_override {
              uri
              title
            }
            link {
              uri
              uri_alias
              options {
                attributes {
                  target
                }
              }
              parent
            }
            id
            weight
          }
        }
        # footer LEGAL
        legalData: allMenuLinkContentMenuLinkContent(
          filter: { menu_name: { eq: "legal" }, enabled: { eq: true } }
          sort: { fields: weight, order: ASC }
        ) {
          nodes {
            title
            langcode
            link_override {
              uri
              title
            }
            link {
              uri
              uri_alias
              options {
                attributes {
                  target
                }
              }
              parent
            }
            id
            weight
          }
        }
        salonFinderData: nodePage(field_component: { eq: "find-salon" }) {
          field_hreflang {
            href
            langcode: hreflang
          }
        }
        searchData: nodePage(field_component: { eq: "search" }) {
          field_hreflang {
            href
            langcode: hreflang
          }
        }
      }
    `}
  />
)

export default memo(Layout)

Layout.propTypes = {
  children: PropTypes.node,
  isNavAlpha: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      crumbLabel: PropTypes.string,
      pathname: PropTypes.string
    })
  ),
  containerClass: PropTypes.string,
  pageUrls: PropTypes.arrayOf(
    PropTypes.shape({
      hreflang: PropTypes.string,
      href: PropTypes.string
    })
  ).isRequired,
  disableFooterIcons: PropTypes.bool,
  hideBreadcrubsOnMobile: PropTypes.bool
}
