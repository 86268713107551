import React, { useRef, useCallback, memo, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import _debounce from 'lodash.debounce'
import { isMobile } from 'react-device-detect'

import { quizMarkets } from '../../consts/consts'

import SEO from 'src/components/SEO'
import Parallax from 'src/components/Parallax'
import Layout from 'src/layouts'

import useTracking from 'src/hooks/useTracking'
import { createPageData } from 'src/utils/tracking'
import { getMetaTitle, findLocalePageUrlData, getPageUrl } from 'src/utils/shared'
import { setCSSViewportHeight, findLink } from './utils'
import { buildHomeSchema } from './schema'

import Banner from './sections/Banner'
import WhatWeDo from './sections/WhatWeDo'
import WeDo from './sections/WeDo'
import MadeFrom from './sections/MadeFrom'
import Quiz from './sections/Quiz'
import NaturalHairCare from './sections/NaturalHairCare'
import { enableNaturalHairCare } from '../../consts/consts'

const HomePage = ({ pageContext, data }) => {
  const intl = useIntl()
  const parallaxRef = useRef(null)

  useEffect(() => {
    const debouncedViewportHeight = _debounce(setCSSViewportHeight, 500)

    setCSSViewportHeight()

    isMobile && window?.addEventListener('orientationchange', debouncedViewportHeight)

    return () => {
      isMobile && window.removeEventListener('orientationchange', debouncedViewportHeight)
    }
  }, [])

  const handleScrollClick = useCallback(() => {
    parallaxRef.current.content.scrollIntoView({
      behavior: 'smooth'
    })
  }, [parallaxRef])

  const {
    allNodeHomepage: { nodes: allNodeHomepage },
    allNodeProduct: { nodes: whatWeDoCarousel },
    mobileBambooImg,
    tabletBambooImg,
    desktopBambooImg,
    mobileProductsImg,
    tabletProductsImg,
    desktopProductsImg,
    mobileWwcImg,
    desktopWwcImg,
    mobileHandsImg,
    desktopHandsImg,
    mobileQuizImg,
    desktopQuizImg,
    wedoReadMoreBlockLeftImg,
    wedoReadMoreBlockRightImg,
    weDoVideoThumbnail,
    mobileNhcImg,
    desktopNhcImg,
    allNodeIngredient: { nodes: madeFromCarousel },
    links: { edges: linksList }
  } = data

  const nodeHomepage = allNodeHomepage[0]

  if (!nodeHomepage) {
    navigate('/404')
  }

  const {
    field_hreflang: pageUrls,
    field_metatag_normalized: metaTags,
    relationships: bannerRelationships
  } = nodeHomepage

  useTracking({
    data: createPageData({
      pageId: 'home',
      breadCrumbs: 'home',
      pageType: 'homepage',
      instanceName: 'homepage',
      primaryCategory: 'homepage',
      language: intl.locale,
      pageTitle: getMetaTitle(metaTags),
      pageName: 'homepage'
    })
  })

  const bambooImg = {
    image: [
      mobileBambooImg.childImageSharp.gatsbyImageData,
      tabletBambooImg.childImageSharp.gatsbyImageData,
      desktopBambooImg.childImageSharp.gatsbyImageData
    ],
    alt: intl.formatMessage({ id: 'images.homepage.bamboo' })
  }

  const nhcImg = {
    image: [mobileNhcImg.childImageSharp.gatsbyImageData, desktopNhcImg.childImageSharp.gatsbyImageData],
    alt: intl.formatMessage({ id: 'images.homepage.nhc' })
  }

  const productsImg = {
    image: [
      mobileProductsImg.childImageSharp.gatsbyImageData,
      tabletProductsImg.childImageSharp.gatsbyImageData,
      desktopProductsImg.childImageSharp.gatsbyImageData
    ],
    alt: intl.formatMessage({ id: 'images.homepage.products' })
  }

  const wwcImg = {
    image: [mobileWwcImg.childImageSharp.gatsbyImageData, desktopWwcImg.childImageSharp.gatsbyImageData],
    alt: intl.formatMessage({ id: 'images.homepage.wwc' })
  }

  const handsImg = {
    image: [mobileHandsImg.childImageSharp.gatsbyImageData, desktopHandsImg.childImageSharp.gatsbyImageData],
    alt: intl.formatMessage({ id: 'images.homepage.hands' })
  }

  const quizImg = {
    image: [mobileQuizImg?.childImageSharp?.gatsbyImageData, desktopQuizImg?.childImageSharp?.gatsbyImageData],
    alt: intl.formatMessage({ id: 'images.homepage.quiz' })
  }

  const wedoReadMoreBlockImgSrc = {
    left: {
      image: wedoReadMoreBlockLeftImg.childImageSharp.gatsbyImageData,
      alt: intl.formatMessage({ id: 'images.homepage.readMoreLeft' })
    },
    right: {
      image: wedoReadMoreBlockRightImg.childImageSharp.gatsbyImageData,
      alt: intl.formatMessage({ id: 'images.homepage.readMoreRight' })
    },
    thumbnail: weDoVideoThumbnail.childImageSharp.gatsbyImageData.images.fallback.src
  }

  const whatWeCreateBtnLink = findLink({
    linksList,
    uri: 'entity:node/2',
    hasParent: false,
    lang: intl.locale
  })

  const madeFromBtnLink = findLink({
    linksList,
    uri: 'entity:node/12',
    hasParent: true,
    lang: intl.locale
  })

  const partnershipLink = findLink({
    linksList,
    uri: 'entity:node/14',
    hasParent: true,
    lang: intl.locale
  })

  return (
    <Layout pageUrls={pageUrls} isNavAlpha>
      <SEO
        schema={buildHomeSchema(getPageUrl(findLocalePageUrlData(intl.locale, pageUrls)), intl.locale)}
        pageUrls={pageUrls}
        fetchedMetaTags={metaTags}
        lang={pageContext.intl.language}
      />
      <Banner intl={intl} handleScrollClick={handleScrollClick} bannerData={bannerRelationships?.field_sections} />
      <Fragment>
        <Parallax {...bambooImg} ref={parallaxRef} />
        <WhatWeDo
          intl={intl}
          img={wwcImg}
          imgBg={handsImg}
          data={whatWeDoCarousel}
          btnLink={whatWeCreateBtnLink?.node.link.uri_alias}
        />
        <Parallax translate={-250} {...productsImg} />
        {quizMarkets.includes(intl.locale) && <Quiz intl={intl} img={quizImg} />}
        <MadeFrom intl={intl} data={madeFromCarousel} btnLink={madeFromBtnLink?.node.link.uri_alias} />
        {enableNaturalHairCare.includes(intl.locale) && <NaturalHairCare intl={intl} img={nhcImg} />}
      </Fragment>
      <WeDo
        intl={intl}
        partnershipLink={partnershipLink?.node.link.uri_alias}
        imgReadMoreBlock={wedoReadMoreBlockImgSrc}
      />
    </Layout>
  )
}

export default memo(HomePage)

HomePage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}
