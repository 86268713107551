import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'

import useTracking from 'src/hooks/useTracking'
import { createPageData } from 'src/utils/tracking'
import { getMetaTitle } from 'src/utils/shared'

const BasicPage = ({ pageContext, data }) => {
  const { locale } = useIntl()

  const {
    nodePage: { title, body, field_breadcrumb: breadcrumbs, field_metatag_normalized: metaTags }
  } = data

  useTracking({
    data: createPageData({
      pageId: 'basic',
      breadCrumbs: 'basic',
      pageType: 'basic page',
      instanceName: 'basic page',
      primaryCategory: 'basic',
      language: locale,
      pageTitle: getMetaTitle(metaTags),
      pageName: pageContext.slug
    })
  })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
        <div className="columns has-margin-bottom-2 is-multiline">
          <h1 className="column is-auto-mobile is-5-desktop is-3-widescreen title__page has-frame has-margin-horizontal-1 has-margin-bottom-2__nonmobile has-margin-bottom-1__mobile">
            {title}
          </h1>

          {body?.value && (
              <div className="column is-full" dangerouslySetInnerHTML={{ __html: body.value }} />
          )}
        </div>
      </Hero>
    </Layout>
  )
}

BasicPage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    nodePage: PropTypes.shape({
      title: PropTypes.shape({ value: PropTypes.string }),
      body: PropTypes.shape({ value: PropTypes.string }),
      field_breadcrumb: PropTypes.arrayOf(PropTypes.object),
      field_metatag_normalized: PropTypes.arrayOf(PropTypes.object)
    })
  })
}

export const query = graphql`
  query BasicPage($slug: String!, $langKey: String) {
    nodePage(field_component: { eq: "basic" }, path: { alias: { eq: $slug } }, langcode: { eq: $langKey }) {
      ...PageDataFragment
    }
  }
`

export default BasicPage
