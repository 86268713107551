import React, { useCallback, FC } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { SwiperSlide } from 'swiper/react'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'
import SwiperCarousel from 'src/components/SwiperCarousel'
import ProductCard from 'src/components/ProductCard'
import SalonFinder from 'src/components/SalonFinder'
import BuyNowModal from 'src/templates/ProductPage/sections/components/BuyNowModal'

import useTracking from 'src/hooks/useTracking'
import useToggleModal from 'src/hooks/useToggleModal'
import useClientRerender from 'src/hooks/useClientRerender'

import { createPageData, createContentPageDigitalData } from 'src/utils/tracking'
import { getMetaTitle, toLowerKebabCase, formImageBaseData } from 'src/utils/shared'
import { getRetailerData } from './utils'

import { highlightedSalonFinder } from 'src/consts'
import { disableHappyColoredSections } from 'src/consts'
import { disableHappyNaturalHair } from 'src/consts'

import * as S from './styles/styled'

import type { ModalButtonInterface, HappyPageInterface } from './types'

const renderButton: FC<ModalButtonInterface> = ({ shopsList = [], intl, toggleModal, onClick }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleBtnClick = useCallback(() => {
    onClick(shopsList[0].field_wtb_link?.uri)
  }, [shopsList, onClick])

  if (!shopsList?.length) {
    return
  }

  if (shopsList?.length === 1) {
    return (
      <a
        onClick={handleBtnClick}
        href={shopsList[0]?.field_wtb_link?.uri}
        target="_blank"
        rel="noopener noreferrer"
        className="button is-size-6 is-uppercase is-fullwidth-mobile is-outlined is-white"
      >
        {intl.formatMessage({ id: 'product_page.banner.buy_now' })}
      </a>
    )
  }

  return (
    <button className="button is-size-6 is-outlined is-white is-uppercase is-fullwidth-mobile" onClick={toggleModal}>
      {intl.formatMessage({ id: 'product_page.banner.buy_now' })}
    </button>
  )
}

const Happy: FC<HappyPageInterface> = ({ pageContext, data }) => {
  const intl = useIntl()
  const isClient = useClientRerender()
  const { isModalOpen, toggleModal } = useToggleModal()

  const {
    nodePage: {
      title,
      field_breadcrumb: breadcrumbs,
      relationships: { field_sections: paragraphs },
      field_metatag_normalized: metaTags
    },
    allNodeProduct: { nodes: carouselData },
    nodeRegis: {
      relationships: {
        field_logotype: {
          localFile: { url: logoRegis }
        }
      }
    },
    nodeLookFantastic: {
      relationships: {
        field_logotype: {
          localFile: { url: logoLookFantastic }
        }
      }
    },
    nodeSupercuts: {
      relationships: {
        field_logotype: {
          localFile: { url: logoSupercuts }
        }
      }
    },
    nodeToniAndGuy: {
      relationships: {
        field_logotype: {
          localFile: { url: logoToniAndGuy }
        }
      }
    }
  } = data

  const lookFantasticShopData = {
    field_wtb_link: {
      uri: 'https://www.lookfantastic.com/brands/wedo.list'
    },
    ...getRetailerData(logoLookFantastic, 'LookFantastic')
  }

  const toniAndGuyShopData = {
    field_wtb_link: {
      uri: 'https://store.toniandguy.com/brands/wedo/'
    },
    ...getRetailerData(logoToniAndGuy, 'Toni & Guy')
  }

  const shopsList = [
    {
      field_wtb_country: 'united_kingdom',
      field_wtb_link: {
        uri: 'https://www.regissalons.co.uk/shop/brands/wedo/'
      },
      ...getRetailerData(logoRegis, 'Regissalons.co.uk')
    },
    {
      field_wtb_country: 'united_kingdom',
      ...lookFantasticShopData
    },
    {
      field_wtb_country: 'united_kingdom',
      ...toniAndGuyShopData
    },
    {
      field_wtb_country: 'united_kingdom',
      field_wtb_link: {
        uri: 'https://www.supercuts.co.uk/shop/brand/wedo'
      },
      ...getRetailerData(logoSupercuts, 'Supercuts')
    },
    {
      field_wtb_country: 'ireland',
      ...lookFantasticShopData
    },
    {
      field_wtb_country: 'ireland',
      ...toniAndGuyShopData
    }
  ]

  useTracking({
    data: createContentPageDigitalData({
      contentType: 'happy',
      contentTitle: title,
      pageData: createPageData({
        pageId: title,
        breadCrumbs: `home:our-story:happy`,
        pageType: 'happy',
        instanceName: 'happy',
        primaryCategory: 'happy',
        language: intl.locale,
        pageTitle: getMetaTitle(metaTags),
        pageName: pageContext.slug
      })
    })
  })

  const { field_p1_text: paragraph1Text, field_p1_title: paragraph1Title } = paragraphs[0]
  const {
    field_p1_text: paragraph2Text,
    relationships: paragraph2Image,
    field_p1_image: paragraph2ImageAlt
  } = paragraphs[1]
  const {
    field_p1_text: paragraph3Text,
    field_p1_title: paragraph3Title,
    relationships: paragraph3Image,
    field_p1_image: paragraph3ImageAlt
  } = paragraphs[4]
  const {
    field_p2_text: paragraph4Text,
    field_p2_title: paragraph4Title,
    relationships: paragraph4Image,
    field_p2_link: paragraph4Link,
    field_p2_image: paragraph4ImageAlt
  } = paragraphs[2]
  const { field_p4_text: paragraph4SignpostText } = paragraphs[3]
  const {
    field_p2_text: paragraph5Text,
    field_p2_title: paragraph5Title,
    relationships: paragraph5Image,
    field_p2_link: paragraph5Link,
    field_p2_image: paragraph5ImageAlt
  } = paragraphs[5]
  const {
    field_p1_text: paragraph6Text,
    field_p1_title: paragraph6Title,
    relationships: paragraph6Image,
    field_p1_image: paragraph6ImageAlt
  } = paragraphs[6]

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <Hero heroBody="has-padding-vertical-3__nonmobile has-margin-top-0 has-padding-top-0__mobile">
        <div className="columns is-multiline is-centered">
          <div className="column is-full-touch is-two-thirds-desktop has-text-centered has-padding-vertical-3__mobile">
            <S.MainTitle className="title">{paragraph1Title.value}</S.MainTitle>
          </div>
          <div className="column is-full">
            <S.ParagraphContent dangerouslySetInnerHTML={{ __html: paragraph1Text.value }} />
          </div>
        </div>
      </Hero>

      <Hero heroBody="has-padding-vertical-3__nonmobile has-margin-top-0 has-padding-top-0__mobile">
        <div className="columns is-multiline is-centered">
          <S.ParagraphImage>
            <GatsbyImage
              {...formImageBaseData({ image: paragraph2Image?.field_p1_image })}
              alt={paragraph2ImageAlt?.alt} />
          </S.ParagraphImage>
          <div className="column is-full">
            <S.ParagraphContent dangerouslySetInnerHTML={{ __html: paragraph2Text.value }} />
          </div>
        </div>
      </Hero>
      {!disableHappyColoredSections.includes(intl.locale) && (
        <Hero heroBody="has-padding-vertical-2__nonmobile  has-padding-top-0__mobile" hero="is-primary">
          <a id="retailoffer" className="anchor-alignment" />
          <div className="columns is-centered">
            <div className="column is-half-tablet has-text-centered has-padding-vertical-3__mobile">
              <S.ParagraphTitle whiteFrame className="title has-text-right-mobile">
                {paragraph3Title.value}
              </S.ParagraphTitle>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-half-tablet is-one-third-desktop is-relative">
              <GatsbyImage className="is-covered" {...formImageBaseData({ image: paragraph3Image?.field_p1_image })} alt={paragraph3ImageAlt?.alt} />
            </div>

            <div className="column is-half-tablet is-one-third-desktop">
              <div dangerouslySetInnerHTML={{ __html: paragraph3Text.value }} />
              <p className="has-margin-top-2">
                {intl.locale === 'en-gb' &&
                  renderButton({
                    shopsList,
                    intl,
                    toggleModal
                  })}
              </p>
            </div>
          </div>
        </Hero>
      )}
      {!disableHappyColoredSections.includes(intl.locale) && (
        <Hero heroBody="has-padding-top-2__nonmobile has-padding-top-0__mobile has-padding-bottom-0" hero="is-secondary">
          <div className="columns is-centered">
            <div className="column is-half-tablet has-text-centered has-padding-vertical-3__mobile">
              <S.ParagraphTitle whiteFrame className="title has-text-right-mobile">
                {paragraph4Title.value}
              </S.ParagraphTitle>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-half-tablet has-text-centered has-padding-vertical-1__mobile">
              <h2 className="is-inline-block is-size-4 title has-text-right-mobile">
                *Available when Hair Salons Reopen*
              </h2>
            </div>
          </div>
          <div className="columns change-direction__nonmobile is-centered">
            <div className="column is-half-tablet is-one-third-desktop is-relative">
              <GatsbyImage className="is-covered" {...formImageBaseData({ image: paragraph4Image?.field_p2_image })} alt={paragraph4ImageAlt?.alt} />
            </div>
            <div className="column is-half-tablet is-one-third-desktop">
              <div dangerouslySetInnerHTML={{ __html: paragraph4Text.value }} />
              {paragraph4Link && (
                <p className="has-text-centered">
                  <Link
                    to={paragraph4Link.uri}
                    className="button is-outlined is-white-alpha is-uppercase is-fullwidth-mobile"
                  >
                    {paragraph4Link.title}
                  </Link>
                </p>
              )}
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-half-tablet has-text-centered has-padding-vertical-1__mobile">
              <h2 className="is-inline-block is-size-5 is-size-6-touch has-text-centered has-text-right-mobile">
                <div dangerouslySetInnerHTML={{ __html: paragraph4SignpostText.value }} />
              </h2>
            </div>
          </div>
        </Hero>
      )}
      {!disableHappyColoredSections.includes(intl.locale) && (
        <Hero heroBody="has-padding-top-2__nonmobile has-padding-top-0__mobile has-padding-bottom-0" hero="is-primary">
          <div className="columns is-centered">
            <div className="column is-half-tablet has-text-centered has-padding-vertical-3__mobile">
              <S.ParagraphTitle whiteFrame className="title has-text-right-mobile">
                {intl.formatMessage({ id: 'partnership.salonfinder' })}
              </S.ParagraphTitle>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-half-tablet has-text-centered has-padding-vertical-1__mobile">
              <h2 className="is-inline-block is-size-5 is-size-6-touch has-text-centered has-text-right-mobile">
                <div dangerouslySetInnerHTML={{ __html: paragraph4SignpostText.value }} />
              </h2>
            </div>
          </div>
        </Hero>
      )}
      {!disableHappyColoredSections.includes(intl.locale) && (
        <SalonFinder
          locale={intl.locale}
          hero={highlightedSalonFinder.includes(intl.locale) ? 'is-primary' : 'is-secondary'}
        />
      )}
      {!disableHappyNaturalHair.includes(intl.locale) && (
        <Hero heroBody="has-padding-vertical-2__nonmobile has-padding-top-2__mobile">
          <div className="columns is-centered">
            <div className="column is-half-tablet is-one-third-desktop is-relative">
              <GatsbyImage className="is-covered" {...formImageBaseData({ image: paragraph5Image?.field_p2_image })} alt={paragraph5ImageAlt?.alt} />
            </div>

            <div className="column is-half-tablet is-one-third-desktop">
              <S.ParagraphTitle className="title has-text-right-mobile">{paragraph5Title.value}</S.ParagraphTitle>

              <div dangerouslySetInnerHTML={{ __html: paragraph5Text.value }} />
              {paragraph5Link && (
                <p className="has-text-centered">
                  <Link
                    to={paragraph5Link.uri}
                    className="button is-outlined is-white-alpha is-uppercase is-fullwidth-mobile"
                  >
                    {paragraph5Link.title}
                  </Link>
                </p>
              )}
            </div>
          </div>
        </Hero>
      )}
      {!disableHappyColoredSections.includes(intl.locale) && (
        <Hero
          heroBody="has-padding-vertical-2__nonmobile  has-padding-top-2__mobile"
          hero="is-fourth has-margin-top-3__nonmobile"
        >
          <div className="columns change-direction__nonmobile is-centered">
            <div className="column is-half-tablet is-one-third-desktop is-relative">
              <GatsbyImage className="is-covered" {...formImageBaseData({ image: paragraph6Image?.field_p1_image })} alt={paragraph6ImageAlt?.alt} />
            </div>
            <div className="column is-half-tablet is-one-third-desktop">
              <S.ParagraphTitle className="title">{paragraph6Title?.value}</S.ParagraphTitle>
              <div dangerouslySetInnerHTML={{ __html: paragraph6Text.value }} />
            </div>
          </div>
        </Hero>
      )}
      <Hero>
        <div className="has-margin-bottom-2">
          <div className="is-uppercase has-text-weight-bold">{intl.formatMessage({ id: 'shared.you_may_like' })}</div>
        </div>

        {isClient && (
          <SwiperCarousel navLocation={toLowerKebabCase(title)}>
            {carouselData?.map(({ id, ...card }) => (
              <SwiperSlide tag="li" key={id}>
                <ProductCard {...card} isClickable intl={intl} />
              </SwiperSlide>
            ))}
          </SwiperCarousel>
        )}
      </Hero>

      {isModalOpen && intl.locale === 'en-gb' && (
        <BuyNowModal
          toggleModal={toggleModal}
          intl={intl}
          intlTag="product_page.banner"
          shopsList={shopsList}
          onClick={() => { }}
        />
      )}
    </Layout>
  )
}

export default Happy

export const query = graphql`
  query HappyPage(
    $slug: String!
    $langKey: String!
    $productVariantHeight: Int! = 565
    $productVariantWidth: Int! = 426
    $productVariantQuality: Int! = 75
    $isRelatedProductsIngredient: Boolean! = false
    $includeProductCategoryDrupalId: Boolean! = false
  ) {
    nodePage(field_component: { eq: "happy" }, path: { alias: { eq: $slug } }, langcode: { eq: $langKey }) {
      ...PageDataFragment

      relationships {
        field_sections {
          ... on paragraph__paragraph_1 {
            field_p1_text {
              value
            }
            field_p1_title {
              value
            }
            field_p1_image {
              alt
            }
            relationships {
              field_p1_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 75
                      webpOptions: { quality: 75 }
                      formats: [JPG, WEBP]
                    )
                  }
                }
              }
            }
          }
          ... on paragraph__paragraph_2 {
            field_p2_link {
              title
              uri
            }
            field_p2_text {
              value
            }
            field_p2_title {
              value
            }
            field_p2_image {
              alt
            }
            relationships {
              field_p2_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 75
                      webpOptions: { quality: 75 }
                      formats: [JPG, WEBP]
                    )
                  }
                }
              }
            }
          }
          ... on paragraph__paragraph_4 {
            field_p4_text {
              value
            }
          }
        }
      }
    }

    allNodeProduct(
      filter: { field_p_show_on_homepage: { eq: true }, status: { eq: true }, 
      langcode: { eq: $langKey }, drupal_internal__nid: {in: [28, 30, 38, 33, 37]} }
      sort: { fields: created, order: DESC }
    ) {
      ...RelatedProductsFragment
    }

    nodeRegis: taxonomyTermRetailers(name: { eq: "Regissalons.co.uk" }) {
      relationships {
        field_logotype {
          localFile {
            url
          }
        }
      }
    }

    nodeLookFantastic: taxonomyTermRetailers(name: { eq: "LookFantastic" }) {
      relationships {
        field_logotype {
          localFile {
            url
          }
        }
      }
    }

    nodeSupercuts: taxonomyTermRetailers(name: { eq: "Supercuts" }) {
      relationships {
        field_logotype {
          localFile {
            url
          }
        }
      }
    }

    nodeToniAndGuy: taxonomyTermRetailers(name: { eq: "Toni & Guy" }) {
      relationships {
        field_logotype {
          localFile {
            url
          }
        }
      }
    }
  }
`
