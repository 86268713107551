import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Link } from 'gatsby-plugin-intl'

import Video from 'src/components/Video'

import * as S from '../../styles/banner'
import { StyledIcon } from 'src/themes'

const VIDEO_CONFIG = {
  youtube: {
    playerVars: {
      mute: 1,
      autoPlay: 1,
      loop: 1,
      controls: 0,
      showInfo: 0,
      autoHide: 0
    },
    preload: false
  }
}

const BannerVideo = ({ url, handleModalData, intl, intlTag, handleScrollClick, text, link, showScroll }) => {
  const {
    file: { publicURL: iconsPath }
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "sign-icons.svg" }) {
          publicURL
        }
      }
    `
  )

  return (
    <S.StyledBanner className="hero homepage-banner">
      <Video
        url={url}
        loop
        playing
        volume={0}
        className={`video video-responsive video-background`}
        config={VIDEO_CONFIG}
        disableTracking
      />
      <S.HeroBody isVideo className="hero-body video">
        {text?.value && (
          <div
            className="has-text-centered has-text-white is-full-mobile is-9-tablet is-offset-2-tablet is-4-desktop is-offset-9-desktop is-size-3-tablet is-size-2-desktop has-margin-vertical-2__mobile has-margin-vertical-3__nonmobile has-padding-horizontal-2__mobile"
            dangerouslySetInnerHTML={{ __html: text.value }}
          />
        )}
        {link?.uri_alias && (
          <p className="has-text-centered has-margin-bottom-2">
            <Link className="button button is-outlined is-uppercase is-white-alpha is-fullwidth" to={link.uri_alias}>
              {link.title}
            </Link>
          </p>
        )}
        <p className="has-text-centered">
          <span className="button is-size-5 has-text-white is-transparent" onClick={handleModalData}>
            <S.PlayIconWrapper className="icon">
              <StyledIcon css={S.playIconStyles}>
                <use xlinkHref={`${iconsPath}#play-circle`} />
              </StyledIcon>
            </S.PlayIconWrapper>
            <span>{intl.formatMessage({ id: `${intlTag}.play_video` })}</span>
          </span>
        </p>
      </S.HeroBody>
      {showScroll && <S.ScrollDown className="is-hidden-mobile" onClick={handleScrollClick} />}
    </S.StyledBanner>
  )
}

export default BannerVideo

BannerVideo.propTypes = {
  url: PropTypes.string.isRequired,
  handleModalData: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  intlTag: PropTypes.string.isRequired,
  handleScrollClick: PropTypes.func.isRequired,
  text: PropTypes.shape({
    value: PropTypes.string
  }),
  link: PropTypes.shape({
    title: PropTypes.string,
    uri_alias: PropTypes.string
  }),
  showScroll: PropTypes.bool
}
