import React from 'react'

const getUserAgent = () => (typeof window.navigator === 'undefined' ? '' : navigator.userAgent)

const isAgentMobile = (userAgent) =>
  Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i))

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false)

  React.useEffect(() => {
    const userAgent = getUserAgent()
    const mobile = isAgentMobile(userAgent)

    setMobile(mobile)
  }, [])

  return { isMobile }
}
