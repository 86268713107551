import React, { FC } from 'react'

import { InputComponentProps } from './types'

const Input: FC<InputComponentProps> = ({
  label,
  name,
  type = 'text',
  value,
  onChange,
  placeholder = '',
  errorMessage = '',
  isInvalid,
  required
}) => (
  <div className="field">
    {!!label && (
      <label className="label" htmlFor={name}>
        {label}
      </label>
    )}
    <div className="control">
      <input
        name={name}
        type={type}
        value={value || ''}
        onChange={onChange}
        className={`input ${isInvalid ? 'is-danger' : ''}`}
        placeholder={placeholder}
        id={name}
        required={required}
      />
    </div>
    {isInvalid && <p className="help is-danger">{errorMessage}</p>}
  </div>
)

export default Input
