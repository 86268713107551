import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { ProgressController, MarginTopController } from '../types/progressBar'

import { breakpoints, colors, media } from 'src/themes'

const barColor = '#ffa671'

export const BarWrapper = styled.section`
  width: 90%;

  ${media({
    margin: ['1rem auto 1rem', undefined, '0 auto 3.5rem']
  })};
`

export const BarContainer = styled.div<MarginTopController>`
  background-color: ${barColor};
  border: 0.5px solid ${barColor};
  height: 6px;
  margin-bottom: 0.5rem;
  position: relative;
  width: 100%;

  ${({ isIframe }) =>
    isIframe &&
    css`
      margin-top: 1%;
    `};
`

export const BarProgress = styled.div<ProgressController>`
  background-color: ${colors.white};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 500ms ease-out;

  ${({ progressPercentage }) => ({
    transform: `translateX(${progressPercentage}%)`
  })}
`

export const ProgressText = styled.p`
  text-align: center;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: 0.65rem;
  }
`
