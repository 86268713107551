import { ProductData } from '../types'

const productData: ProductData[] = [
  {
    id: 1,
    text: 'No Plastic Shampoo Light & Soft',
    include: ['a1'],
    exclude: ['d5'],
    drupalId: 100,
    displayWeight: 5
  },
  {
    id: 2,
    text: 'Light & Soft Shampoo',
    include: ['a1'],
    exclude: ['a5', 'c5'],
    drupalId: 7,
    displayWeight: 4
  },
  {
    id: 3,
    text: 'Light & Soft Conditioner',
    include: ['a1'],
    drupalId: 29,
    displayWeight: 3
  },
  {
    id: 4,
    text: 'No Plastic Shampoo Moisture & Shine',
    include: ['b1', 'c1', 'c5'],
    exclude: ['d5', 'a1'],
    drupalId: 28,
    displayWeight: 5
  },
  {
    id: 5,
    text: 'Moisture & Shine Shampoo',
    include: ['b1', 'c1'],
    exclude: ['a5', 'c5'],
    excludeCondition: ['c1', ['c2', 'd2'], ['b3', 'c3']],
    drupalId: 27,
    displayWeight: 4
  },
  {
    id: 6,
    text: 'Moisture & Shine Conditioner',
    include: ['b1', 'c1'],
    excludeCondition: ['c1', ['c2', 'd2'], ['b3', 'c3']],
    drupalId: 30,
    displayWeight: 3
  },
  {
    id: 7,
    text: 'Rich & Repair Shampoo',
    condition: ['c1', ['c2', 'd2'], ['b3', 'c3']],
    exclude: ['a5', 'c5'],
    drupalId: 68,
    displayWeight: 4
  },
  {
    id: 8,
    text: 'Rich & Repair Conditioner',
    condition: ['c1', ['c2', 'd2'], ['b3', 'c3']],
    drupalId: 69,
    displayWeight: 3
  },
  {
    id: 9,
    text: 'Detangle',
    include: ['e4'],
    drupalId: 36,
    displayWeight: 1
  },
  {
    id: 10,
    text: 'Moisturizing Day Cream',
    include: ['a4', 'g4', 'h4'],
    excludeCondition: ['c1', ['a4']],
    drupalId: 38,
    displayWeight: 1
  },
  {
    id: 12,
    text: 'Natural Oil',
    condition: ['c1', ['a4']],
    drupalId: 37,
    displayWeight: 1
  },
  {
    id: 13,
    text: 'Light & Soft Mask',
    include: ['b3', 'c3', 'b4', 'i4'],
    exclude: ['b1', 'c1'],
    drupalId: 31,
    displayWeight: 2
  },
  {
    id: 14,
    text: 'Moisture & Shine Mask',
    include: ['b3', 'c3', 'b4', 'i4'],
    exclude: ['a1'],
    excludeCondition: ['c1', ['c2', 'd2'], ['b3', 'c3']],
    drupalId: 32,
    displayWeight: 2
  },
  {
    id: 15,
    text: 'Rich & Repair Mask',
    condition: ['c1', ['c2', 'd2'], ['b3', 'c3'], ['b3', 'c3', 'b4', 'i4']],
    drupalId: 70,
    displayWeight: 2
  },
  {
    id: 16,
    text: 'Nourishing Night Cream',
    include: ['f4', 'i4'],
    drupalId: 39,
    displayWeight: 1
  },
  {
    id: 17,
    text: 'Scalp Refresh',
    include: ['k4'],
    drupalId: 34,
    displayWeight: 1
  },
  {
    id: 17, // todo: id 17 should be doubled?
    text: 'Protect Balm',
    include: ['j4'],
    drupalId: 33,
    displayWeight: 0
  },
  // Test product for condition displayForCountry
  // {
  //   id: 18,
  //   text: 'No Plastic Shampoo Moisture & Shine',
  //   include: ['a1'],
  //   exclude: ['d5'],
  //   drupalId: 28,
  //   displayWeight: 5,
  //   displayForCountry: 'en-au'
  // },
]

export default productData
