import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import _get from 'lodash.get'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SwiperSlide } from 'swiper/react'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'
import SwiperCarousel from 'src/components/SwiperCarousel'
import ProductCard from 'src/components/ProductCard'
import CategoryCard from 'src/components/CategoryCard'

import useClientRerender from 'src/hooks/useClientRerender'
import useTracking from 'src/hooks/useTracking'
import useIngredientsPDF from 'src/hooks/useIngredientsPDF'
import { createPageData, createContentPageDigitalData } from 'src/utils/tracking'
import { getMetaTitle, findByTypeName, toLowerKebabCase, formImageBaseData } from 'src/utils/shared'
import { filterCarouselData } from './utils'

import { CategoryCardColumn } from 'src/themes'
import * as S from './styles/ingredient'

const PRODUCTS_ROW_CAP = 4

const Ingredient = ({ pageContext, data }) => {
  const intl = useIntl()
  const { langKey } = pageContext
  const isClient = useClientRerender()
  const {
    nodeIngredient: {
      title,
      field_breadcrumb: breadcrumbs,
      relationships: pageRelationships,
      field_metatag_normalized: metaTags
    },
    allNodeProduct: { nodes: productsList = [] }
  } = data

  const paragraphs = _get(pageRelationships, 'field_sections', [])

  const pdfURL = useIngredientsPDF({ langcode: intl.locale })

  const { field_p3_text, field_p3_title, field_p3_image, relationships: p3Image } = findByTypeName({
    arr: paragraphs,
    typeName: 'paragraph__paragraph_3'
  })

  const { field_p4_text, field_p4_title, field_p4_image, relationships: p4Image } = findByTypeName({
    arr: paragraphs,
    typeName: 'paragraph__paragraph_4'
  })

  const carouselData = filterCarouselData(productsList, title)

  const p3ImageData = formImageBaseData({ image: p3Image?.field_p3_image })
  const p4ImageData = formImageBaseData({ image: p4Image?.field_p4_image })

  useTracking({
    data: createContentPageDigitalData({
      contentType: 'our-ingredients',
      contentTitle: title,

      pageData: createPageData({
        pageId: title,
        breadCrumbs: `home:our-story:our-ingredients:${toLowerKebabCase(title)}`,
        pageType: 'our ingredients',
        instanceName: 'our ingredients',
        primaryCategory: 'our ingredients',
        language: intl.locale,
        pageTitle: getMetaTitle(metaTags),
        pageName: pageContext.slug
      })
    })
  })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={langKey} />
      <div className="columns ingr__heading has-margin-bottom-0__mobile has-padding-top-1">
        <div className="column is-relative has-padding-bottom-0__mobile">
          <h1 className="title__page has-padding-horizontal-2 has-padding-vertical-2 is-hidden-touch">{title}</h1>
          <div className="columns">
            <CategoryCardColumn className="column title-column is-hidden-desktop has-padding-horizontal-2 is-paddingless">
              <CategoryCard title={title} ratio="1.33" image={p3Image?.field_p3_image} alt={field_p3_image?.alt}/>
            </CategoryCardColumn>
          </div>
        </div>
      </div>

      <Hero
        hero="ingr__top__bg"
        heroBody="ingr__top__bg has-padding-vertical-1"
        customCSS={S.backgroundStyles(p3Image)}
      >
        <div className="columns is-multiline is-centered">
          <div className="column is-12-mobile is-8-tablet is-5-desktop">
            {field_p3_title && (
              <S.DescriptionTop
                className="title has-text-white has-margin-bottom-4"
                dangerouslySetInnerHTML={{ __html: field_p3_title.value }}
              />
            )}
            {field_p3_text && (
              <S.TextTop className="has-text-white" dangerouslySetInnerHTML={{ __html: field_p3_text.value }} />
            )}
          </div>

          <div className="column is-12-mobile is-3-tablet ingr__pulled_img has-margin-top-5-negative is-hidden-touch">
            {p3ImageData && <GatsbyImage {...p3ImageData} alt={field_p3_image.alt} />}
          </div>
        </div>
      </Hero>

      <Hero heroBody="ingr__body has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
        <div className="columns has-margin-bottom-4 is-centered change-direction-reverse">
          <div className="column is-4-desktop ingr__body__desc">
            {field_p4_title && (
              <S.DescriptionBody
                className="title is-inline-block"
                dangerouslySetInnerHTML={{ __html: field_p4_title.value }}
              />
            )}

            {field_p4_text && <div dangerouslySetInnerHTML={{ __html: field_p4_text.value }} />}
            {pdfURL && (
              <S.KnowMore
                href={pdfURL}
                target="_blank"
                rel="noopener noreferrer"
                className="button is-primary is-uppercase has-margin-vertical-1"
              >
                {intl.formatMessage({ id: 'shared.know_more_ingredients' })}
              </S.KnowMore>
            )}
          </div>

          <div className="column is-4-desktop ingr__body__imgn">{p4ImageData && <GatsbyImage {...p4ImageData} alt={field_p4_image.alt} />}</div>
        </div>
      </Hero>
      {!!carouselData?.length && (
        <Hero>
          <div className="has-margin-bottom-2">
            <div className="is-uppercase has-text-weight-bold">
              {intl.formatMessage({ id: `ingredient_related.ing_you_may_like` })}
            </div>
          </div>
          <SwiperCarousel
            config={{ loop: carouselData?.length > PRODUCTS_ROW_CAP }}
            key={isClient}
            navLocation="ingredient-page"
          >
            {carouselData.map(({ id, ...card }) => (
              <SwiperSlide tag="li" key={id}>
                <ProductCard {...card} isClickable intl={intl} />
              </SwiperSlide>
            ))}
          </SwiperCarousel>
        </Hero>
      )}
    </Layout>
  )
}

export default Ingredient

Ingredient.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    nodeIngredient: PropTypes.shape({
      title: PropTypes.string,
      field_breadcrumb: PropTypes.arrayOf(PropTypes.object),
      relationships: PropTypes.shape({
        field_sections: PropTypes.arrayOf(
          PropTypes.shape({
            relationships: PropTypes.shape({
              field_pc_product_category: PropTypes.arrayOf(PropTypes.object)
            })
          })
        ),
        field_p_ingredient_main: PropTypes.shape({
          title: PropTypes.string
        })
      }),
      field_metatag_normalized: PropTypes.arrayOf(PropTypes.object)
    }),
    allNodeProduct: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object)
    })
  })
}

export const query = graphql`
  query IngredientPage(
    $slug: String!
    $langKey: String!
    $productVariantHeight: Int! = 506
    $productVariantWidth: Int! = 382
    $productVariantQuality: Int! = 75
    $isRelatedProductsIngredient: Boolean! = true
    $includeProductCategoryDrupalId: Boolean! = false
  ) {
    nodeIngredient(path: { alias: { eq: $slug } }, langcode: { eq: $langKey }) {
      ...PageDataFragment

      relationships {
        field_sections {
          ... on paragraph__paragraph_3 {
            __typename
            field_p3_text {
              value
            }
            field_p3_title {
              value
            }
            field_p3_image {
              alt
            }
            relationships {
              field_p3_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 382
                      quality: 75
                      webpOptions: { quality: 75 }
                      outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
                      formats: [JPG, WEBP]
                    )
                    customRatio: gatsbyImageData(
                      layout: CONSTRAINED
                      width: 383
                      quality: 75
                      webpOptions: { quality: 75 }
                      aspectRatio: 1.33
                      outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
                      formats: [JPG, WEBP]
                    )
                  }
                }
              }
              field_p3_background {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 1920
                      quality: 75
                      webpOptions: { quality: 75 }
                      outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
                      formats: [JPG, WEBP]
                    )
                  }
                }
              }
              field_p3_background_mobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 75
                      webpOptions: { quality: 75 }
                      outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
                      formats: [JPG, WEBP]
                    )
                  }
                }
              }
            }
          }
          ... on paragraph__paragraph_4 {
            __typename
            field_p4_text {
              value
            }
            field_p4_title {
              value
            }
            field_p4_image {
              alt
            }
            relationships {
              field_p4_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      width: 382
                      quality: 75
                      webpOptions: { quality: 75 }
                      outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
                      formats: [JPG, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    allNodeProduct(filter: { langcode: { eq: $langKey } }) {
      ...RelatedProductsFragment
    }
  }
`
