import React, { useState, useEffect, FC } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
const Odometer = loadable(() => import('react-odometerjs'))

import Hero from 'src/components/Hero'
import * as S from './styled'

import { getNumbersToSum, calcBottlesPerInterval, msToMinutes } from './utils'
import { formImageBaseData, isBrowser } from 'src/utils/shared'

import { CounterHeroProps } from './types'

import 'odometer/themes/odometer-theme-default.css'

const INTL_TAG = 'partnership.counter'
/* 
  every INCREMENT_TIME, INCREMENT_VALUE of bottles are collected, 
  so the amount of milliseconds between two dates is 
  divided by 1 minute, multiplied by average value 
  and incremented by basic value from passed date
*/
const BOTTLES_DAILY = 46369
const INCREMENT_TIME = 360000 // time (in miliseconds) for counter to increment
const BASE_VALUE = 7496071 // start date base value
const INCREMENT_VALUE = calcBottlesPerInterval(BOTTLES_DAILY, INCREMENT_TIME) // value that should be added after INCREMENT_TIME minutes
const DATES_MINUTES_DIFF = msToMinutes(new Date().getTime() - new Date('october 27, 2021 14:00:00').getTime())
const INIT_BOTTLES_NUM = Math.floor((DATES_MINUTES_DIFF / msToMinutes(INCREMENT_TIME)) * INCREMENT_VALUE) + BASE_VALUE
// how many times should increment (from 1 to INCREMENT_VALUE)
const initRandomValue = Math.floor(Math.random() * INCREMENT_VALUE) + 1

const counterValues = getNumbersToSum(INCREMENT_VALUE, initRandomValue)
const counterTimeouts = getNumbersToSum(INCREMENT_TIME, initRandomValue, true)

const CounterHero: FC<CounterHeroProps> = ({ intl, image }) => {
  const [counterVal, setCounter] = useState(0)

  useEffect(() => {
    setCounter(INIT_BOTTLES_NUM)

    function incrementCounter() {
      setCounter((counterVal) => counterVal + counterValues.shift())

      const incrementTimes = Math.floor(Math.random() * INCREMENT_VALUE) + 1

      if (!counterValues.length) {
        counterValues.push(...getNumbersToSum(INCREMENT_VALUE, incrementTimes))
      }

      setTimeout(incrementCounter, counterTimeouts.shift())

      if (!counterTimeouts.length) {
        counterTimeouts.push(...getNumbersToSum(INCREMENT_TIME, incrementTimes, true))
      }
    }

    setTimeout(incrementCounter, counterTimeouts[0])
  }, [])

  return (
    <Hero heroBody="has-padding-top-2 has-padding-vertical-2__mobile has-padding-bottom-0 has-padding-bottom-2__nonmobile has-text-centered">
      <h1 className="title__page is-size-3 has-margin-bottom-2">{intl.formatMessage({ id: `${INTL_TAG}.header` })}</h1>
      <h4 className="is-size-5 is-bold">{intl.formatMessage({ id: `${INTL_TAG}.we_collected` })}</h4>
      <S.CounterWrapper className="has-margin-vertical-1" css={S.odometerDigit}>
        {isBrowser && <Odometer format="d" duration={500} value={counterVal} />}
      </S.CounterWrapper>
      <h4 className="is-size-5 is-bold has-padding-bottom-3">
        {intl.formatMessage({ id: `${INTL_TAG}.bottles_with` })}
      </h4>
      <h4 className="is-size-5 has-text-weight-bold has-padding-vertical-1__mobile">
        {intl.formatMessage({ id: `${INTL_TAG}.in_partnership` })}
      </h4>
      <GatsbyImage className="has-margin-bottom-3" css={S.partnerLogo} {...formImageBaseData({ image })} />
      <p className="pre-lined">{intl.formatMessage({ id: `${INTL_TAG}.pb_desc` })}</p>
    </Hero>
  )
}

export default CounterHero
