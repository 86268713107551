import styled from '@emotion/styled'

import { mq } from 'src/themes'

export const Icon404Wrapper = styled.svg`
  ${mq({
    height: ['25vw', '17vw', 120, 144, 166],
    width: ['86vw', '68vw', 468, 564, 652]
  })}
`

export const Icon404 = styled.use`
  ${mq({
    fontSize: ['86vw', '68vw', 468, 564, 652]
  })}
`
