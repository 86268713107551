export const filterCarouselData = (productsList, title) =>
  productsList.filter((node) => {
    const ingredientsList = node?.relationships?.field_sections.filter(
      ({ relationships }) => relationships?.field_p_ingredient_main
    )

    const result = ingredientsList.find(({ relationships }) => relationships?.field_p_ingredient_main.title === title)

    if (result) {
      return node
    }
  })
