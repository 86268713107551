import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-plugin-intl'
import Hero from 'src/components/Hero'
import * as S from '../styles/naturalHairStyle'
import { getArtDirection } from 'src/utils/shared'
import { title } from '../styles/styled'
const INTL_TAG = 'homepage.natural_hair_care'

const NaturalHairCare = ({ intl, img, }) => {
  return (
    <Hero hero="is-clippedhero css-0" heroBodyStyles={S.heroBodyStyles}>
      <div className="container has-text-centered-tablet css-0">
        <h2 className="title is-inline-block css-pxuuth-frame-frame-title-title ml-5 mr-5" css={title('orange')} >{intl.formatMessage({ id: `${INTL_TAG}.header` })}</h2>
            <div css={S.textStyles}>
             <div className='column is-half-fullhd is-half is-full-mobile p-0'>
               <p className="has-margin-bottom-1 pl-5 pr-5" >{intl.formatMessage({ id: `${INTL_TAG}.desc1` })}</p>
               <p className="has-margin-bottom-1 pl-5 pr-5">{intl.formatMessage({ id: `${INTL_TAG}.desc2` })}</p>
             </div>
            </div>
        <S.ImageSection>
          <GatsbyImage css={S.bannerImgStyles} image={getArtDirection(img.image)} alt={img.alt} />
        </S.ImageSection>
        <p className="has-margin-top-2 has-padding-horizontal-2__mobile">
          <Link
            to={intl.formatMessage({ id: `${INTL_TAG}.natural_hair_care_link` })}
            className="button is-outlined is-primary is-uppercase is-fullwidth-mobile"
            language={intl.formatMessage({id: `${INTL_TAG}.natural_hair_care_link_lang`, defaultMessage: intl.locale})}
          >
            {intl.formatMessage({ id: `${INTL_TAG}.button_hair_care` })}
          </Link>
        </p>
      </div>
    </Hero>
  )
}

export default NaturalHairCare

NaturalHairCare.propTypes = {
  img: PropTypes.shape({
    image: PropTypes.arrayOf(PropTypes.object).isRequired,
    alt: PropTypes.string
  }),
  intl: PropTypes.object
}
