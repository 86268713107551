import React, { FC } from 'react'

import { CheckboxProps } from './types'

const Checkbox: FC<CheckboxProps> = ({
  label,
  name = '',
  value,
  onChange,
  errorMessage = '',
  isInvalid,
  fieldClass = '',
  required
}) => (
  <div className={`field field-checkbox ${fieldClass}`}>
    <input type="checkbox" name={name} id={name} onChange={onChange} checked={value} required={required} />
    {!!label && (
      <label
        htmlFor={name}
        className={`checkbox ${isInvalid ? 'is-danger' : ''}`}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    )}
    {isInvalid && <p className="help is-danger">{errorMessage}</p>}
  </div>
)

export default Checkbox
