import { css } from '@emotion/react'

export const videoStyles = css`
  position: relative;
  padding-bottom: 56.25%;
  width: 100% !important;
  height: 0 !important;
  overflow: hidden;

  iframe,
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .react-player {
    &__shadow {
      background: rgba(0, 0, 0, 0.75) !important;
    }
    &__play-icon {
      margin-left: 5px !important;
      border-width: 10px 0 10px 16px !important;
    }
  }
`

export const videoHidden = css`
  visibility: hidden;
  height: 1px !important;
  padding: 0 !important;
  pointer-events: none;
`
