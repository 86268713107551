import { useState, useCallback } from 'react'

import type { UseToggleModal } from './useToggleModal.types'

const useToggleModal = (): UseToggleModal => {
  const [isModalOpen, handleToggleModal] = useState(false)

  const toggleModal = useCallback(() => {
    handleToggleModal(!isModalOpen)
    document.documentElement.classList.toggle('is-clipped')
  }, [isModalOpen])

  return { isModalOpen, toggleModal }
}

export default useToggleModal
