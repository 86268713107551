import React, { FC } from 'react'

import { SelectProps } from './types'

const Select: FC<SelectProps> = ({ label, options, onChange, name, value, placeholder, isInvalid, errorMessage }) => (
  <div className="field field-select">
    {!!label && (
      <label className="label" htmlFor={name}>
        {label}
      </label>
    )}
    <div className="control">
      <div className={`select ${isInvalid ? 'is-danger' : ''}`}>
        <select name={name} onChange={onChange} value={value || ''} id={name}>
          {!value && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map(({ label, value, id }) => (
            <option key={id || value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </div>
    {isInvalid && <p className="help is-danger">{errorMessage}</p>}
  </div>
)

export default Select
