import React, { useState, useCallback, useEffect, useRef, useMemo, FC } from 'react'

import { getAgreementsUrl, validateForm, getAgreementsLangCode, mapAgreementsPropertyNames } from 'src/utils/form'
import { fetchPost, createDefaultPortal } from 'src/utils/shared'

import useFormState from 'src/hooks/useFormState'
import useToggleModal from 'src/hooks/useToggleModal'
import { RECAPTCHA_KEY, EMAIL_REGEX, SUBSCRIBE_FETCH_URL } from 'src/consts'
import signupCompleteEvent from 'src/dataLayer/Subscribe'

import Modal from 'src/components/Modal'
import ModalContent from 'src/components/ModalContent'
import Input from 'src/components/Form/Input'
import Select from 'src/components/Form/Select'
import Recaptcha from 'src/components/Form/Recaptcha'
import Agreements from 'src/components/Form/Agreements'
import Checkbox from 'src/components/Form/Checkbox';
import type { ErrorState } from 'src/types'
import type { FormProps, AgreementsData, FieldProps } from 'src/types'

const REQUIRED_FIELDS = ['ContactType', 'FirstName', 'LastName', 'Email', 'Country', 'Email', 'Response', 'Site']
const MONTHS_KEYS = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

const INTL_TAG = 'subscribe'
const INTL_FORM_TAG = 'form'

const initialState = {
  FormCode: 'Wedo_Newsletter',
  Site: RECAPTCHA_KEY,
  Response: null,
  AdditionalField: { ProvinceSelector: '' }
}

const selectBirtDayOptions = new Array(31).fill(0).map((_, ind) => ({
  label: `${ind + 1}`,
  value: `${ind + 1}`,
  id: ind + 1
}))

const Form: FC<FormProps> = ({ intl, countries, language, contactImgPopup }) => {
  const [errorState, setErrorState] = useState<ErrorState>({})
  const [isFetching, setFetchingState] = useState(false)

  const agreements = useRef(null)
  const recaptchaRef = useRef(null)

  const { isModalOpen, toggleModal } = useToggleModal()
  const {
    state: formState,
    handleInputChange,
    checkboxState,
    handleCheckboxChange,
    setCheckboxState,
    handleRecaptchaVerify,
    handleRecaptchaExpire,
    clearForm
  } = useFormState({
    initialState,
    errorState,
    setErrorState
  })

  useEffect(() => {
    const url = getAgreementsUrl({
      formCode: 'Wedo_Newsletter',
      Language: getAgreementsLangCode(intl.locale)
    })

    fetch(url)
      .then((resp) => resp.json())
      .then(({ Data }: AgreementsData) => {
        agreements.current = mapAgreementsPropertyNames(Data.Agreements) // eslint-disable-line
      })
      .then(() => {
        const initialCheckboxState = agreements.current.reduce(
          (total, { name, required }) => ({
            ...total,
            [name]: required ? null : false
          }),
          {}
        )

        agreements.current.forEach(({ name, required }) => {
          if (required) {
            REQUIRED_FIELDS.push(name)
          }
        })
  
        if (intl.locale == 'de-de') {
          agreements.current.find(f => f.name == 'Privacy').label = intl.formatMessage({id: `${INTL_TAG}.privacy_de_agrement`});
        }

        setCheckboxState(initialCheckboxState)
      })
      .catch(() => {
        setFetchingState(true)
      })
  }, [intl.locale, setCheckboxState])

  const handleModalClose = useCallback(() => {
    clearForm()
    recaptchaRef.current.reset()
    toggleModal()
  }, [clearForm, toggleModal])

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
  
      if (intl.locale == 'de-de') {
        REQUIRED_FIELDS.push('Terms');
      }
      
      const formError = validateForm({
        formState: { ...formState, ...checkboxState },
        requiredFields: REQUIRED_FIELDS
      })

      if (Object.values(formError).includes(true)) {
        setErrorState(formError)
        signupCompleteEvent({ action: 'error' })
        return
      }

      const dataToFetch = agreements?.current && {
        ...formState,
        Agreements: agreements.current.map(({ id, name }) => ({
          Id: id,
          Value: checkboxState[name]
        }))
      }

      setFetchingState(true)

      try {
        const json = await fetchPost({ url: SUBSCRIBE_FETCH_URL, body: dataToFetch })

        if (!json.ErrorMessage) {
          signupCompleteEvent({ action: 'complete' })
          toggleModal()
        } else signupCompleteEvent({ action: 'error' })
      } finally {
        setFetchingState(false)
      }
    },
    [formState, checkboxState, toggleModal]
  )

  const selectContactTypeOptions: FieldProps[] = useMemo(
    () => [
      { label: intl.formatMessage({ id: `${INTL_FORM_TAG}.professional` }), value: 'Professional' },
      { label: intl.formatMessage({ id: `${INTL_FORM_TAG}.consumer` }), value: 'Consumer' }
    ],
    [intl]
  )

  const selectBirthMonthsOptions: FieldProps[] = useMemo(
    () =>
      MONTHS_KEYS.map((name, i) => ({
        label: intl.formatMessage({ id: `${INTL_FORM_TAG}.months.${name}` }),
        value: `${i + 1}`,
        id: name
      })),
    [intl]
  )

  const { Country, FirstName, LastName, Email, BirthDay, BirthMonth, ContactType, Response } = formState

  return (
    <form>
      <fieldset disabled={isFetching}>
        <div className="columns is-narrow">
          <div className="column is-7-tablet is-5-widescreen has-padding-bottom-0">
            <div className="has-margin-bottom-2">
              <h2 className="column is-inline-block title has-frame">
                {intl.formatMessage({ id: `${INTL_TAG}.subheader` })}
              </h2>
            </div>
            <Select
              label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.im` })}*`}
              name="ContactType"
              options={selectContactTypeOptions}
              placeholder={intl.formatMessage({ id: `${INTL_FORM_TAG}.please_select` })}
              onChange={handleInputChange}
              required
              value={ContactType}
              isInvalid={ContactType === '' || errorState.ContactType}
              errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
            />
          </div>
        </div>
        <Input
          label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.first_name` })}*`}
          name="FirstName"
          onChange={handleInputChange}
          value={FirstName}
          isInvalid={FirstName === '' || errorState.FirstName}
          errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
        />
        <Input
          label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.last_name` })}*`}
          name="LastName"
          onChange={handleInputChange}
          value={LastName}
          isInvalid={LastName === '' || errorState.LastName}
          errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
        />
        <div className="columns">
          <div className="column is-5 has-padding-bottom-0">
            <div className="columns is-vcentered">
              <div className="column">
                <Select
                  label={intl.formatMessage({ id: `${INTL_FORM_TAG}.birthday` })}
                  name="BirthDay"
                  options={selectBirtDayOptions}
                  onChange={handleInputChange}
                  value={BirthDay}
                />
              </div>
              <div css={{ marginTop: 'auto' }} className="column align-bottom">
                <Select
                  name="BirthMonth"
                  options={selectBirthMonthsOptions}
                  onChange={handleInputChange}
                  value={BirthMonth}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-5 has-padding-bottom-0">
            <Select
              label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.choose_location` })}*`}
              name="Country"
              options={countries}
              onChange={handleInputChange}
              required
              value={Country}
              isInvalid={Country === '' || errorState.Country}
              errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
            />
          </div>
        </div>

        <Input
          label={`${intl.formatMessage({ id: `${INTL_FORM_TAG}.email` })}*`}
          name="Email"
          onChange={handleInputChange}
          value={Email}
          isInvalid={Email === '' || (!!Email && !EMAIL_REGEX.test(Email)) || errorState.Email}
          errorMessage={intl.formatMessage({
            id: !Email ? `${INTL_FORM_TAG}.required` : `${INTL_FORM_TAG}.invalid_data`
          })}
        />
        { intl.locale === 'de-de' && <div className="field is-size-7" dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: `${INTL_TAG}.checkbox_desc` }) }}></div>}
        {!!agreements.current && (
          <Agreements
            agreements={agreements.current}
            handleCheckboxChange={handleCheckboxChange}
            checkboxState={checkboxState}
            errorState={errorState}
          />
        )}
        {intl.locale === 'de-de' &&
          <Checkbox
            name={'Terms'}
            label={intl.formatMessage({id: `${INTL_TAG}.terms_de_agrement`})}
            onChange={handleCheckboxChange}
            value={checkboxState['Terms']}
            isInvalid={(checkboxState['Terms'] === false || errorState['Terms'])}
            errorMessage={intl.formatMessage({id: `${INTL_FORM_TAG}.required`})}
            fieldClass="is-size-7 has-margin-vertical-2"
            required
          />
        }
        <Recaptcha
          sitekey={RECAPTCHA_KEY}
          onVerify={handleRecaptchaVerify}
          onExpire={handleRecaptchaExpire}
          lang={language}
          isInvalid={Response === false || errorState.Response}
          errorMessage={intl.formatMessage({ id: `${INTL_FORM_TAG}.required` })}
          ref={recaptchaRef}
        />
        <div className="field">
          <button type="submit" className="button is-primary is-fullwidth-mobile" onClick={handleSubmit}>
            {intl.formatMessage({ id: `${INTL_TAG}.submit` })}
          </button>
        </div>
        <div className="field is-size-7">{intl.formatMessage({ id: `${INTL_FORM_TAG}.mandatory` })}</div>

        {isModalOpen &&
          createDefaultPortal(
            <Modal onClick={handleModalClose}>
              <ModalContent
                className="subscribe-modal-img"
                header={intl.formatMessage({ id: `${INTL_TAG}.modal_header` })}
                onClick={handleModalClose}
                image={contactImgPopup}
              />
            </Modal>,
            document.getElementById('gatsby-focus-wrapper')
          )}
      </fieldset>
    </form>
  )
}

export default Form
