import dispatch from 'src/utils/tracking/dispatch'

const carouselClickEvent = ({ navLocation = '', next = false, prev = false, clicked, sliderNum }) => {
  const eventName = 'carouselClick'

  const detail = {
    eventName,
    eventAction: 'Carousel-Navigation-Click',
    eventType: 'carouselClick',
    event: {
      component: 'carousel',
      category: 'navigation',
      navLocation,
      nextsliderArrow: next,
      prevsliderArrow: prev,
      selectedNavItem: clicked,
      sliderNum
    }
  }

  dispatch({
    eventName,
    detail
  })
}

export default carouselClickEvent
