import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SwiperSlide } from 'swiper/react'

import { formImageBaseData, isMobileImageProvided } from 'src/utils/shared'

import SwiperCarousel from 'src/components/SwiperCarousel'
import Video from 'src/components/Video'
import Modal from 'src/components/Modal'

import useClientRerender from 'src/hooks/useClientRerender'

import BannerImg from './components/BannerImg'
import BannerVideo from './components/BannerVideo'
import BannerDefault from './components/BannerDefault'
import * as S from '../styles/banner'

const INTL_TAG = 'homepage.banner'
const carouselConfig = {
  autoplay: {
    delay: 10 * 1000,
    disableOnInteraction: false
  },
  pagination: {
    type: 'bullets',
    bulletElement: 'button',
    clickable: true
  },
  watchSlidesVisibility: false,
  breakpoints: {},
  className: 'swiper-banner'
}
const Banner = ({ intl, handleScrollClick, bannerData = [] }) => {
  // todo: BannerDefault be redundant
  if (!bannerData.length) {
    return <BannerDefault intl={intl} onScrollClick={handleScrollClick} />
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isClient = useClientRerender()

  /* eslint-disable react-hooks/rules-of-hooks */
  const [modalData, handleModalData] = useState(undefined)
  /* eslint-enable react-hooks/rules-of-hooks */

  const handleToggleModal = (data) => {
    handleModalData(data?.uri ? data : undefined)
    document.documentElement.classList.toggle('is-clipped')
  }

  return (
    <S.StyledBanner className="hero homepage-banner">
      <SwiperCarousel key={isClient} config={carouselConfig} isBanner>
        {bannerData.map(({ id, link, text, video, bgVideo, relationships, field_hi_image, field_hi_image_mobile }) => {
          const { field_hi_image_mobile: mobileImageData, field_hi_image: desktopImageData } = relationships

          if (!video) {
            const imageData = {
              mobile: formImageBaseData({
                image: isMobileImageProvided({
                  mobileImageData,
                  desktopImageData
                })
              }),
              desktop: formImageBaseData({ image: desktopImageData })
            }

            return (
              <SwiperSlide tag="li" key={id}>
                <BannerImg
                  link={link}
                  text={text}
                  imageData={imageData}
                  intl={intl}
                  intlTag={INTL_TAG}
                  alt={field_hi_image?.alt}
                  altMobile={field_hi_image_mobile?.alt} />
              </SwiperSlide>
            )
          }

          return (
            <SwiperSlide tag="li" key={id}>
              <BannerVideo
                url={bgVideo.uri}
                modalUrl={video.uri}
                text={text}
                link={link}
                loop
                className={`video video-responsive video-background`}
                intl={intl}
                intlTag={INTL_TAG}
                handleScrollClick={handleScrollClick}
                handleModalData={() => handleToggleModal(video)}
              />
            </SwiperSlide>
          )
        })}
      </SwiperCarousel>
      {!!modalData && (
        <Modal onClick={handleToggleModal} css={S.modalStyle}>
          <Video url={modalData.uri} playing className="video video-responsive" />
        </Modal>
      )}
    </S.StyledBanner>
  )
}

export default Banner

Banner.whyDidYouRender = {
  logOnDifferentValues: true
}

Banner.propTypes = {
  intl: PropTypes.object,
  handleScrollClick: PropTypes.func.isRequired,
  bannerData: PropTypes.arrayOf(PropTypes.object).isRequired
}
