import React, { FC } from 'react'

import CategoryCard from 'src/components/CategoryCard'

import * as S from './styles/startScreen'

import { StartScreenProps } from './types/startScreen'

const INTL_TAG = 'quiz.start_screen'

const StartScreen: FC<StartScreenProps> = ({ startQuiz, desktopImg, mobileImg, intl }) => (
  <S.MainWrapper className="container">
    <S.ContentContainer>
      <S.Header>{intl.formatMessage({ id: `${INTL_TAG}.header` })}</S.Header>
      <S.Description>{intl.formatMessage({ id: `${INTL_TAG}.description` })}</S.Description>
    </S.ContentContainer>

    <CategoryCard
      title={intl.formatMessage({ id: `${INTL_TAG}.card_title` })}
      buttonText={intl.formatMessage({ id: `${INTL_TAG}.start_button` })}
      disableHoverEffects
      handleButtonClick={startQuiz}
      imageArtDirection={{
        image: [mobileImg, desktopImg],
        alt: intl.formatMessage({ id: `images.quiz.start` })
      }}
      isQuizCard
    />
    <S.StartButton
      data-test="quiz-start-button"
      className="button is-outlined is-uppercase"
      onClick={() => startQuiz(true)}
    >
      {intl.formatMessage({ id: `${INTL_TAG}.start_button` })}
    </S.StartButton>
  </S.MainWrapper>
)

export default StartScreen
