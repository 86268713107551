import { useLayoutEffect, useState } from 'react'

const useClientRerender = (): number => {
  const [isClient, setIsClient] = useState<number>(0)

  useLayoutEffect(() => {
    setIsClient(1)
  }, [])

  return isClient
}

export default useClientRerender
