import { IntlShape } from 'gatsby-plugin-intl'
import { AnswerData, QuestionData } from '../types'

const answers1 = (intl: IntlShape, INTL_TAG: string): AnswerData[] => [
  {
    questionId: 1,
    id: 'a1',
    text: intl.formatMessage({ id: `${INTL_TAG}.hair_type.answers.fine` }),
    image: 'quiz-fine-img'
  },
  {
    questionId: 1,
    id: 'b1',
    text: intl.formatMessage({ id: `${INTL_TAG}.hair_type.answers.medium` }),
    image: 'quiz-medium-img'
  },
  {
    questionId: 1,
    id: 'c1',
    text: intl.formatMessage({ id: `${INTL_TAG}.hair_type.answers.coarse` }),
    image: 'quiz-coarse-img'
  }
]

export default (intl: IntlShape, INTL_TAG: string): QuestionData => ({
  id: 1,
  text: intl.formatMessage({ id: `${INTL_TAG}.hair_type.question` }),
  answers: answers1(intl, INTL_TAG),
  hint: intl.formatMessage({ id: `${INTL_TAG}.hair_type.hint` })
})
