import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { breakpoints, colors } from 'src/themes'

export const ImageSection = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
`

export const QuizSectionTitle = styled.h1`
  @media (max-width: 769px) {
    width: 90%;
  }
`

export const bannerImgStyles = css`
  @media (max-width: ${breakpoints.tablet}) {
    height: 0 !important;
    padding-bottom: 68%;
  }
`

export const heroBodyStyles = css`
  @media (max-width: ${breakpoints.tablet}) {
    padding: 1.5rem 0;
  }
`

export const ImageContent = styled.div`
  margin-left: 40px;
  position: absolute;
  text-align: left;
  width: 100%;
`

export const ImageTextDesc = styled.h2`
  color: ${colors.white};
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`
