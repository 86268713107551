import flags from 'src/assets/images/flags.svg'

export const COUNTRIES_FLAGS = {
  denmark: `${flags}#DK-flag`,
  finland: `${flags}#FI-flag`,
  sweden: `${flags}#SE-flag`,
  norway: `${flags}#NO-flag`,
  germany: `${flags}#DE-flag`,
  austria: `${flags}#AT-flag`,
  switzerland: `${flags}#CH-flag`,
  greatbritain: `${flags}#UK-flag`,
  united_kingdom: `${flags}#UK-flag`,
  ireland: `${flags}#IE-flag`
}
