import { IntlShape } from 'gatsby-plugin-intl'
import { AnswerData, QuestionData } from '../types'

const answers5 = (intl: IntlShape, INTL_TAG: string): AnswerData[] => [
  {
    questionId: 5,
    id: 'a5',
    text: intl.formatMessage({ id: `${INTL_TAG}.solid_shampoo.answers.continue` })
  },
  {
    questionId: 5,
    id: 'c5',
    text: intl.formatMessage({ id: `${INTL_TAG}.solid_shampoo.answers.try` })
  },
  {
    questionId: 5,
    id: 'd5',
    text: intl.formatMessage({ id: `${INTL_TAG}.solid_shampoo.answers.not_interested` })
  }
]

export default (intl: IntlShape, INTL_TAG: string): QuestionData => ({
  id: 5,
  text: intl.formatMessage({ id: `${INTL_TAG}.solid_shampoo.question` }),
  answers: answers5(intl, INTL_TAG),
  withoutImages: true
})
