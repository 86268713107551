import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import usePositionY from 'src/hooks/usePositionY'

import { StyledIcon } from 'src/themes'

import { isBrowser } from 'src/utils/shared'

const handleButtonClick = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })

const ScrollToTop = () => {
  const positionY = usePositionY()
  const {
    file: { publicURL: iconsPath }
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "sign-icons.svg" }) {
          publicURL
        }
      }
    `
  )

  if (positionY < 400) {
    return null
  }

  const isScrolledToBottom =
    isBrowser && Math.round(window.pageYOffset + window.innerHeight) >= document.documentElement.offsetHeight - 50

  return (
    <button
      className={`button is-primary scroll-top is-medium ${isScrolledToBottom ? 'scrolled-bottom' : ''}`}
      onClick={handleButtonClick}
    >
      <span className="icon is-small">
        <StyledIcon css={{ width: '0.875em' }}>
          <use xlinkHref={`${iconsPath}#chevron-up`} />
        </StyledIcon>
      </span>
    </button>
  )
}

export default ScrollToTop
