import React, { FC } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { ModalContentProps } from './types'

import { formImageBaseData } from 'src/utils/shared'

const ModalContent: FC<ModalContentProps> = ({
  header = '',
  content = '',
  button = 'OK',
  image,
  onClick,
  className
}) => (
  <div className="box is-marginless is-paddingless is-hoverable">
    <div className="columns is-vcentered is-marginless is-paddingless">
      <div className="column is-marginless is-paddingless">
        <div className="box">
          <h1 className="title__page">{header}</h1>
          <div className="is-size-7 has-margin-vertical-2" dangerouslySetInnerHTML={{ __html: content }} />
          <button className="button is-primary is-fullwidth" onClick={onClick}>
            {button}
          </button>
        </div>
      </div>
      <div className="column is-marginless is-paddingless is-hidden-mobile">
        <GatsbyImage className={`img-photo ${className || ''}`} {...formImageBaseData({ image })} />
      </div>
    </div>
  </div>
)

export default ModalContent
