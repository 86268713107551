import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby-plugin-intl'

import Hero from 'src/components/Hero'
import PictureCard from 'src/components/PictureCard'
import { title } from '../styles/styled'

const INTL_TAG = 'homepage.what_its_made_from'

const MadeFrom = ({ intl, data, btnLink }) => {
  return (
    <Hero hero="is-clipped" container="has-text-centered-tablet">
      <h2 className="title is-inline-block" css={title('orange')}>
        {intl.formatMessage({ id: `${INTL_TAG}.header` })}
      </h2>
      <p className="has-margin-bottom-2" css={{ whiteSpace: 'pre-line' }}>
        {intl.formatMessage({ id: `${INTL_TAG}.desc` })}
      </p>
      {!!data?.length && (
        <div data-test="weblog-recent-section" className="columns is-multiline is-mobile">
          {data.map(({ id, path, field_i_image, ...card }) => (
            <div className="column is-half-mobile is-one-third-tablet is-one-quarter-widescreen" key={id}>
              <Link to={path?.alias}>
                <PictureCard true {...card} alt={field_i_image?.alt}/>
              </Link>
            </div>
          ))}
        </div>
      )}
      <p className="has-margin-top-2">
        <Link to={btnLink} className="button is-outlined is-primary is-uppercase is-fullwidth-mobile">
          {intl.formatMessage({ id: `${INTL_TAG}.button_explore` })}
        </Link>
      </p>
    </Hero>
  )
}

export default MadeFrom

MadeFrom.propTypes = {
  intl: PropTypes.any,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      relationships: PropTypes.shape({
        field_i_image: PropTypes.shape({
          alt: PropTypes.string,
          localFile: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              gatsbyImageData: PropTypes.shape({
                images: PropTypes.shape({
                  fallback: PropTypes.shape({
                    src: PropTypes.string.isRequired
                  })
                })
              })
            })
          })
        })
      }).isRequired
    })
  ).isRequired,
  btnLink: PropTypes.string
}
