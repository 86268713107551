exports.components = {
  "component---src-intl-pages-basic-page-index-js": () => import("./../../../src/intlPages/basic-page/index.js" /* webpackChunkName: "component---src-intl-pages-basic-page-index-js" */),
  "component---src-intl-pages-contact-us-index-tsx": () => import("./../../../src/intlPages/contact-us/index.tsx" /* webpackChunkName: "component---src-intl-pages-contact-us-index-tsx" */),
  "component---src-intl-pages-find-salon-index-js": () => import("./../../../src/intlPages/find-salon/index.js" /* webpackChunkName: "component---src-intl-pages-find-salon-index-js" */),
  "component---src-intl-pages-happy-index-tsx": () => import("./../../../src/intlPages/happy/index.tsx" /* webpackChunkName: "component---src-intl-pages-happy-index-tsx" */),
  "component---src-intl-pages-our-story-index-tsx": () => import("./../../../src/intlPages/our-story/index.tsx" /* webpackChunkName: "component---src-intl-pages-our-story-index-tsx" */),
  "component---src-intl-pages-our-story-our-brand-index-tsx": () => import("./../../../src/intlPages/our-story/our-brand/index.tsx" /* webpackChunkName: "component---src-intl-pages-our-story-our-brand-index-tsx" */),
  "component---src-intl-pages-our-story-our-ingredients-index-tsx": () => import("./../../../src/intlPages/our-story/our-ingredients/index.tsx" /* webpackChunkName: "component---src-intl-pages-our-story-our-ingredients-index-tsx" */),
  "component---src-intl-pages-our-story-our-team-index-tsx": () => import("./../../../src/intlPages/our-story/our-team/index.tsx" /* webpackChunkName: "component---src-intl-pages-our-story-our-team-index-tsx" */),
  "component---src-intl-pages-products-js": () => import("./../../../src/intlPages/products.js" /* webpackChunkName: "component---src-intl-pages-products-js" */),
  "component---src-intl-pages-search-index-js": () => import("./../../../src/intlPages/search/index.js" /* webpackChunkName: "component---src-intl-pages-search-index-js" */),
  "component---src-intl-pages-sitemap-index-js": () => import("./../../../src/intlPages/sitemap/index.js" /* webpackChunkName: "component---src-intl-pages-sitemap-index-js" */),
  "component---src-intl-pages-subscribe-index-tsx": () => import("./../../../src/intlPages/subscribe/index.tsx" /* webpackChunkName: "component---src-intl-pages-subscribe-index-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hair-quiz-tsx": () => import("./../../../src/pages/hair-quiz.tsx" /* webpackChunkName: "component---src-pages-hair-quiz-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lets-collect-js": () => import("./../../../src/pages/lets-collect.js" /* webpackChunkName: "component---src-pages-lets-collect-js" */),
  "component---src-pages-wedo-hair-quiz-tsx": () => import("./../../../src/pages/wedo-hair-quiz.tsx" /* webpackChunkName: "component---src-pages-wedo-hair-quiz-tsx" */),
  "component---src-templates-ingredient-ingredient-js": () => import("./../../../src/templates/Ingredient/Ingredient.js" /* webpackChunkName: "component---src-templates-ingredient-ingredient-js" */),
  "component---src-templates-product-list-product-list-js": () => import("./../../../src/templates/ProductList/ProductList.js" /* webpackChunkName: "component---src-templates-product-list-product-list-js" */),
  "component---src-templates-product-page-product-page-js": () => import("./../../../src/templates/ProductPage/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-product-page-js" */)
}

