import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'
import SalonFinderWidget from 'src/components/SalonFinder'

import useTracking from 'src/hooks/useTracking'
import { createPageData } from 'src/utils/tracking'
import { getMetaTitle } from 'src/utils/shared'

const SalonFinder = ({ pageContext, data }) => {
  const { locale } = useIntl()

  const {
    nodePage: { title, field_breadcrumb: breadcrumbs, field_metatag_normalized: metaTags }
  } = data

  useTracking({
    data: createPageData({
      pageId: 'findSalon',
      breadCrumbs: `home:findsalon`,
      pageType: 'findsalon page',
      instanceName: 'findsalon page',
      primaryCategory: 'findsalon',
      language: locale,
      pageTitle: getMetaTitle(metaTags),
      pageName: pageContext.slug
    })
  })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
        <div className="columns">
          <h1 className="column is-3-tablet is-2-widescreen title__page">{title}</h1>
        </div>
      </Hero>

      <SalonFinderWidget locale={locale} />
    </Layout>
  )
}

export default SalonFinder

SalonFinder.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    nodePage: PropTypes.shape({
      title: PropTypes.string,
      field_breadcrumb: PropTypes.arrayOf(PropTypes.object),
      field_metatag_normalized: PropTypes.arrayOf(PropTypes.object)
    })
  })
}

export const query = graphql`
  query SalonFinderPage($slug: String!, $langKey: String) {
    nodePage(path: { alias: { eq: $slug } }, field_component: { eq: "find-salon" }, langcode: { eq: $langKey }) {
      ...PageDataFragment
    }
  }
`
