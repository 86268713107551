import React, { useCallback, forwardRef, LegacyRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import carouselClickEvent from 'src/dataLayer/Carousel'
import { getDirectedArrow } from './utils'

import * as S from './styled'

import { ArrowProps } from './types'

import { StyledIcon } from 'src/themes'

const Arrow = (
  { direction = 'left', className, onClick, useTracking, currentSlide, navLocation, productGalleryNav }: ArrowProps,
  ref: LegacyRef<HTMLSpanElement>
) => {
  const {
    file: { publicURL: iconsPath }
  }: {
    file: {
      publicURL: string
    }
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "sign-icons.svg" }) {
          publicURL
        }
      }
    `
  )

  const handleClick = useCallback(
    (e) => {
      if (onClick) onClick(e)

      if (useTracking) {
        carouselClickEvent({
          navLocation,
          next: direction === 'right' || direction === 'down',
          prev: direction === 'left' || direction === 'up',
          clicked: 'Button',
          sliderNum: currentSlide
        })
      }
    },
    [currentSlide, direction, navLocation, onClick, useTracking]
  )

  return (
    <span ref={ref} css={productGalleryNav && S.navButtonWrapper} className={className} onClick={handleClick}>
      <StyledIcon css={S.navButtonStyles({ direction, productGalleryNav })}>
        <use xlinkHref={`${iconsPath}#${getDirectedArrow(direction)}`} />
      </StyledIcon>
    </span>
  )
}

export default forwardRef<HTMLSpanElement, ArrowProps>(Arrow)
