import React, { Fragment, useReducer } from 'react'
import PropTypes from 'prop-types'

import Hero from 'src/components/Hero'
import radioIcons from 'src/assets/images/radio-icons.svg'
import IconWrapper from 'src/components/Icons/IconWrapper'

import { iconSvgStyles, iconUseStyles, weDoHero } from 'src/themes'

const ALL_ICONS = {
  'dermatologically-tested': ['icon-hands-active', 'icon-hands-inactive'],
  'minimalist-formula': ['icon-natural-active', 'icon-natural-inactive'],
  'conscious-sourcing': ['icon-flower-active', 'icon-flower-inactive'],
  'professional-performance': ['icon-professional-active', 'icon-professional-inactive'],
  recyclable: ['icon-recycle-active', 'icon-recycle-inactive']
}

const INTL_TAG = 'product_page.icons'

const setIconType = (state, { type, desc }) => ({
  ...state,
  iconName: type,
  iconDesc: desc
})

const reducer = {
  'dermatologically-tested': setIconType,
  'minimalist-formula': setIconType,
  'conscious-sourcing': setIconType,
  'professional-performance': setIconType,
  recyclable: setIconType
}

const iconSize = {
  bunny: '8rem',
  vegan: '9em'
}

// TODO(?): move this func to utils/shared
// if more useReducers will occur
const reducerHandler = (state, action) =>
  action.type && reducer[action.type] ? reducer[action.type](state, action) : state

const Icons = ({ productIcons, intl }) => {
  const [{ iconName, iconDesc }, dispatch] = useReducer(reducerHandler, {
    iconName: productIcons[0]?.field_i_icon_name,
    iconDesc: productIcons[0]?.field_i_text?.value
  })

  return (
    <Fragment>
      <Hero hero="has-margin-top-2 has-margin-bottom-6" heroBody="has-padding-vertical-0">
        <div className="columns has-margin-bottom-2">
          <div className="column has-padding-horizontal-0 has-text-centered is-flex is-wrapped justify-center product-icons">
            {productIcons.map(({ field_i_icon_name: type, field_i_text }) => {
              const Icon = ALL_ICONS[type]

              if (Icon) {
                return (
                  <div>
                    <IconWrapper
                      key={type}
                      className="has-margin-horizontal-0__mobile has-margin-right-1__mobile has-margin-horizontal-2__nonmobile radio-icon-wrapper"
                      onClick={() => dispatch({ type, desc: field_i_text?.value })}
                      isActive={iconName === type}
                      icons={radioIcons}
                      iconId={Icon[0]}
                      iconIdInactive={Icon[1]}
                      iconSize="5rem"
                      isRadio
                    />
                  </div>
                )
              }
            })}
          </div>
        </div>
        <div className="columns">
          <div className="column icon-tabs">
            <div className="icon-tab has-text-centered">
              <h5 className="is-capitalized is-size-5 has-text-weight-bold has-margin-bottom-2">
                {intl.formatMessage({ id: `${INTL_TAG}.${iconName}.title` })}
              </h5>
              <div dangerouslySetInnerHTML={{ __html: iconDesc }} />
            </div>
          </div>
        </div>
      </Hero>

      <Hero
        customCSS={weDoHero}
        hero="wedo-we-do has-margin-top-4 has-margin-bottom-4 product-link-icons"
        heroBody="has-padding-vertical-0"
      >
        <div className="columns">
          <div className="column has-text-centered is-flex justify-center">
            <a
              href="/leaping-bunny/"
              target="_blank"
              rel="noopener noreferrer"
              className="has-margin-horizontal-1__mobile has-margin-horizontal-3__nonmobile"
            >
              <svg css={iconSvgStyles(iconSize.bunny)}>
                <use css={iconUseStyles(iconSize.bunny)} xlinkHref={`${radioIcons}#icon-bunny`} />
              </svg>
            </a>
            <a
              href="https://www.vegansociety.com/your-business/about-vegan-trademark"
              target="_blank"
              rel="noopener noreferrer"
              className="has-margin-horizontal-1__mobile has-margin-horizontal-3__nonmobile"
            >
              <svg css={iconSvgStyles(iconSize.vegan)}>
                <use css={iconUseStyles(iconSize.vegan)} xlinkHref={`${radioIcons}#icon-vegan`} />
              </svg>
            </a>
          </div>
        </div>
      </Hero>
    </Fragment>
  )
}

export default Icons

Icons.whyDidYouRender = {
  logOnDifferentValues: true
}

Icons.propTypes = {
  productIcons: PropTypes.arrayOf(
    PropTypes.shape({
      field_i_icon_name: PropTypes.string,
      field_i_text: PropTypes.shape({
        value: PropTypes.string
      })
    })
  ),
  intl: PropTypes.object.isRequired
}
