import React, { useState, FC } from 'react'

import Answer from './Answer'

import { getAnswerImage, isSimpleVariant } from '../utils'

import * as S from './styles/question'

import { ControlButtonsActions } from '../types'
import { Choices, QuestionProps, HandleAnswer } from './types/question'

export const INTL_TAG = 'quiz.questions'

const Question: FC<QuestionProps> = ({
  id,
  text,
  answers,
  numberOfChoices,
  quizImages,
  hint,
  submessage,
  withoutImages,
  selectedAnswers,
  intl,
  questionNumber,
  updateState,
  isQuestionLast
}) => {
  const [choices, setChoices] = useState<Choices>(selectedAnswers[id - 1] || [])

  const handleAnswer = ({ numberOfChoices, choiceId, incompatibleWith }: HandleAnswer) => {
    const arr = [...choices]
    const index = arr.indexOf(choiceId)

    // Uncheck the choice if currently checked
    if (index >= 0) {
      arr.splice(index, 1)

      setChoices(arr)
      return
    }

    const incompatibileElement = arr.indexOf(incompatibleWith)

    if (incompatibileElement >= 0) {
      arr.splice(incompatibileElement, 1, choiceId)
      setChoices(arr)
      return
    }

    if (choices.length >= numberOfChoices) {
      arr.pop()
      setChoices([...arr, choiceId])
      return
    }

    setChoices([...arr, choiceId])
  }

  const handleButtonClick = (action: ControlButtonsActions) => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })

    if (action === 'PREVIOUS_QUESTION') {
      updateState({ action })
      return
    }

    updateState({ action, answer: choices })
  }

  return (
    <>
      {questionNumber === 0 && (
        <S.MainHeader data-test="quiz-main-header" className="title">
          {intl.formatMessage({ id: `${INTL_TAG}.main_header` })}
        </S.MainHeader>
      )}
      <S.QuestionText>{text}</S.QuestionText>
      {submessage && <p>{submessage}</p>}
      <S.AnswersWrapper simpleVariant={isSimpleVariant(answers?.length)} withoutImages={withoutImages}>
        {answers?.map(({ image, id, ...rest }) => (
          <Answer
            key={id}
            image={getAnswerImage({ quizImages, imgName: image })}
            numberOfChoices={numberOfChoices}
            handleAnswer={handleAnswer}
            activeChoices={choices}
            id={id}
            simpleVariant={isSimpleVariant(answers?.length)}
            {...rest}
          />
        ))}
      </S.AnswersWrapper>
      {hint && <S.Hint>{hint}</S.Hint>}
      <S.ControlButton
        disabled={questionNumber <= 0}
        className="button"
        onClick={() => handleButtonClick('PREVIOUS_QUESTION')}
        data-test="quiz-previous-button"
      >
        {intl.formatMessage({ id: `${INTL_TAG}.previous_button` })}
      </S.ControlButton>
      <S.ControlButton
        disabled={!choices.length}
        primary
        className="button"
        onClick={() => handleButtonClick(isQuestionLast ? 'SHOW_RESULT' : 'NEXT_QUESTION')}
        data-test="quiz-next-button"
      >
        {intl.formatMessage({ id: `${INTL_TAG}.next_button` })}
      </S.ControlButton>
    </>
  )
}

export default Question
