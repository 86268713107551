import { createPortal } from 'react-dom'
import { isTablet, isMobileOnly } from 'react-device-detect'
import { withArtDirection as createImgSource } from 'gatsby-plugin-image'

export const removeHTML = (string) => string.replace(/<\/?[^>]+(>|$)/g, '')

export const isBrowser = typeof window !== 'undefined'

export const mergeObjects = (target = {}, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object) Object.assign(source[key], mergeObjects(target[key], source[key]))
  }

  // Join `target` and modified `source`
  Object.assign(target, source)
  return target
}

export const getPercentage = (val, total) => parseInt((100 * val) / total)

export const fetchPost = ({ url, body }) =>
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).then((response) => response.json())

export const getDevice = () => {
  if (isMobileOnly) return 'mobile'
  else if (isTablet) return 'tablet'

  return 'web'
}

export const resolvePath = (object, path, defaultValue) =>
  path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object)

export const mapObjectsToArray = (key, arr) => arr.map((a) => resolvePath(a, key))

export const createDefaultPortal = (element, container = document.getElementById('gatsby-focus-wrapper')) =>
  createPortal(element, container)

export const getMetaTitle = (field_metatag_normalized) =>
  field_metatag_normalized?.find(({ attributes }) => attributes.name === 'title')?.attributes?.content

export const findByTypeName = ({ arr, typeName }) => arr?.find((obj) => obj?.__typename === typeName) || {}
export const filterByTypeName = ({ arr, typeName }) => arr?.filter((obj) => obj?.__typename === typeName) || []

export const toLowerKebabCase = (string) => string.trim().replace(/\s+/g, '-').toLowerCase()

export const trimByChar = (string, character) => {
  const first = [...string].findIndex((char) => char !== character)
  const last = [...string].reverse().findIndex((char) => char !== character)
  return string.substring(first, string.length - last)
}

export const filterObjectsByTitle = (array) =>
  array.filter((obj, objInd, arr) => arr.findIndex((t) => t.title === obj.title) === objInd)

// todo?: should we display english images on other lang pages?
export const findImgByLang = ({ images, intl, extension = '.jpg' }) =>
  images.find((obj) =>
    obj?.childImageSharp?.gatsbyImageData?.images?.fallback?.src.endsWith(intl.locale + extension)
  ) || images.find((obj) => obj?.childImageSharp?.gatsbyImageData?.images?.fallback?.src.endsWith('en' + extension))

export const getApiHref = ({ href, hreflang, siteUrl = process.env.GATSBY_DRUPAL_HOST }) =>
  hreflang === 'en' ? siteUrl + href : siteUrl + `/${hreflang}` + href

export const getQueryParamValue = (name) => {
  if (isBrowser) {
    const params = new URLSearchParams(window.location.search.substring(1))

    return params.get(name)
  }
}

export const setQueryParamValue = ({ name, value }) => {
  if (isBrowser && history.pushState && name && value) {
    const params = new URLSearchParams(window.location.search)
    params.set(name, value)
    const url = `${window.location.protocol}//${window.location.host + window.location.pathname}?${params.toString()}`

    window.history.pushState({ path: url }, '', url)
  }
}

export const getSortedData = (dataNodes) => {
  return dataNodes.reduce((acc, category) => {
    const linkElements = category.link.uri_alias.split('/')

    if (linkElements.length <= 3) {
      return [...acc, category]
    } else {
      const found = acc.find((el) => el.link.uri_alias.includes(linkElements[1]))
      const idx = acc.indexOf(found)

      if (found) {
        acc[idx] = {
          ...acc[idx],
          children: acc[idx].children ? [...acc[idx].children, category] : [category]
        }
      }
    }
    return acc
  }, [])
}

export const getDistinguishedData = (sortedData) => {
  const withSubs = []
  const noSubs = []

  sortedData.forEach((category) => {
    category?.children?.length ? withSubs.push(category) : noSubs.push(category)
  })

  return {
    withSubs,
    noSubs
  }
}

// eslint-disable-next-line react-hooks/rules-of-hooks
export const getArtDirection = (images) =>
  createImgSource(images[1], [
    {
      media: '(max-width: 768px)',
      image: images[0]
    },
    {
      media: '(min-width: 769px)',
      image: images[1]
    }
  ])

// todo: check logic
export const getLocalizationCode = (code) => {
  switch (code) {
    case 'el-gr':
      return 'gr'
    case 'en-au':
      return 'au'
    case 'en-gb':
      return 'gb'
    default:
      return code.split('-')[0]
  }
}

export const isRatioDefault = (imageData = {}) => {
  const { width, height } = imageData

  return {
    image: imageData,
    width,
    height
  }
}

export const formImageBaseData = ({ aspectRatio, sizes, image }) => {
  if (!image) return

  const { alt, localFile } = image

  if (!localFile) return

  return {
    alt: alt,
    ...(!aspectRatio || aspectRatio === '1'
      ? isRatioDefault(localFile?.childImageSharp?.gatsbyImageData)
      : isRatioDefault(localFile?.childImageSharp?.customRatio)),
    ...(sizes && { sizes })
  }
}

export const selectImageData = ({ desktopImageData, mobileImageData }) => mobileImageData || desktopImageData

export const isMobileImageProvided = ({ desktopImageData, mobileImageData }) =>
  mobileImageData ? mobileImageData : desktopImageData

export const truncateParagraph = (str, maxChar = 300, suffix = '...') => {
  const wordsMatch = new RegExp(`^.{0,${maxChar}}\\s`, 's')
  const regexedValue = str.match(wordsMatch)

  if (!regexedValue) {
    return ''
  }

  return `${regexedValue[0].trim()}${suffix}`
}

export const applyIconStyles = ({ isRadio, iconSize }) => (style) => (isRadio ? style(iconSize) : '')

export const buildFontPreloadData = (
  array,
  { rel = 'preload', as = 'font', type = 'font/woff2', crossOrigin = 'anonymous' } = {}
) =>
  array.map((font) => ({
    rel,
    as,
    type,
    crossOrigin,
    href: font
  }))

export const shuffleElements = ({ arr = [], elementsToShuffle = 0 }) => {
  let element = elementsToShuffle > arr.length ? arr.length : elementsToShuffle,
    randomNum,
    temp

  while (element--) {
    randomNum = Math.floor(Math.random() * arr.length)

    temp = arr[element]
    arr[element] = arr[randomNum]
    arr[randomNum] = temp
  }

  return arr
}

export const getImageCarouselKey = (image) => image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback.src

export const buildPageUrlWithLocale = (locale) => process.env.GATSBY_SITE_URL + `/${locale}`

export const getPageUrl = ({ href, hreflang, connectedHreflang }) =>
  buildPageUrlWithLocale(connectedHreflang || hreflang) + href

export const findLocalePageUrlData = (lang, pageUrls) => pageUrls.find(({ hreflang }) => hreflang === lang)

export const getLocalSharpImage = (resource) =>
  resource?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src

export const getImageForProductVariant = (relationship) =>
  getLocalSharpImage(relationship.field_p_variant?.[0]?.relationships?.field_pv_image?.[0])

export const removeSpecialChars = (string) => string?.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()

export const getLanguageByLocale = (locale) => {
  switch (locale) {
    case 'el-gr':
      return 'Greek'
    case 'es-es':
      return 'Spanish'
    case 'de-de':
      return 'German'
    default:
      return 'English'
  }
}

export const findProductImage = (images) => {
  return images?.find((obj) => obj?.field_pv_default) || images[0]
}

export const setMetaTags = ({ title, description, keywords }) => {
  const metaTags = []

  title &&
    metaTags.push({
      attributes: {
        content: title,
        name: 'title'
      }
    })

  description &&
    metaTags.push({
      attributes: {
        content: description,
        name: 'description'
      }
    })

  keywords &&
    metaTags.push({
      attributes: {
        content: keywords,
        name: 'keywords'
      }
    })

  return metaTags
}

export const setPageUrls = ({ langs, href }) => langs.map((hreflang) => ({ href, hreflang }))

export const addConnectedUrls = ({ pageUrls, connections }) => {
  const result = [...pageUrls]

  connections.forEach((connectedUrls) => {
    const primaryUrl = pageUrls.find(({ hreflang }) => hreflang === connectedUrls[0])

    if (primaryUrl) {
      result.push({ ...primaryUrl, hreflang: connectedUrls[1], connectedHreflang: primaryUrl.hreflang })
    }
  })

  return result
}
