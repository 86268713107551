import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'
import CategoryCard from 'src/components/CategoryCard'

import useTracking from 'src/hooks/useTracking'
import { createContentPageDigitalData, createPageData } from 'src/utils/tracking'
import { getMetaTitle } from 'src/utils/shared'
import _get from 'lodash.get'

import { CategoryCardColumn } from 'src/themes'

import { OurStoryProps } from './types'

const OurStory: FC<OurStoryProps> = ({ pageContext, data }) => {
  const intl = useIntl()

  const {
    nodePage: {
      title,
      body: { value: description },
      field_breadcrumb: breadcrumbs,
      relationships,
      field_metatag_normalized: metaTags
    }
  } = data

  const categories = _get(relationships, 'field_sections[0].relationships.field_r_related_page')

  useTracking({
    data: createContentPageDigitalData({
      contentType: 'our-story',
      contentTitle: title,

      pageData: createPageData({
        pageId: title,
        breadCrumbs: `home:our-story`,
        pageType: 'our story',
        instanceName: 'our story',
        primaryCategory: 'our story',
        language: intl.locale,
        pageTitle: getMetaTitle(metaTags),
        pageName: pageContext.slug
      })
    })
  })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
        <div className="columns has-margin-bottom-2">
          <h1 className="column is-3-tablet is-2-widescreen title__page">{title}</h1>
          <div className="column is-half-widescreen" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className="columns is-multiline">
          {categories?.map(({ title, id, path, relationships, field_p_image }) => (
            <CategoryCardColumn className="column is-half-tablet is-one-quarter-widescreen" key={id}>
              <CategoryCard
                isClickable
                title={title}
                buttonText={intl.formatMessage({ id: 'shared.explore' })}
                buttonUri={path.alias}
                image={relationships?.field_p_image}
                alt={field_p_image?.alt}
              />
            </CategoryCardColumn>
          ))}
        </div>
      </Hero>
    </Layout>
  )
}

export default OurStory

export const query = graphql`
  query OurStory($slug: String!, $langKey: String!) {
    nodePage(field_component: { eq: "our-story" }, path: { alias: { eq: $slug } }, langcode: { eq: $langKey }) {
      ...PageDataFragment

      relationships {
        field_sections {
          ... on paragraph__related_pages {
            relationships {
              field_r_related_page {
                title
                id
                body {
                  value
                }
                path {
                  alias
                }
                field_p_image {
                  alt
                }
                relationships {
                  field_p_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          quality: 50
                          webpOptions: { quality: 55 }
                          formats: [JPG, WEBP]
                          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
