import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { GatsbyImage } from 'gatsby-plugin-image'

import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'
import Layout from 'src/layouts'

import useTracking from 'src/hooks/useTracking'
import { createPageData } from 'src/utils/tracking'
import { getMetaTitle } from 'src/utils/shared'

import Form from './sections/Form'
import { formImageBaseData } from 'src/utils/shared'

import * as S from './styles/styled'
import { formImageStyles } from 'src/themes'

import { ContactUsProps } from './types'

const ContactUs: FC<ContactUsProps> = ({ pageContext, data }) => {
  const intl = useIntl()

  const {
    nodePage: {
      field_breadcrumb: breadcrumbs,
      field_metatag_normalized: metaTags,
      title,
      body: { value: desc }
    },
    countriesList,
    contactImgLocalFile,
    contactImgPopupLocalFile
  } = data

  const contactImage = {
    localFile: contactImgLocalFile,
    alt: intl.formatMessage({ id: 'images.contact.hero' })
  }

  const contactImagePopup = {
    localFile: contactImgPopupLocalFile
  }

  useTracking({
    data: createPageData({
      pageId: 'contact',
      breadCrumbs: 'contact',
      pageType: 'contact page',
      instanceName: 'contact page',
      primaryCategory: 'contact us',
      language: intl.locale,
      pageTitle: getMetaTitle(metaTags),
      pageName: pageContext.slug
    })
  })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <div className="wedo-form-page">
        <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
          <div className="columns">
            <h1 className="column is-3-tablet is-2-widescreen title__page">{title}</h1>
            <div className="column is-5-tablet is-4-widescreen" dangerouslySetInnerHTML={{ __html: desc }} />
            <div className="column is-4 is-hidden-mobile">
              {/* todo: check if this can be single element, bcs it most likely should be */}
              <GatsbyImage css={formImageStyles} {...formImageBaseData({ image: contactImage })} />
            </div>
          </div>
        </Hero>
        <Hero hero="is-fourth" heroBody="has-padding-top-2 has-padding-bottom-2" customCSS={S.hero}>
          <div className="is-hidden-tablet">
            {/* todo: check if this can be single element, bcs it most likely should be */}
            <GatsbyImage css={formImageStyles} {...formImageBaseData({ image: contactImage })} />
          </div>
          <div className="columns is-centered">
            <div className="column is-offset-3-tablet is-offset-0-widescreen is-9-tablet is-8-widescreen">
              <div className="form" >{intl.locale !== 'ru-ru' &&
                <Form
                  intl={intl}
                  countries={countriesList?.countries}
                  language={pageContext.intl.language}
                  contactImgPopup={contactImagePopup}
                />
            }</div>
            </div>
          </div>
            <div className="infopage">{intl.locale === 'ru-ru' &&
              <div className="columns is-centered no-form">
                <div className="column is-offset-3-tablet is-offset-0-widescreen is-9-tablet is-8-widescreen">
                  <p className="contact_long-text">По вопросам приобретения продукции для мастеров и салонов,
                  сотрудничества и другим вопросам просим Вас писать
                  на электронный адрес <a href="mailto:Wella.MskStudio@wella.com">wellaprofrus@wella.com</a>.
                  (Часы работы: Пн - Пт с 9:00 до 17:00 по Московскому времени)
                      <br /><br />Если вы планируете отправить заявку на сотрудничество,
                      просим Вас сначала ознакомиться с Политикой конфиденциальности (раздел Политика приватности)
                      на нашем сайте и в заявке, по возможности, предоставить данные:
                      <br /><br />- Имя и номер телефона контактного лица;
                      <br /><br />- В каком городе Вы находитесь (если г .Москва, укажите,
                      пожалуйста, ближайшую станцию метро);
                      <br /><br />- Вы представляете салон, профессиональный магазин,
                      интернет-магазин или являетесь частным мастером?;
                      <br /><br />- Если Вы представляете салон, укажите, пожалуйста, на какое
                       количество кресел он рассчитан?;
                       <br /><br />- Kакие наши бренды Вас интересуют?</p>
                </div>
              <div className="column">
                  <h4 className="contact_title">Контактная информация студий Wella</h4>
                    <div className="contact-element">
                      <h5 className="contact-element_title">1. Студия Москва</h5>
                      <p className="contact-element_description">
                        Ул. Валовая, 35 <br />
                        Студийный электронный адрес: <a href="mailto:Wella.MskStudio@wella.com" >Wella.MskStudio@wella.com</a>;<br />
                        Телефон студии:<br />
                        M +7 968 578 30 11<br />
                        T +7 495 931 95 00
                      </p>
                    </div>
                    <div className="contact-element">
                      <h5 className="contact-element_title">2. Студия Санкт-Петербург:</h5>
                      <p className="contact-element_description">
                        Большой проспект, П.С., 84, лит. А <br />
                        Студийный электронный адрес: <a href="mailto:Wella.SpbStudio@wella.com" >Wella.SpbStudio@wella.com</a>;<br />
                        Телефон студии:<br />
                        T +7 (812) 449 09 87
                      </p>
                    </div>
                    <div className="contact-element">
                      <h5 className="contact-element_title">3. Студия Екатеринбург:</h5>
                      <p className="contact-element_description">
                        Проспект Ленина, 50Б, офис 705 <br />
                        Студийный электронный адрес: <a href="mailto:Wella.EkbStudio@wella.com" >Wella.EkbStudio@wella.com</a>;<br />
                        Телефон студии:<br />
                        T +7 (343) 311-06-91
                      </p>
                    </div>
                    <div className="contact-element">
                      <h5 className="contact-element_title">4. Студия Новосибирск:</h5>
                      <p className="contact-element_description">
                        Красный проспект, 79/1 <br />
                        Студийный электронный адрес: <a href="mailto:Wella.NskStudio@wella.com" >Wella.NskStudio@wella.com</a>;<br />
                        Телефон студии:<br />
                        T +7 383 335 82 16 /18 /19;<br />
                        M +7 963 942 40 11
                      </p>
                    </div>
                    <div className="contact-element">
                      <h5 className="contact-element_title">5. Студия Хабаровск:</h5>
                        <p className="contact-element_description">
                          Ул. Тургенева, 46 <br />
                          Студийный электронный адрес: <a href="mailto:Wella.KhbStudio@wella.com" >Wella.KhbStudio@wella.com</a>;<br />
                          Телефон студии:<br />
                          T: +7 (4212) 410 250
                        </p>
                    </div>
                  </div>
              </div>
            }
           </div>
        </Hero>
      </div>
    </Layout>
  )
}

export default ContactUs

export const query = graphql`
  query ContactUsPage($slug: String, $langKey: String) {
    nodePage(field_component: { eq: "contact" }, path: { alias: { eq: $slug } }, langcode: { eq: $langKey }) {
      ...PageDataFragment
    }
    countriesList {
      countries {
        label
        value
        id
      }
    }
    contactImgLocalFile: file(relativePath: { eq: "contact_photo_v2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 432
          quality: 50
          webpOptions: { quality: 55 }
          outputPixelDensities: [0.25, 0.5, 0.6, 0.75, 1]
          formats: [JPG, WEBP]
        )
      }
    }
    contactImgPopupLocalFile: file(relativePath: { eq: "contact_popup.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 432
          quality: 50
          webpOptions: { quality: 55 }
          outputPixelDensities: [0.25, 0.5, 0.6, 0.75, 1]
          formats: [JPG, WEBP]
        )
      }
    }
  }
`
