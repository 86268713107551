import React, { useState, FC } from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Hero from 'src/components/Hero'
import Layout from 'src/layouts'
import Quiz from 'src/components/Quiz'
import SEO from 'src/components/SEO'

import useTracking from 'src/hooks/useTracking'
import { createPageData, createContentPageDigitalData } from 'src/utils/tracking'
import { setMetaTags, setPageUrls } from 'src/utils/shared'

import { noPaddingMobile } from 'src/themes'
import { WeDoQuizProps } from 'src/components/Quiz/types'

const INTL_TAG = 'quiz.metadata'

const WeDoQuiz: FC<WeDoQuizProps> = ({ data, pageContext }) => {
  const [isFinalScreen, setFinalScreen] = useState(false)
  const intl = useIntl()
  const pageBreadcrumb = [
    pageContext?.breadcrumb?.crumbs[0],
    {
      crumbLabel: intl.formatMessage({ id: `${INTL_TAG}.breadcrumb_label` }),
      pathname: `/${intl.locale}/hair-quiz/`
    }
  ]
  const pageTitle = intl.formatMessage({ id: `${INTL_TAG}.title` })
  const pageUrls = setPageUrls({ langs: pageContext?.intl?.languages, href: pageContext?.intl?.originalPath })

  useTracking({
    data: createContentPageDigitalData({
      contentType: 'quiz',
      contentTitle: pageTitle,
      pageData: createPageData({
        pageId: pageTitle,
        breadCrumbs: `home:quiz`,
        pageType: 'quiz',
        instanceName: 'quiz',
        primaryCategory: 'quiz',
        language: intl.locale,
        pageTitle: pageTitle,
        pageName: `${intl.locale}/hair-quiz`
      })
    })
  })

  return (
    <Layout breadcrumbs={pageBreadcrumb} hideBreadcrubsOnMobile disableFooterIcons={!isFinalScreen} pageUrls={pageUrls}>
      <SEO
        pageUrls={pageUrls}
        fetchedMetaTags={setMetaTags({
          title: pageTitle,
          description: intl.formatMessage({ id: `${INTL_TAG}.description` }),
          keywords: intl.formatMessage({ id: `${INTL_TAG}.keywords` })
        })}
        lang={pageContext.language}
      />
      <Hero hero="has-text-centered is-growed" heroBody="is-flex is-flex-centered" heroBodyStyles={noPaddingMobile}>
        <Quiz setFinalScreen={setFinalScreen} data={data} intl={intl} />
      </Hero>
    </Layout>
  )
}

export default WeDoQuiz

export const query = graphql`
  query WeDoQuiz(
    $langKey: String!
    $productVariantHeight: Int! = 483
    $productVariantWidth: Int! = 364
    $productVariantQuality: Int! = 80
    $isRelatedProductsIngredient: Boolean! = false
    $includeProductCategoryDrupalId: Boolean! = true
  ) {
    allNodeProduct(filter: { langcode: { eq: $langKey } }, sort: { fields: drupal_id }) {
      ...RelatedProductsFragment
    }
    # QUIZ IMAGE MOBILE
    startScreenImgMobile: file(relativePath: { eq: "quiz-start-screen--mobile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 75
          width: 769
          webpOptions: { quality: 78 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    # QUIZ IMAGE DESKTOP
    startScreenImgDesktop: file(relativePath: { eq: "quiz-start-screen--desktop.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 80
          width: 1920
          webpOptions: { quality: 82 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }

    # NEWSLETTER BANNER MOBILE
    newsletterMobile: file(relativePath: { eq: "banner-newsletter--mobile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 75
          width: 769
          webpOptions: { quality: 78 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    # NEWSLETTER BANNER DESKTOP
    newsletterDesktop: file(relativePath: { eq: "banner-newsletter--desktop.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 80
          width: 1920
          webpOptions: { quality: 82 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }

    quizImages: allFile(filter: { relativePath: { regex: "/quiz//" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 80
            width: 300
            webpOptions: { quality: 82 }
            formats: [AUTO, WEBP]
            outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
          )
        }
      }
    }
  }
`
