import { IntlShape } from 'gatsby-plugin-intl'
import { AnswerData, QuestionData } from '../types'

const answers4 = (intl: IntlShape, INTL_TAG: string): AnswerData[] => [
  {
    questionId: 4,
    id: 'a4',
    text: intl.formatMessage({ id: `${INTL_TAG}.wishes.answers.smoother` })
  },
  {
    questionId: 4,
    id: 'b4',
    text: intl.formatMessage({ id: `${INTL_TAG}.wishes.answers.shine` })
  },
  {
    questionId: 4,
    id: 'c4',
    text: intl.formatMessage({ id: `${INTL_TAG}.wishes.answers.volume` })
  },
  {
    questionId: 4,
    id: 'd4',
    text: intl.formatMessage({ id: `${INTL_TAG}.wishes.answers.less_oily` })
  },
  {
    questionId: 4,
    id: 'e4',
    text: intl.formatMessage({ id: `${INTL_TAG}.wishes.answers.detangle` })
  },
  {
    questionId: 4,
    id: 'f4',
    text: intl.formatMessage({ id: `${INTL_TAG}.wishes.answers.curl` })
  },
  {
    questionId: 4,
    id: 'g4',
    text: intl.formatMessage({ id: `${INTL_TAG}.wishes.answers.less_frizz` })
  },
  {
    questionId: 4,
    id: 'h4',
    text: intl.formatMessage({ id: `${INTL_TAG}.wishes.answers.moisturized` })
  },
  {
    questionId: 4,
    id: 'i4',
    text: intl.formatMessage({ id: `${INTL_TAG}.wishes.answers.repaired` })
  },
  {
    questionId: 4,
    id: 'j4',
    text: intl.formatMessage({ id: `${INTL_TAG}.wishes.answers.less_split` })
  },
  {
    questionId: 4,
    id: 'k4',
    text: intl.formatMessage({ id: `${INTL_TAG}.wishes.answers.hydration` })
  }
]

export default (intl: IntlShape, INTL_TAG: string): QuestionData => ({
  id: 4,
  text: intl.formatMessage({ id: `${INTL_TAG}.wishes.question` }),
  submessage: intl.formatMessage({ id: `${INTL_TAG}.wishes.submessage` }),
  answers: answers4(intl, INTL_TAG),
  numberOfChoices: 2,
  withoutImages: true
})
