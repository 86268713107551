import React, { FC } from 'react'
//TO DO: Animation on hide (if possible) - add Class and use https://reactjs.org/docs/events.html#animation-events before change state

import { ModalProps } from './types'

import * as S from './styled'

const Modal: FC<ModalProps> = ({ children, onClick, className = '', contentCSS }) => {
  return (
    <div className={`modal is-active ${className}`}>
      <div className="modal-background fade-in" onClick={onClick} css={S.background} />
      <div css={contentCSS} className="modal-content slide-in">
        {children}
      </div>
      <button className="modal-close is-large fade-in" onClick={onClick} aria-label="close" />
    </div>
  )
}

export default Modal
