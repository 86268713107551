import React, { useCallback, FC } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import carouselClickEvent from 'src/dataLayer/Carousel'

import { ProductPaginationProps } from './types'

import { playButtonStyles } from 'src/themes'

const ProductPagination: FC<ProductPaginationProps> = ({ index, onClick, productImage, navLocation, PhotoDot }) => {
  const handleClick = useCallback(
    (e) => {
      if (onClick) onClick(e)

      carouselClickEvent({
        navLocation,
        clicked: 'paginationbutton',
        sliderNum: index
      })
    },
    [index, onClick, navLocation]
  )

  const {
    file: { publicURL: iconsPath }
  }: {
    file: { publicURL: string }
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "sign-icons.svg" }) {
          publicURL
        }
      }
    `
  )

  return (
    <PhotoDot onClick={handleClick}>
      <GatsbyImage
        className="pagination-dot-img"
        image={productImage?.localFile?.childImageSharp?.dotImage}
        alt={productImage?.alt}
      />
      {productImage.isVideo && (
        <svg css={playButtonStyles({ isDot: true })}>
          <use xlinkHref={`${iconsPath}#play-circle-solid`} />
        </svg>
      )}
    </PhotoDot>
  )
}

export default ProductPagination
