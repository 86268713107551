import packageJson from '../../../package.json'
import { getDevice, removeHTML, trimByChar, isBrowser } from '../shared'
import dispatch from './dispatch'

export const createPageData = ({
  instanceName,
  pageId,
  pageType,
  breadCrumbs,
  primaryCategory,
  subCategory1,
  subCategory2,
  language,
  currencyCode,
  errorPage,
  pageTitle,
  pageName
}) => {
  if (typeof document !== 'undefined') {
    const shortLang = language.includes('-') ? language.split('-')[0] : language
    const country = language === 'en-au' ? language.split('-')[1] : shortLang

    const prevPath =
      window.previousPath && window.previousPath !== window.location.href ? window.previousPath : document.referrer

    return {
      pageInstanceId: `${instanceName} - ${process.env.GATSBY_ENV_TYPE}`,
      version: packageJson?.version,
      page: {
        pageInfo: {
          pageId,
          breadCrumbs: breadCrumbs.toLowerCase(),
          pageName,
          pageURL: location.href,
          referrer: prevPath && new URL(prevPath).hostname,
          referringURL: prevPath,
          pageTitle
        },
        category: {
          pageType,
          instanceName,
          primaryCategory,
          subCategory1,
          subCategory2
        },
        attributes: {
          platform: getDevice(),
          errorPage,
          domain: location.host,
          pageHierarchy: `${country.toUpperCase()}|${language}|${breadCrumbs.replace(/:/g, '|').toLowerCase()}`,
          language: shortLang,
          country: country.toUpperCase(),
          currencyCode
        }
      }
    }
  }
}

export const pageLoaded = (eventInfo) => {
  const eventName = 'pageLoaded'

  dispatch({
    eventName,
    detail: {
      eventAction: 'page load',
      eventName,
      eventType: 'userEvent',
      eventInfo
    }
  })
}

export const pageView = (eventInfo) => {
  const eventName = 'pageViewed'

  dispatch({
    eventName,
    detail: {
      eventAction: 'page viewed',
      eventName,
      eventType: 'userEvent',
      eventInfo
    }
  })
}

export const createProductDigitalData = ({
  descFull,
  descShort,
  sku,
  currency,
  rating,
  primaryCategory,
  subCategory1,
  type,
  retailersInfo,
  packsize,
  shade,
  productImage,
  name,
  pageData
}) => {
  if (typeof document !== 'undefined') {
    return {
      ...pageData,
      product: {
        productEvent: 'prodview',
        sku,
        currency,
        rating,
        category: {
          primaryCategory,
          subCategory1
        },
        productInfo: {
          name,
          description_short: descShort && removeHTML(descShort),
          description_full: descFull && removeHTML(descFull)
        },
        retailersInfo,
        attributes: {
          productUrl: location.href,
          variant: {
            packsize,
            shade,
            type
          },
          productImage
        }
      }
    }
  }
}

export const createContentPageDigitalData = ({ contentType, contentTitle, pageData }) => {
  if (typeof document !== 'undefined') {
    return {
      ...pageData,
      contentData: {
        contentType,
        contentTitle,
        contentUrl: location.href
      }
    }
  }
}

export const getCategory = (str) => {
  const baseCategories = ['shampoo', 'conditioner', 'mask', 'cosmetics']

  return baseCategories.find((category) => str.toLowerCase().search(category) !== -1)
}

export const createDataBasedOnLocation = (lang) => {
  let name
  const pathname = isBrowser ? window.location.pathname : '/'
  const crumbs = trimByChar(pathname, '/').split('/')
  const standaloneCategories = ['our-story', 'contact-us', 'subscribe']
  const categories = ['products', ...standaloneCategories]
  const createData = (name) => ({
    pageId: name,
    breadCrumbs: `${name === 'product' || name === 'product listing' ? 'home:' : ''}${pathname
      .slice(1, pathname.length)
      .replace(/\//g, ':')}`,
    pageType: `${name} page`,
    instanceName: `${name} page`,
    primaryCategory: `${name !== 'home' ? name : 'homepage'}`,
    language: lang,
    ...(name === 'product' ? { subCategory1: crumbs[2].replace(/-/g, ' ') } : {})
  })

  const category = categories.filter((obj) => crumbs.indexOf(obj.toLowerCase()) != -1)[0]

  if (category) {
    if (standaloneCategories.indexOf(category) !== -1) {
      let currName = category

      if (category === 'our-story') currName = 'content'
      if (category === 'contact-us') currName = 'contact'

      name = currName
    } else {
      name = crumbs.length > 3 ? 'product' : 'product listing'
    }
  } else name = 'home'

  return createData(name)
}
