import { css, SerializedStyles } from '@emotion/react'

import { mq } from 'src/themes'

export const parallax = css`
  @media (max-width: 330px) {
    height: 240px;
  }
  ${mq({
    height: [340, 400, undefined, '50vh']
  })}

  .react-parallax-background-children,
  .react-parallax-background {
    width: 100%;

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }
`

export const gatsbyImage = (translate: number): SerializedStyles => css`
  position: initial;
  max-width: 100%;
  max-height: 100%;

  & > div {
    ${mq({
      paddingBottom: ['100%', '15%', 0]
    })}
  }

  ${translate &&
  css`
    transform: translateY(${translate}px);
  `}
`
