import React, { useCallback, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Modal from 'src/components/Modal'
import Video from 'src/components/Video'

import * as S from '../../styles/banner'
import { StyledIcon } from 'src/themes'

const INTL_TAG = 'homepage.banner'

const VIDEO_CONFIG = {
  youtube: {
    playerVars: {
      mute: 1,
      autoPlay: 1,
      loop: 1,
      controls: 0,
      showInfo: 0,
      autoHide: 0
    },
    preload: false
  }
}

const VIDEO_MODAL_CONFIG = {
  youtube: {
    playerVars: {
      autoPlay: 1,
      controls: 1
    },
    preload: false
  }
}

const getModalVideoUrl = (lang) => {
  switch (lang) {
    case 'de-de':
      return 'https://www.youtube.com/watch?v=LvNF2fnwpYM'
    case 'el-gr':
      return 'https://www.youtube.com/watch?v=i5zzAkuyrE8&list=PLpCx2lLYo5xbkI5Tyyz3vvuHehEoIsu7K&index=2&t=0s'
    default:
      return 'https://www.youtube.com/watch?v=4OdPtrvIz-0'
  }
}

const getBgVideoUrl = (lang) => {
  switch (lang) {
    case 'de-de':
      return 'https://www.youtube.com/watch?v=9NUZGuB1L6U'
    case 'el-gr':
      return 'https://www.youtube.com/watch?v=9NUZGuB1L6U'
    default:
      return 'https://www.youtube.com/watch?v=9NUZGuB1L6U'
  }
}

// TODO?: THIS WHOLE FILE MIGHT BE REDUNDANT
const BannerDefault = ({ intl, onScrollClick, showScroll }) => {
  const [isModalOpen, toggleModal] = useState(false)
  const [hasVideoStarted, setVideoStarted] = useState(false)
  const {
    file: { publicURL: iconsPath }
  } = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "sign-icons.svg" }) {
          publicURL
        }
      }
    `
  )

  const handleToggleModal = useCallback(() => {
    toggleModal(!isModalOpen)
    document.documentElement.classList.toggle('is-clipped')
  }, [isModalOpen])

  const handleVideoStart = useCallback(() => {
    setVideoStarted(true)
  }, [])

  return (
    <section className="hero homepage-banner">
      <Video
        url={getBgVideoUrl(intl.locale)}
        loop
        playing
        volume={0}
        onStart={handleVideoStart}
        className={`video video-responsive video-background ${!hasVideoStarted ? 'is-loading' : ''}`}
        config={VIDEO_CONFIG}
        disableTracking
      />
      <div className="hero-body">
        <p className="has-text-centered">
          <span className="button is-size-5 has-text-white is-transparent" onClick={handleToggleModal}>
            <S.PlayIconWrapper className="icon">
              <StyledIcon css={S.playIconStyles}>
                <use xlinkHref={`${iconsPath}#play-circle`} />
              </StyledIcon>
            </S.PlayIconWrapper>
            <span>{intl.formatMessage({ id: `${INTL_TAG}.play_video` })}</span>
          </span>
        </p>
      </div>
      {isModalOpen && (
        <Modal onClick={handleToggleModal}>
          <Video
            url={getModalVideoUrl(intl.locale)}
            config={VIDEO_MODAL_CONFIG}
            playing
            className="video video-responsive"
            css={S.modalStyle}
          />
        </Modal>
      )}
      {showScroll && <S.ScrollDown className="is-hidden-mobile" onClick={onScrollClick} />}
    </section>
  )
}

export default BannerDefault

BannerDefault.whyDidYouRender = {
  logOnDifferentValues: true
}

BannerDefault.propTypes = {
  intl: PropTypes.object,
  onScrollClick: PropTypes.func.isRequired,
  showScroll: PropTypes.bool
}
