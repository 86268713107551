import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { colors, mq, backgrounds, breakpoints, frame } from 'src/themes'

const textShadow = css`
  text-shadow: 0 0 2px ${backgrounds.blackAlphaHigh};
`

export const backgroundStyles = (image) => ({
  ...bgImgStyle(image),
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'
})

export const DescriptionTop = styled.h2`
  ${frame({ color: colors.orange })}
  ${textShadow};
`

export const TextTop = styled.div`
  ${textShadow}
`

export const DescriptionBody = styled.h2`
  ${frame({ color: colors.orange })}
  ${mq({
    textAlign: ['right', 'initial']
  })}
`

export const KnowMore = styled.a`
  @media (max-width: ${breakpoints.tablet}) {
    height: auto;
    white-space: pre-wrap;
  }
`

export const bgImgStyle = (p3Image) => css`
  ${mq({
    backgroundImage: [
      `url(${p3Image?.field_p3_background_mobile?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src})`,
      `url(${p3Image?.field_p3_background?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src})`
    ]
  })};
`
