import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { breakpoints } from 'src/themes'

const flagSize = '1.5rem'

export const NavbarLink = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
`

export const NavbarDropdown = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    position: relative;
    display: block;
    border: none;
    box-shadow: none;
    top: inherit;
    left: inherit;
  }
`

export const FlagWrapper = styled.svg`
  width: 24px;
  height: ${flagSize};
`

export const Flag = styled.use`
  font-size: ${flagSize};
`

export const textSmall = css`
  font-size: 1.2rem;
`
