import React from 'react'
import PropTypes from 'prop-types'

import IconWrapper from 'src/components/Icons/IconWrapper'
import { Link } from 'gatsby-plugin-intl'

const INTL_TAG = 'footer.icons'

const FooterIcons = ({ intl, layoutIcons }) => {
  return (
    <div className="container icons">
      <div className="columns  is-mobile is-multiline is-centered">
        <div className="column is-one-third-mobile is-one-quarter-tablet is-auto-desktop is-multiline">
          <IconWrapper
            iconDesc={intl.formatMessage({ id: `${INTL_TAG}.cruel_free` })}
            iconId="icon-cruel-free"
            icons={layoutIcons}
          />

          <p className="has-margin-top-1 has-text-centered cruel-free-more">
            {intl.formatMessage({ id: `${INTL_TAG}.cruel_free_link` }).localeCompare(`${INTL_TAG}.cruel_free_link`) !==
              0 && (
              <Link
                to={intl.formatMessage({ id: `${INTL_TAG}.cruel_free_link` })}
                className="has-margin-top-1 has-text-primary cruel-free-link"
              >
                {intl.formatMessage({ id: `${INTL_TAG}.cruel_free_link_name` })}
                <span className="is-sr-only">{intl.formatMessage({ id: `${INTL_TAG}.cruel_free_link_sr_label` })}</span>
              </Link>
            )}
          </p>
        </div>
        <div className="column vegan-icon is-one-third-mobile is-one-quarter-tablet is-auto-desktop">
          <IconWrapper
            iconDesc={intl.formatMessage({ id: `${INTL_TAG}.vegan_society` })}
            iconId="icon-vegan"
            icons={layoutIcons}
          />
        </div>
        <div className="column is-one-third-mobile is-one-quarter-tablet is-auto-desktop">
          <IconWrapper
            iconDesc={intl.formatMessage({ id: `${INTL_TAG}.natural_origin` })}
            iconId="icon-natural-origin"
            icons={layoutIcons}
          />
        </div>
        <div className="column is-one-third-mobile is-one-quarter-tablet is-auto-desktop">
          <IconWrapper
            iconDesc={intl.formatMessage({ id: `${INTL_TAG}.sulfate_silicone` })}
            iconId="icon-sulfate-silicone"
            icons={layoutIcons}
          />
        </div>
        <div className="column is-one-third-mobile is-one-quarter-tablet is-auto-desktop">
          <IconWrapper
            iconDesc={intl.formatMessage({ id: `${INTL_TAG}.dermatologically` })}
            iconId="icon-dermatologically"
            icons={layoutIcons}
          />
        </div>
        <div className="column is-one-third-mobile is-one-quarter-tablet is-auto-desktop">
          <IconWrapper
            iconDesc={intl.formatMessage({ id: `${INTL_TAG}.recyclable` })}
            iconId="icon-recyclable"
            icons={layoutIcons}
          />
        </div>
        <div className="column is-one-third-mobile is-one-quarter-tablet is-auto-desktop">
          <IconWrapper
            iconDesc={intl.formatMessage({ id: `${INTL_TAG}.professional_performance` })}
            iconId="icon-professional-performance"
            icons={layoutIcons}
          />
        </div>
        <div className="column is-one-third-mobile is-one-quarter-tablet is-auto-desktop">
          <IconWrapper
            iconDesc={intl.formatMessage({ id: `${INTL_TAG}.fighting_ocean` })}
            iconId="icon-fighting-ocean"
            icons={layoutIcons}
          />
        </div>
      </div>
    </div>
  )
}

export default FooterIcons

FooterIcons.propTypes = {
  intl: PropTypes.object,
  layoutIcons: PropTypes.string.isRequired
}
