import facepaint from 'facepaint'

export const mq = facepaint([
  '@media(min-width: 769px)', // Tablet
  '@media(min-width: 1008px)', // Desktop
  '@media(min-width: 1200px)', // WideScreen
  '@media(min-width: 1392px)' // FullHD
])

export const media = facepaint([
  '@media(min-width: 480px)', // Mobile
  '@media(min-width: 769px)', // Tablet
  '@media(min-width: 1008px)', // Desktop
  '@media(min-width: 1200px)', // WideScreen
  '@media(min-width: 1392px)' // FullHD
])

// Fonts
export const fonts = { primary: `'Century Gothic', sans-serif` }

// Colors
export const colors = {
  orange: '#f0956f',
  green: '#74aa50',
  violet: '#b288b9',
  gold: '#b89d18',
  peach: '#edc8a3',
  pink: '#d6938a',
  white: '#ffffff',
  extraLight: '#f9f9f9',
  light: '#f7f6f4',
  semiLight: '#f5e2d0',
  medium: '#777777',
  dark: '#3b3b3b',
  red: '#c0392b'
}

export const backgrounds = {
  blackAlpha: 'rgba(0, 0, 0, 0.85)',
  blackAlphaHigh: 'rgba(0, 0, 0, 0.65)',
  blackAlphaMedium: 'rgba(0, 0, 0, 0.35)',
  blackAlphaLight: 'rgba(0, 0, 0, 0.15)'
}

// Base sizes (BULMA)
export const sizes = {
  size1: '4.25rem',
  size2: '2.5rem',
  size3: '2rem',
  size4: '1.5rem',
  size5: '1.25rem',
  size6: '1rem',
  size7: '0.875rem',
  size8: '0.75rem',
  size9: '0.625rem'
}

// Links
export const links = {
  link: colors.dark,
  linkHover: colors.orange
}

// Line heights
export const lineHeights = {
  small: 1,
  medium: 1.25,
  standard: 1.5,
  big: 1.75,
  huge: 2
}

// Letter spacing
export const letterSpacing = {
  small: '0.7px',
  standard: '1px',
  big: '1.9px'
}

// Breakpoints
export const breakpoints = {
  gap: '24px',
  mobile: '480px',
  tablet: '769px',
  desktop: '1008px',
  widescreen: '1200px',
  fullhd: '1392px'
}

// Transition
export const transition = 'all 250ms ease-out'

// z-index
export const zIndexes = {
  zIndexNegative: -1,
  zIndexDefault: 0,
  zIndex1: 1,
  zIndex5: 5,
  zIndex10: 10,
  zIndex100: 100,
  zIndexMax: 99999
}

// Footer
export const footer = {
  footerBackgroundColor: colors.white,
  footerColor: false,
  footerPadding: 0
}

// Radius and border width in buttons (BULMA)
export const buttons = {
  buttonMinWidth: '290px',
  radius: 0,
  radiusSmall: 0,
  controlBorderWidth: '1px',
  labelWeight: 400,
  boxRadius: 0,
  boxShadow: 'none',
  boxPadding: '0.75rem'
}

export const padding = {
  x1: '0.75rem',
  x2: '1.5rem',
  x3: '2.25rem'
}
