import { getApiHref } from 'src/utils/shared'

export const formatTitle = ({ title, splitBy = '|' }) => {
  if (typeof title === 'string' && splitBy) {
    return title.split(splitBy)[0]
  }

  return ''
}

export const getSearchResults = async ({ hreflang, href, query }) => {
  const apiUrl = getApiHref({ href, hreflang })

  const resp = await fetch(`${apiUrl}${query}`)
  const data = await resp.json()

  return data
}
