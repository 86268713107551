import dispatch from 'src/utils/tracking/dispatch'

const videoPlayEvent = ({ type = 'youtube', name, length, url, played, milestone, evtName }) => {
  const eventName = evtName

  const detail = {
    eventName,
    eventAction: 'Video Media Play',
    eventType: 'userEvent',
    eventInfo: {
      videoName: name,
      videoType: type,
      videoLength: length,
      videoURL: url,
      videotimePlayed: played,
      videoMilestone: milestone
    }
  }

  dispatch({
    eventName,
    detail
  })
}

export default videoPlayEvent
