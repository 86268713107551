export const RECAPTCHA_KEY = process.env.GATSBY_RECAPTCHA_KEY

/*eslint-disable */
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
/*eslint-enable */

// form request urls
export const SUBSCRIBE_FETCH_URL = `${process.env.GATSBY_GSDB_HOST}/api/v2/subscribe`
export const GET_AGREEMENTS_FETCH_URL = `${process.env.GATSBY_GSDB_HOST}/api/v2/subscribe/agreements?`
export const UNSUB_FETCH_URL = `${process.env.GATSBY_GSDB_HOST}/api/v2/unsubscribe`
export const GSDB_COUNTRIES = `${process.env.GATSBY_GSDB_HOST}/api/v2/Countries/New/en`

// supported languages

export const languages = ['de-de', 'el-gr', 'es-es', 'it-it', 'en-gb', 'en-au', 'nl-nl', 'fr-fr', 'fr-be', 'ru-ru']
export const quizMarkets = ['en-gb', 'de-de', 'el-gr', 'es-es', 'it-it', 'nl-nl', 'en-au', 'ru-ru', 'fr-fr', 'fr-be']
export const highlightedSalonFinder = ['en-au']
export const enableNaturalHairCare = ['en-gb', 'en-au', 'it-it', 'el-gr', 'de-de', 'es-es', 'nl-nl', 'fr-be', 'fr-fr', 'ru-ru']
export const disableHappyColoredSections = ['en-gb', 'de-de', 'ru-ru', 'fr-fr', 'it-it', 'es-es', 'el-gr', 'fr-be', 'nl-nl']
export const disableHappyNaturalHair = ['de-de', 'ru-ru', 'fr-fr', 'it-it', 'es-es', 'el-gr', 'fr-be', 'nl-nl']
// breakpoints for different devices
export const breakpoints = {
  mobile: 480,
  tablet: 769
}
