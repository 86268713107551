import { ProductVideo, PaginationProductImage, Direction } from './types'

export const mapVideosToDotThumbnails = (productVideos: ProductVideo[]): PaginationProductImage[] =>
  productVideos.map(({ relationships }) => ({ isVideo: true, ...relationships.field_p_video_cover }))

export const getDirectedArrow = (direction: Direction): string => {
  switch (direction) {
    case 'up':
      return 'chevron-up'
    case 'down':
      return 'chevron-down'
    case 'left':
      return 'arrow-left'
    case 'right':
      return 'arrow-right'
    default:
      return
  }
}
