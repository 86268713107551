import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby-plugin-intl'
import PropTypes from 'prop-types'
import { useIntl } from 'gatsby-plugin-intl'

import FooterIcons from './FooterIcons/FooterIcons'
import SocialMedia from './SocialMedia'
import LegalPopupDE from './LegalPopupDE'

const INTL_TAG = 'footer'

const Footer = ({ socialMediaData, exploreData, legalData, disableFooterIcons, layoutIcons }) => {
  const intl = useIntl()
  const otButtonRef = useRef(null)

  useEffect(() => {
    // OT script adds localized text only on script load to `.ot-sdk-show-settings` elements
    // and wont keep it after SPA rerender
    // this has to be replicated based on hidden element that wont rerender
    // and act as reference for rerendered element
    const globalOtButton = document.getElementById('onetrust-btn')
    const localOtButton = otButtonRef.current

    if (localOtButton && globalOtButton) {
      otButtonRef.current.textContent = globalOtButton.textContent
    }
  }, [])
  const [isLegalPopupOpen, setIsLegalPopupOpen] = useState(false);
  const toggleLegalPopup = (e) => {
    e.preventDefault();
    setIsLegalPopupOpen(!isLegalPopupOpen);
  }

  return (
    <footer className="footer">
      {!disableFooterIcons && <FooterIcons layoutIcons={layoutIcons} intl={intl} />}
      <div className="container is-fluid">
        <div className="columns is-mobile is-multiline footer-navs">
          <div className="column">
            <p className="has-text-primary has-text-weight-bold is-marginless is-size-6">
              {intl.formatMessage({ id: 'shared.explore' })}
            </p>
            <nav>
              {exploreData.map(({ id, title, link, link_override }) => (
                <Link
                  key={id}
                  to={link_override?.uri || link.uri_alias}
                  className="is-block"
                  {...link.options.attributes}
                >
                  {title}
                </Link>
              ))}
            </nav>
          </div>
          <div className="column">
            <p className="has-text-primary has-text-weight-bold is-marginless is-size-6">
              {intl.formatMessage({ id: `${INTL_TAG}.legal` })}
            </p>
            <nav>
              {legalData.map(({ id, title, link, link_override }) => (
                <a
                  key={id}
                  href={link_override?.uri || link.uri}
                  className="is-block"
                  {...link.options.attributes}
                  rel="noopener noreferrer"
                >
                  {title}
                </a>
              ))}

              {process.env.GATSBY_OT_LICENSE_SRC && (
                <button
                  ref={otButtonRef}
                  className="is-block ot-sdk-show-settings"
                  onClick={() => {
                    if (window && window.Optanon) {
                      window.Optanon.ToggleInfoDisplay()
                    }
                  }}
                />
              )}
              {intl.locale === "de-de" && <a href="#" onClick={toggleLegalPopup}>Impressum</a>}
            </nav>
          </div>
          <div className="column is-full-mobile is-half-tablet footer-social">
            <p className="has-text-primary has-text-weight-bold is-marginless is-size-6">
              {intl.formatMessage({ id: `${INTL_TAG}.follow_us` })}
            </p>
            <div className="columns is-mobile is-vcentered is-multiline">
              <SocialMedia className="column is-two-thirds-mobile is-full-tablet" data={socialMediaData} />
              <p className="column is-one-third-mobile is-full-tablet has-text-weight-bold has-text-right-mobile footer-social--tag">
                @wedo_act
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container is-fluid footer-copyright">
        <div className="columns is-vcentered is-mobile">
          <div className="column is-two-thirds-mobile">
            <p className="is-size-8">
              <strong>
                {intl.formatMessage({ id: `${INTL_TAG}.copyright` }, { year: new Date().getFullYear() })}
                <span className="is-hidden-mobile">
                  <br />
                  {intl.formatMessage({ id: `${INTL_TAG}.trademark_rights` })}
                </span>
              </strong>
            </p>
          </div>
          <div className="column has-text-right is-one-third-mobile">
            <Link to="/" className="is-block is-pulled-right">
              <svg className="icon-logo-wrapper" width="100%">
                <use className="icon-logo-wedo" xlinkHref={`${layoutIcons}#icon-logo-wedo`} />
              </svg>
            </Link>
          </div>
        </div>
      </div>
      {isLegalPopupOpen && intl.locale === "de-de" && <LegalPopupDE toggleLegalPopup={toggleLegalPopup} />}
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
  socialMediaData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      link: PropTypes.shape({
        uri: PropTypes.string.isRequired,
        uri_alias: PropTypes.string,
        options: PropTypes.shape({
          fa_icon: PropTypes.string.isRequired,
          attributes: PropTypes.shape({
            target: PropTypes.string
          })
        }).isRequired
      }).isRequired
    })
  ).isRequired,
  exploreData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      link: PropTypes.shape({
        uri: PropTypes.string,
        uri_alias: PropTypes.string.isRequired,
        options: PropTypes.shape({
          attributes: PropTypes.any
        })
      }).isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  legalData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      link: PropTypes.shape({
        uri: PropTypes.string.isRequired,
        uri_alias: PropTypes.string,
        options: PropTypes.shape({
          attributes: PropTypes.any
        }).isRequired
      }).isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired,
  disableFooterIcons: PropTypes.bool,
  layoutIcons: PropTypes.string
}

export default Footer
