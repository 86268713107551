import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import _get from 'lodash.get'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'
import CategoryCard from 'src/components/CategoryCard'

import useTracking from 'src/hooks/useTracking'
import { createPageData } from 'src/utils/tracking'
import { getMetaTitle } from 'src/utils/shared'

import { CategoryCardColumn } from 'src/themes'

const ProductsPage = ({ pageContext, data }) => {
  const intl = useIntl()

  const {
    nodePage: {
      title,
      body: { value: description },
      field_breadcrumb: breadcrumbs,
      relationships: pageRelationships,
      field_metatag_normalized: metaTags
    }
  } = data

  const categories = _get(pageRelationships, 'field_sections[0].relationships.field_pc_product_category', [])

  useTracking({
    data: createPageData({
      pageId: 'productCategory',
      breadCrumbs: `home:products`,
      pageType: 'products categories',
      instanceName: 'products categories',
      primaryCategory: 'products',
      language: intl.locale,
      pageTitle: getMetaTitle(metaTags),
      pageName: pageContext.slug
    })
  })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
        <div className="columns has-margin-bottom-2">
          <h1 className="column is-3-tablet is-2-widescreen title__page">{title}</h1>
          <div className="column is-half-widescreen" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div className="columns is-multiline">
          {categories.map(({ title, id, path, relationships, field_pc_image }) => (
            <CategoryCardColumn className="column is-half-tablet is-one-quarter-widescreen" key={id}>
              <CategoryCard
                title={title}
                buttonText={intl.formatMessage({ id: 'shared.explore' })}
                buttonUri={path.alias}
                // this has to be standarized
                image={relationships?.field_pc_image}
                alt={field_pc_image?.alt}
                isClickable
                testAttr="category-card"
              />
            </CategoryCardColumn>
          ))}
        </div>
      </Hero>
    </Layout>
  )
}

export default ProductsPage

ProductsPage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    nodePage: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.shape({ value: PropTypes.string }),
      field_breadcrumb: PropTypes.arrayOf(PropTypes.object),
      field_pc_image: PropTypes.shape({
        alt: PropTypes.string
      }),
      relationships: PropTypes.shape({
        field_pc_image: PropTypes.object,
        field_sections: PropTypes.arrayOf(
          PropTypes.shape({
            relationships: PropTypes.shape({
              field_pc_product_category: PropTypes.arrayOf(PropTypes.object).isRequired
            })
          })
        )
      }),
      field_metatag_normalized: PropTypes.arrayOf(PropTypes.object)
    })
  })
}

export const query = graphql`
  query ProductsPage($slug: String!, $langKey: String) {
    nodePage(path: { alias: { eq: $slug } }, field_component: { eq: "products" }, langcode: { eq: $langKey }) {
      ...PageDataFragment

      relationships {
        field_sections {
          ... on paragraph__product_categories {
            relationships {
              field_pc_product_category {
                title
                id
                body {
                  value
                }
                path {
                  alias
                }
                field_pc_image {
                  alt
                }
                relationships {
                  field_pc_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          quality: 70
                          webpOptions: { quality: 72 }
                          formats: [JPG, WEBP]
                          outputPixelDensities: [0.25, 0.35, 0.5, 0.75, 1]
                          sizes: "(min-width: 1008px) 25vw, (min-width: 768px) 50vw, 100vw"
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
