import qs from 'qs'
import { EMAIL_REGEX, GET_AGREEMENTS_FETCH_URL } from 'src/consts'

export const getAgreementsLangCode = (lang) => {
  switch (lang) {
    case 'en-gb':
      return 'en-US'
    case 'de-de':
      return 'de-DE'
    case 'el-gr':
      return 'el-GR'
    case 'es-es':
      return 'es-ES'
    case 'it-it':
      return 'it-IT'
    case 'nl-nl':
      return 'nl-NL'
    case 'en-au':
      return 'en-AU'
    case 'fr-be':
      return 'fr-BE'
    case 'fr-fr':
      return 'fr-FR'
    case 'ru-ru':
      return 'ru-RU'
    default:
      return ''
  }
}

export const getAgreementsUrl = (params = {}) => GET_AGREEMENTS_FETCH_URL + qs.stringify(params)

export const validateForm = ({ formState, requiredFields }) =>
  requiredFields.reduce((total, field) => {
    if (!formState[field]) {
      return {
        ...total,
        [field]: true
      }
    }

    if (field.toLowerCase().includes('email') && !EMAIL_REGEX.test(formState[field])) {
      return {
        ...total,
        [field]: true
      }
    }

    return total
  }, {})

export const mapAgreementsPropertyNames = (agreements) =>
  agreements.map(({ Id, ErrorMessage, Label, Name, Required }) => ({
    id: Id,
    errorMessage: ErrorMessage,
    label: Label,
    name: Name,
    required: Required
  }))
