import React, { FC } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { formImageBaseData } from 'src/utils/shared'
import * as S from './styled'

import { PictureCardProps } from './types'

const PictureCard: FC<PictureCardProps> = ({ relationships, title, isCarousel, alt }) => {
  const imageData = formImageBaseData({
    image: relationships?.field_i_image || relationships?.field_po_image
  })

  return (
    <S.Card>
      {!!imageData && <GatsbyImage css={S.imageStyles} {...imageData} alt={alt}/>}
      {!!title && <S.Title isCarousel={isCarousel}>{title}</S.Title>}
    </S.Card>
  )
}

export default PictureCard
