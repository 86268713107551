import type { RelatedRetailer } from './types'

export const getRetailerData = (logo: string, name: string): { relationships: RelatedRetailer } => ({
  relationships: {
    field_wtb_retailer_reference: {
      name,
      relationships: {
        field_logotype: {
          localFile: {
            url: logo
          }
        }
      }
    }
  }
})
