import React, { forwardRef, LegacyRef } from 'react'
import { Parallax as ReactParallax, Background } from 'react-parallax'
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'

import * as S from './styled'

import { ParallaxProps } from './types'

const Parallax = (
  { image, strength = 400, imgProps, alt, translate = 0, ...rest }: ParallaxProps,
  ref: LegacyRef<ReactParallax>
) => {
  const images = withArtDirection(image[2], [
    {
      media: '(max-width: 768px)',
      image: image[0]
    },
    {
      media: '(min-width: 769px) and (max-width: 1214px)',
      image: image[1]
    },
    {
      media: '(min-width: 1215px)',
      image: image[2]
    }
  ])

  return (
    <ReactParallax className="hero" strength={strength} {...rest} ref={ref} css={S.parallax}>
      <Background>
        <GatsbyImage image={images} alt={alt} {...imgProps} css={S.gatsbyImage(translate)} />
      </Background>
    </ReactParallax>
  )
}

export default forwardRef<ReactParallax, ParallaxProps>(Parallax)
