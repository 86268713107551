import React, { memo, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'gatsby-plugin-intl'

import { redirectToLang } from 'src/utils/layout'

let LANGUAGES = [
  { langCode: 'en-gb', langText: 'International' },
  { langCode: 'en-gb', langText: 'UK/Ireland' },
  { langCode: 'en-au', langText: 'Australia/New Zealand' },
  { langCode: 'nl-be', langText: 'Belgium (Dutch)' },
  { langCode: 'fr-be', langText: 'Belgium (French)' },
  { langCode: 'br', langText: 'Brasil' },
  { langCode: 'en-gb', langText: 'Danmark' },
  { langCode: 'de-de', langText: 'Deutschland' },
  { langCode: 'es-es', langText: 'España' },
  { langCode: 'el-gr', langText: 'Ελλάδα' },
  { langCode: 'fr-fr', langText: 'France' },
  { langCode: 'en-gb', langText: 'India' },
  { langCode: 'it-it', langText: 'Italia' },
  { langCode: 'nl-nl', langText: 'Nederland' },
  { langCode: 'en-gb', langText: 'Norge' },
  { langCode: 'de-de', langText: 'Österreich' },
  { langCode: 'de-de', langText: 'Schweiz' },
  { langCode: 'en-gb', langText: 'Suomi' },
  { langCode: 'en-gb', langText: 'Sverige' }
]

const ListItem = ({ lang, href, Component }) => (
  <span className="navbar-item" onClick={() => redirectToLang(href)}>
    <Component width="1.5em" height="1.5em" className="has-margin-right-1" /> {lang}
  </span>
)

const CountrySelector = ({ isSelectorOpen, onListClick, pageUrls = [] }) => {
  const { messages } = useIntl()
  const changeCountry = messages['menu.change_country']


  return (
    <Fragment>
      <div
        className={`navbar-item has-dropdown is-hoverable ${isSelectorOpen ? 'is-opened' : ''}`}
        data-test="country-selector"
      >
        <span
          className="navbar-link is-hidden-touch"
          onClick={onListClick}
        >
          {changeCountry}
        </span>
        <span className="navbar-link is-hidden-desktop" onClick={onListClick}>
          {changeCountry}
        </span>
        <div className="navbar-dropdown is-right selector-nav-bar" data-test="country-selector-dropdown">
          {LANGUAGES.map(({ langCode, langText }) => (
            <span key={langText} className="navbar-item" onClick={() => redirectToLang({ langCode, pageUrls })}>
              {langText}
            </span>
          ))}
        </div>
      </div>
    </Fragment>
  )
}

export default memo(CountrySelector)

CountrySelector.propTypes = {
  onListClick: PropTypes.func.isRequired,
  isSelectorOpen: PropTypes.bool,
  pageUrls: PropTypes.arrayOf(
    PropTypes.shape({
      hreflang: PropTypes.string,
      href: PropTypes.string
    })
  ).isRequired
}

ListItem.propTypes = {
  lang: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  Component: PropTypes.node.isRequired
}
