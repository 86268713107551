import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { colors, lineHeights, sizes, mq, breakpoints, padding, backgrounds, transition } from './variables'
import cartCenter from 'src/images/cart_center.png'
import cartTop from 'src/images/cart_top.png'
import cartBottom from 'src/images/cart_bottom.png'

export const boldWhite = css`
  font-weight: bold;
  color: ${colors.white};
`

export const frame = ({ color }) => css`
  position: relative;
  padding: 1.25rem 0;

  &:after,
  &:before {
    content: '';
    width: 10%;
    height: 3px;
    background-color: ${color};
    position: absolute;
    display: block;
  }

  &::before {
    top: 0;
    right: 0;
  }

  &:after {
    bottom: 0;
    left: 0;
  }
}
`

export const outlineNone = css`
  outline: none !important;
  &:hover,
  &:active,
  &:focus,
  &:focus-within,
  &:visited,
  &:active:focus,
  &::-moz-focus-inner {
    outline: none !important;
    box-shadow: none !important;
    -webkit-tap-highlight-color: transparent !important; /* mobile webkit */
  }
`

export const iconSvgStyles = (size) => css`
  cursor: pointer;
  width: ${size};
  height: ${size};
`

export const iconUseStyles = (size) => css`
  font-size: ${size};
`

export const title = {
  big: css`
    ${mq({
      fontSize: [sizes.size2, undefined, sizes.size1]
    })}
    line-height: ${lineHeights.small};
  `,
  page: css`
    margin: 0;
    font-size: ${sizes.size4};
    line-height: ${lineHeights.small};
    font-weight: 700;
  `
}

export const linkBorder = ({ color, hoverColor }) => css`
  text-decoration: underline;
  color: ${color};
  background: none;
  transition: all 0.4s;

  @media (min-width: ${breakpoints.desktop}) {
    ${({ noPaddingUrl }) => (noPaddingUrl ? { padding: 0 } : { padding: '0 5px' })}

    &:hover {
      color: ${hoverColor};
      background: ${color};
      transition: all 0.4s;
    }
  }
`

export const CategoryCardColumn = styled.div`
  ${mq({
    padding: [0, undefined, undefined, padding.x1]
  })};

  @media (max-width: ${breakpoints.tablet}) {
    margin-left: -1rem;
    margin-right: -1rem;

    &:nth-child(even) {
      text-align: right;
    }
  }
`

export const cardCSS = css`
  border: none;
  box-shadow: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const StyledIcon = styled.svg`
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
`

export const weDoHero = css`
  background-image: url(${cartCenter});
  background-position: top;
  background-repeat: repeat;
  position: relative;

  &:before,
  &:after {
    content: '';
    background-position: bottom;
    background-repeat: repeat-x;
    display: block;
    width: 100%;
    position: absolute;
  }

  &:before {
    height: 44px;
    background-image: url(${cartTop});
    top: 0;
    transform: translateY(-100%);

    @media (min-width: ${breakpoints.mobile}) {
      top: 2px;
    }
    @media (min-width: ${breakpoints.widescreen}) {
      top: 4px;
    }
  }

  &:after {
    height: 55px;
    background-image: url(${cartBottom});
    bottom: 0;
    transform: translateY(100%);

    @media (min-width: ${breakpoints.mobile}) {
      bottom: 2px;
    }
    @media (min-width: ${breakpoints.widescreen}) {
      bottom: 4px;
    }
  }
`
export const formImageStyles = css`
  display: block;
  ${mq({
    marginBottom: ['-4.5rem', '-60%'],
    marginLeft: ['1.5rem', 'initial'],
    marginRight: ['-1.5rem', 'initial']
  })};
`

export const SocialIcon = styled.svg`
  height: 1em;
  vertical-align: -0.125em;
  ${({ isYoutube, isFacebook }) => ({
    width: isYoutube ? '1.125em' : isFacebook ? '0.625em' : '0.875em'
  })}
`

export const playButtonStyles = ({ isDot }) => css`
  background: ${backgrounds.blackAlphaHigh};
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${colors.extraLight};
  transition: ${transition};
  ${isDot
    ? css`
        width: 2em;
        height: 2em;
      `
    : css`
        width: 5em;
        height: 5em;
        cursor: pointer;

        &:hover {
          background: ${isDot ? backgrounds.blackAlphaHigh : backgrounds.blackAlpha};
        }
      `}
`

export const noPaddingMobile = css`
  @media (max-width: 769px) {
    padding: 0;
  }
`
