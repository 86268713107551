import { useStaticQuery, graphql } from 'gatsby'

const useIngredientsPDF = ({ langcode }) => {
  if (typeof window === 'undefined') {
    return null
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { ingredientsPDFs } = useStaticQuery(
    graphql`
      query SiteMetaData {
        ingredientsPDFs: allFile(filter: { sourceInstanceName: { eq: "pdfs" } }) {
          nodes {
            relativePath
            publicURL
          }
        }
      }
    `
  )

  const properPDF = ingredientsPDFs.nodes.find(({ relativePath }) => relativePath.endsWith(`${langcode}.pdf`))

  if (!properPDF) {
    return null
  }

  return location?.origin + properPDF.publicURL
}

export default useIngredientsPDF
