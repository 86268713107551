import { css } from '@emotion/react'

import { breakpoints, colors } from 'src/themes'

export const hero = css`

.gatsby-image-wrapper {
  @media (max-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}
div.field.is-size-7 a {
  color: ${colors.orange} !important;
  text-decoration: underline;
}
`

