// TODO: refactor
export const getNumbersToSum = (numToSum: number, arrLength: number, isTimeout?: boolean): number[] => {
  const result: number[] = []
  const decimals: number[] = []

  let currSum = 0

  // generating properly long array of numbers
  for (let i = 0; i < arrLength; i++) {
    result.push(Math.random())
    currSum += result[i]
  }

  let remaining = numToSum

  // setting result with rounded numbers
  result.forEach((num, i) => {
    const res = (num / currSum) * numToSum
    const floored = Math.floor(res) < 1 && isTimeout ? 1 : Math.floor(res)

    remaining -= floored
    decimals.push(floored - num)

    result[i] = floored
  })

  // adding missing values to match numToSum
  while (remaining > 0) {
    let maxPos = 0
    let maxVal = 0

    decimals.forEach((num, i) => {
      if (maxVal < num) {
        maxVal = num
        maxPos = i
      }
    })

    result[maxPos]++
    decimals[maxPos] = 0
    remaining--
  }

  return result
}

export const msToMinutes = (ms: number): number => ms / (1000 * 60)

export const calcBottlesPerInterval = (bottlesDaily: number, incrementTime: number): number =>
  Math.ceil((bottlesDaily / 24 / 60) * msToMinutes(incrementTime))
