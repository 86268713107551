import { css } from '@emotion/react'

import { backgrounds, colors, fonts, mq, sizes } from 'src/themes'

export const salonFinder = css`
    .app-wrapper {

        .search-box {
            &__container {
                margin: 0;
                width: 320px;
            }
  
            &__select-wrapper {
                border: 1px solid ${backgrounds.blackAlphaLight};
            }
  
            &__select-text {
                padding: 0 28px 0 10px;
                line-height: 43px;
                font-size: ${sizes.size6};
                font-family: ${fonts.primary};
                font-weight: 300;
                background-color: ${colors.white};
                color: ${colors.dark};
                width: 320px;
            }
  
            &__select-button {
                height: 45px;
                background-color: ${colors.orange};
            }
            &__open-list {
                padding: 0px 4px 1px;
            }
  
            &__list-option {
                background-color: ${colors.white};
            }
  
            &__input {
                border: 1px solid ${backgrounds.blackAlphaLight};
                border-radius: 0;
                height: 45px;
                margin-top: 10px;
                font-family: ${fonts.primary};
                color: ${colors.dark};
                text-transform: none;
                font-size: ${sizes.size6};
                width: 322px;

                &:focus {
                    outline: none;
                }
            }
        }
        .filter {
            text-align: center;
            padding: 10px 0;
  
            &__filter-button {
                background: ${colors.orange};
                border: 1px solid ${backgrounds.blackAlphaLight};
                margin: 10px 0;
                padding: 10px 50px;
                text-transform: uppercase;
                font-size: ${sizes.size8};
                font-weight: 600;
                cursor: pointer;
                color: ${colors.white};

                &:hover {
                    background: ${colors.white};
                    color: ${colors.orange};
                }
            }
  
            &__options {
                ${mq({
                    maxWidth: [`400px`, `400px`],
                })}
            }
  
            &__button {
                border: 1px solid ${backgrounds.blackAlphaLight};
                text-transform: uppercase;
                font-size: ${sizes.size8};
                cursor: pointer;
                padding: 7px 20px;
                margin: 10px;
                width: auto;
                height: auto;
  
                &.active {
                    background: ${colors.medium};
                    border: 1px solid ${backgrounds.blackAlphaLight};
                    color: ${colors.white};
                }
            }
        }
        .markers-count {
            padding: 0 15px;
            text-align: center;

        }
        .app-wrapper-map-container {
            .map {
                .gm-style-iw-d  {
                    text-align: center;     
                    color: ${colors.dark};
                    padding: 10px 20px 10px 5px;
                    overflow: auto;
                    font-size: ${sizes.size5}
                }
            }
        }
        .salon-list {
            float: left;
            background: ${colors.light};
            color: ${colors.dark};
            ${mq({
                width: ['100%', '100%', '28%', '28%'],
                height: ['442px', '500px', 'auto', 'auto']
            })}
  
            .infinite-scroll-component {
                overflow-y: hidden !important;    
            }
  
            &__salon__data__title {
                font-size: ${sizes.size6};
                font-weight: 600;

            }
  
            .salon {
                &__title-data {
                    font-size: ${sizes.size7};
                    font-weight: 700;
                    color: ${colors.dark};
                }
            }
        }
        .salon-details {
            color: ${colors.dark};
            ${mq({
                overflowY: ['none','none','auto','auto'],
                padding: ['10px 0', '10px 15px']
            })}
            &__back-button {
                background-color: ${colors.orange};
                border: 1px solid ${backgrounds.blackAlphaLight};
                border-radius: 0;
                color: ${colors.white};
                min-height: 38px;
                padding: 2px 18px;

                &:hover {
                    background: ${colors.medium};
                    color: ${colors.white};
                }
            }
            &__name {
                word-wrap: break-word;
            }
            &__details-row {
                font-size: ${sizes.size8};
            }
            &__details-label {
                ${mq({
                    width: ['35%', '30%']
                })}
            }
            &__info-container {
                color: ${colors.dark};
            }

            &__owner-button {
                padding: 10px 15px;
                text-decoration: none;
                margin: 10px 0 0 0;
                float: left;
                border: 1px solid ${colors.orange};
                text-align: center;
                text-transform: uppercase;
                font-size: ${sizes.size9};
                font-weight: 600;
                background-color: ${colors.white};
                color: ${colors.dark};

                &:hover {
                    background: ${colors.orange};
                }
            }
        }
    }
`
