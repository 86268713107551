import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { boldWhite, colors, frame, lineHeights, mq, sizes, transition, breakpoints } from 'src/themes'

import { CarouselSwitchControls } from './types'

export const Card = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
`

export const Title = styled.h3<CarouselSwitchControls>`
  ${boldWhite};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: ${lineHeights.medium};
  transition: ${transition};
  font-size: ${sizes.size6};

  ${({ isCarousel }) =>
    mq(
      isCarousel
        ? {
            fontSize: [sizes.size7, sizes.size6, sizes.size4],
            opacity: 0,
            textTransform: 'uppercase'
          }
        : css`
            ${withBorder};
            font-size: ${sizes.size5};
            opacity: 1;
            
            @media (min-width: ${breakpoints.tablet}) {
              font-size: ${sizes.size4};
            }
          `
    )};
`

export const imageStyles = css`
  max-width: 100%;
  max-height: 100%;
`

export const withBorder = css`
  ${frame({ color: colors.orange })};
  display: inline-block;
  position: absolute;

  &:after,
  &:before {
    width: 25%;
  }
`
