import { useEffect, useState } from 'react'

import { isBrowser } from 'src/utils/shared'

const useMatchMedia = (query: string): boolean => {
  const [matches, setMatches] = useState(isBrowser && window.matchMedia(query).matches)

  useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    media.addListener(listener)

    return () => media.removeListener(listener)
  }, [query, matches])

  return matches
}

export default useMatchMedia
