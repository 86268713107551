import React, { FC } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { checkIfAnswerIsSelected } from '../utils'

import * as S from './styles/answer'

import { AnswerProps } from './types/answer'

const Answer: FC<AnswerProps> = ({
  handleAnswer,
  id,
  text,
  numberOfChoices = 1,
  activeChoices,
  image,
  simpleVariant,
  incompatibleWith
}) => (
  <S.AnswerContainer
    onClick={(e) => {
      e.preventDefault()

      handleAnswer({ numberOfChoices, choiceId: id, incompatibleWith })
    }}
    noImage={!image}
    data-test="quiz-answer"
  >
    {image && <GatsbyImage css={S.answerImageStyles} alt={text} image={image?.childImageSharp?.gatsbyImageData} />}
    <S.AnswerButton
      data-test="quiz-answer-button"
      className="button"
      noImage={!image}
      isActive={checkIfAnswerIsSelected({ activeChoices, id })}
      simpleVariant={simpleVariant}
    >
      {text && text.toUpperCase()}
    </S.AnswerButton>
  </S.AnswerContainer>
)

export default Answer
