import styled from '@emotion/styled'

import { mq, sizes, colors } from 'src/themes'

export const Disclaimer = styled.section`
  ${mq({
    padding: [`0 ${sizes.size4}`, undefined, '0 0']
  })}
`

export const DisclaimerText = styled.p`
  color: ${colors.medium};
  opacity: 0.6;
`
