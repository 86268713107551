import React, { FC } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { formImageBaseData } from 'src/utils/shared'

import { cardCSS } from 'src/themes'

import { MemberCardProps } from './types'

const MemberCard: FC<MemberCardProps> = ({
  field_ps_name,
  field_ps_position,
  field_ps_text,
  relationships,
  field_ps_image
}) => (
  <div css={cardCSS} className="card member-card">
    <div className="card-image">
      {relationships?.field_ps_image && (
        <GatsbyImage loading="eager" {...formImageBaseData({ image: relationships.field_ps_image })} alt={field_ps_image?.alt} />
      )}
    </div>
    <div className="card-content">
      <div className="media-content has-text-centered">
        <h3 className="card-title is-size-4 has-text-weight-bold">{field_ps_name}</h3>
        <h5 className="card-title is-size-6">{field_ps_position}</h5>

        <div
          className="is-size-7 card-description has-margin-top-2"
          dangerouslySetInnerHTML={{ __html: field_ps_text.value }}
        />
      </div>
    </div>
  </div>
)

export default MemberCard
