import React from 'react'
import PropTypes from 'prop-types'

const HAGEL_QUERY_STRING = '?utm_source=wedo-website&utm_medium=website-link&utm_campaign=wedo-website'

import { NavbarDropdown } from '../../styles/components'

export const ShopList = ({ shops, handleShopClick, locale }) => (
  <NavbarDropdown>
    {shops?.map(
      ({
        relationships: { field_wtb_retailer_reference: { name: retailerName = '', relationships = {} } = {} } = {},
        field_wtb_link = ''
      } = {}) => {
        if (!retailerName) {
          return null
        }
        const Icon = relationships?.field_logotype?.localFile?.url

        const url =
          locale === 'de-de' && retailerName === 'Hagel-shop'
            ? field_wtb_link?.uri + HAGEL_QUERY_STRING
            : field_wtb_link?.uri

        return (
          <a
            key={retailerName}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="navbar-item button is-size-8 is-uppercase has-text-left is-light has-margin-bottom-1"
            onClick={() => handleShopClick(url)}
            data-test="modal-shop-link"
            css={{ display: 'inline-flex', width: '100%' }}
          >
            {Icon && <img src={Icon} width="42" height="24" className="has-margin-right-1" />}
            {retailerName}
          </a>
        )
      }
    )}
  </NavbarDropdown>
)

export default ShopList

ShopList.propTypes = {
  shops: PropTypes.arrayOf(PropTypes.object),
  handleShopClick: PropTypes.func,
  locale: PropTypes.string
}
