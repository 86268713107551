export const setCSSViewportHeight = () => {
  const vh = window?.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

export const findLink = ({ linksList, uri, hasParent, lang }) =>
  linksList?.find(
    ({ node }) => node?.link?.uri === uri && !!node?.link?.parent === hasParent && node?.langcode === lang
  )

export const getInstagramPosts = async ({ userId }) => {
  try {
    const request = await fetch(
      `https://www.instagram.com/graphql/query/?query_id=17888483320059182&variables={"id":"${userId}","first":100,"after":null}`
    )
    const { data } = await request.json()

    return data?.user?.edge_owner_to_timeline_media?.edges?.sort(
      (a, b) => b?.node?.taken_at_timestamp - a?.node?.taken_at_timestamp
    )
  } catch (_) {
    return []
  }
}

export const mapLocaleToISOCountry = (locale) => {
  const ISO_COUNTRIES = {
    'en-gb': 'GB',
    'de-de': 'DE',
    'es-es': 'ES',
    'el-gr': 'GR',
    'it-it': 'IT',
    'fr-fr': 'FR',
    'ru-ru': 'RU'
  }

  return ISO_COUNTRIES[locale] || 'GB'
}
