import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { colors, fonts, mq } from 'src/themes'

import bottleImage from '../../images/shapebottle.svg'

export const CounterWrapper = styled.div`
  margin: 0 auto;
  ${mq({
    height: [undefined, 111, 148],
    fontSize: [50, 75, 100]
  })}
`

export const odometerDigit = css`
  .odometer-digit {
    color: ${colors.white};
    font-family: ${fonts.primary};
    ${mq({
      margin: ['0 1px', '0 10px', '0 15px'],
      fontSize: [30, 45, 60],
      width: [37, 55, 74]
    })}

    & > * {
      display: block;
      text-align: center;
    }

    &:before {
      background-position: center center;
      background-image: url(${bottleImage});
      background-repeat: no-repeat;
      background-size: fit;
      ${mq({
        width: [37, 55, 74],
        height: [74, 111, 148]
      })}
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .odometer-last-value {
      left: 0;
      right: 0;
    }
  }
}
`

export const partnerLogo = css`
  max-width: 359px;
  margin: 0 auto;
  padding: 0 5px;
`
