import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

import Hero from 'src/components/Hero'
import Video from 'src/components/Video'

import Plasticbank from 'src/assets/images/plasticbank-icons.svg'

import * as S from '../styles/weDo'
import { title } from '../styles/styled'
import { weDoHero } from 'src/themes'

const INTL_TAG = 'homepage.we_do'

const VIDEO_CONFIG = {
  youtube: {
    playerVars: {
      controls: 1,
      autoPlay: 1,
      showInfo: 1,
      autoHide: 0
    },
    preload: false
  }
}

const getVideoURL = (lang) => {
  switch (lang) {
    case 'de-de':
      return 'https://youtu.be/Kxf9yRPw_yQ'
    case 'es-es':
      return 'https://youtu.be/5xCDrTACbb8'
    default:
      return 'https://youtu.be/lW365tgKUvM'
  }
}

const WeDo = ({ intl, imgReadMoreBlock }) => (
  <Hero hero="wedo-we-do has-margin-top-4 has-margin-bottom-4" customCSS={weDoHero}>
    <div className="columns">
      <div className="column is-half">
        <h2 css={title('orange')} className="title is-inline-block">
          {intl.formatMessage({ id: `${INTL_TAG}.header` })}
        </h2>
        <div className="is-hidden-tablet has-margin-bottom-4">
          <Video
            url={getVideoURL(intl.locale)}
            config={VIDEO_CONFIG}
            light
            playing
            className="video video__wedo video-responsive is-loading"
          />
        </div>
        <p className="has-text-centered has-margin-bottom-4 is-size-3 is-width-80 is-handwritten is-rotated__left">
          {intl.formatMessage({ id: `${INTL_TAG}.wedo_is` })}
        </p>

        <div className="columns is-mobile">
          <div className="column is-half">
            <GatsbyImage {...imgReadMoreBlock.left} className="is-rotated__left has-margin-top-2" />
          </div>
          <div className="column is-half">
            <GatsbyImage {...imgReadMoreBlock.right} className="is-rotated__right" />
          </div>
        </div>
      </div>
      <div className="column is-half is-flex is-dir-column">
        <div className="is-hidden-mobile has-margin-bottom-4 is-rotated__left">
          <Video
            url={getVideoURL(intl.locale)}
            config={VIDEO_CONFIG}
            light={imgReadMoreBlock.thumbnail}
            playing
            className="video video__wedo video-responsive"
          />
        </div>
        <p className="has-text-centered has-margin-bottom-4 is-size-3 is-width-80 is-handwritten is-rotated__left is-growed">
          {intl.formatMessage({ id: `${INTL_TAG}.thats_why` })}
        </p>
        <S.Plasticbank>
          <S.PlaticIconWrapper>
            <S.PlasticIcon xlinkHref={`${Plasticbank}#plasticbank-${intl.locale}`} />
          </S.PlaticIconWrapper>
        </S.Plasticbank>
      </div>
    </div>
  </Hero>
)

export default WeDo

WeDo.propTypes = {
  intl: PropTypes.object,
  imgReadMoreBlock: PropTypes.shape({
    left: PropTypes.shape({
      image: PropTypes.object,
      alt: PropTypes.string
    }),
    right: PropTypes.shape({
      image: PropTypes.object,
      alt: PropTypes.string
    }),
    thumbnail: PropTypes.string
  }),
  partnershipLink: PropTypes.string
}
