import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { Link } from 'gatsby-plugin-intl'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'
import layoutIcons from 'src/assets/images/layout-icons.svg'

import useTracking from 'src/hooks/useTracking'
import { createPageData, createDataBasedOnLocation } from 'src/utils/tracking'
import { getMetaTitle, isBrowser } from 'src/utils/shared'
import { metaTags } from './data'
import * as S from './styles/404'

const INTL_TAG = 'not_found'

const NotFoundPage = () => {
  const intl = useIntl()

  const pathNameArr = isBrowser ? location.pathname.split('/') : []
  const pageName = '/' + pathNameArr[pathNameArr.length - 1]

  useTracking({
    data: createPageData({
      ...createDataBasedOnLocation(intl.locale),
      pageTitle: getMetaTitle(metaTags),
      errorPage: '404',
      pageName
    })
  })

  return (
    <Layout containerClass="is-flex">
      <SEO fetchedMetaTags={metaTags} />
      <Hero hero="is-secondary has-text-centered is-growed is-404" heroBody="is-flex is-flex-centered">
        <div className="columns is-centered">
          <div className="column is-three-quarters-tablet is-half-desktop">
            <S.Icon404Wrapper width="100%">
              <S.Icon404 xlinkHref={`${layoutIcons}#icon-404`} />
            </S.Icon404Wrapper>
          </div>
        </div>

        <h1 className="has-margin-bottom-0 title">{intl.formatMessage({ id: `${INTL_TAG}.header` })}</h1>
        <p className="has-margin-bottom-3">{intl.formatMessage({ id: `${INTL_TAG}.description` })}</p>
        <p>
          <Link to="/" className="button is-outlined is-white-alpha is-uppercase is-fullwidth-mobile">
            {intl.formatMessage({ id: `${INTL_TAG}.button` })}
          </Link>
        </p>
      </Hero>
    </Layout>
  )
}

export default NotFoundPage
