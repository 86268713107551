import React, { FC } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-plugin-intl'

import { formImageBaseData, getArtDirection } from 'src/utils/shared'

import * as S from './styled'

import { CardWrapperProps, CardButtonProps, CategoryCardProps } from './types'

const CardWrapper: FC<CardWrapperProps> = ({ children, isClickable, href, disableHoverEffects, isQuizCard }) =>
  isClickable ? (
    <Link to={href}>
      <S.CategoryCardStyled isQuizCard={isQuizCard} disableHoverEffects={disableHoverEffects}>
        {children}
      </S.CategoryCardStyled>
    </Link>
  ) : (
    <S.CategoryCardStyled isQuizCard={isQuizCard} disableHoverEffects={disableHoverEffects}>
      {children}
    </S.CategoryCardStyled>
  )

const CardButton: FC<CardButtonProps> = ({ isClickable, buttonText, buttonUri, handleButtonClick }) =>
  isClickable || handleButtonClick ? (
    <S.CategoryCardButton
      onClick={handleButtonClick ? () => handleButtonClick(true) : null}
      className="button is-outlined is-white-alpha is-uppercase is-fullwidth"
    >
      {buttonText}
    </S.CategoryCardButton>
  ) : (
    <Link to={buttonUri} className="button is-outlined is-white-alpha is-uppercase is-fullwidth">
      {buttonText}
    </Link>
  )

const CategoryCard: FC<CategoryCardProps> = ({
  title,
  description,
  buttonText,
  buttonUri,
  isClickable,
  ratio,
  testAttr,
  image,
  disableHoverEffects,
  handleButtonClick,
  imageArtDirection,
  isQuizCard,
  alt
}) => {
  const sizes = `(min-width: 1008px) 25vw, (min-width: 768px) 50vw, 100vw`

  return (
    <CardWrapper
      isQuizCard={isQuizCard}
      disableHoverEffects={disableHoverEffects}
      isClickable={isClickable}
      href={buttonUri}
    >
      {image && (
        <GatsbyImage css={S.gatsbyImageWrapper}
          {...formImageBaseData({ image: image, aspectRatio: ratio, sizes })}
          alt={alt} />
      )}
      {imageArtDirection && (
        <GatsbyImage
          css={S.gatsbyImageWrapper({ artDirection: true })}
          alt={imageArtDirection.alt}
          image={getArtDirection(imageArtDirection.image)}
        />
      )}
      <S.Card className="has-text-white" {...(testAttr && { 'data-test': testAttr })}>
        <S.CardHeader className={description && 'has-margin-bottom-2'} css={!description && S.centered}>
          <h4
            className={imageArtDirection ? '' : 'has-frame is-inline-block is-size-3 has-text-weight-bold'}
            css={imageArtDirection && S.cardTitleStyles}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </S.CardHeader>
        {!!description && <S.CardContent dangerouslySetInnerHTML={{ __html: description }} />}
        {(buttonUri || handleButtonClick) && (
          <S.CardFooter>
            <CardButton
              handleButtonClick={handleButtonClick}
              isClickable={isClickable}
              buttonText={buttonText}
              buttonUri={buttonUri}
            />
          </S.CardFooter>
        )}
      </S.Card>
    </CardWrapper>
  )
}

export default CategoryCard
