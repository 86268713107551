// https://github.com/sarneeh/reaptcha/tree/master/lib
// https://github.com/sarneeh/reaptcha/blob/master/lib/index.js
// package doesnt work properly in this yarn setup
// extracted at version 1.7.2
import React, { Component } from 'react'

import { ReptchaProps, ReptchaState, RecaptchaConfig } from './types'

const injectScript = (scriptSrc: string) => {
  const script = document.createElement('script')

  script.async = true
  script.defer = true
  script.src = scriptSrc

  if (document.head) {
    document.head.appendChild(script)
  }
}

const isAnyScriptPresent = (regex: RegExp) =>
  Array.from(document.scripts).reduce((isPresent, script) => (isPresent ? isPresent : regex.test(script.src)), false)

const RECAPTCHA_SCRIPT_URL = 'https://recaptcha.net/recaptcha/api.js'
const RECAPTCHA_SCRIPT_REGEX = /(http|https):\/\/(www)?.+\/recaptcha/

class Reptcha extends Component<ReptchaProps, ReptchaState> {
  container = null

  constructor(props: ReptchaProps) {
    super(props)

    this.state = {
      instanceId: null,
      ready: false,
      rendered: false,
      invisible: this.props.size === 'invisible',
      timer: null
    }
  }

  static defaultProps = {
    id: '',
    className: 'g-recaptcha',
    theme: 'light',
    size: 'normal',
    badge: 'bottomright',
    tabindex: 0,
    explicit: false,
    inject: true,
    isolated: false,
    hl: ''
  }

  _isAvailable = (): boolean => Boolean(window && window.grecaptcha && window.grecaptcha.ready)

  _inject = (): void => {
    if (this.props.inject && !isAnyScriptPresent(RECAPTCHA_SCRIPT_REGEX)) {
      const hlParam = this.props.hl ? `&hl=${this.props.hl}` : ''
      const src = `${RECAPTCHA_SCRIPT_URL}?render=explicit${hlParam}`
      injectScript(src)
    }
  }

  _prepare = (): void => {
    const { explicit, onLoad } = this.props
    window.grecaptcha.ready(() => {
      this.setState({ ready: true }, () => {
        if (!explicit) {
          this.renderExplicitly()
        }
        if (onLoad) {
          onLoad()
        }
      })
    })
  }

  _renderRecaptcha = (container: JSX.Element, config: RecaptchaConfig): number =>
    window.grecaptcha.render(container, config)

  _resetRecaptcha = (): void => window.grecaptcha.reset(this.state.instanceId)

  _executeRecaptcha = (): void => window.grecaptcha.execute(this.state.instanceId)

  _getResponseRecaptcha = (): void => window.grecaptcha.getResponse(this.state.instanceId)

  _stopTimer = (): void => {
    if (this.state.timer) {
      clearInterval(this.state.timer)
    }
  }

  componentDidMount = (): void => {
    this._inject()

    if (this._isAvailable()) {
      this._prepare()
    } else {
      const timer = setInterval(() => {
        if (this._isAvailable()) {
          this._prepare()
          this._stopTimer()
        }
      }, 500)
      this.setState({ timer })
    }
  }

  shouldComponentUpdate = (nextProps: ReptchaProps): boolean =>
    this.props.className !== nextProps.className || !this.state.rendered

  componentWillUnmount = (): void => {
    this._stopTimer()

    if (this.state.rendered) {
      this._resetRecaptcha()
    }
  }

  renderExplicitly = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (this.state.rendered) {
        return reject(new Error('This recaptcha instance has been already rendered.'))
      }
      if (this.state.ready && this.container) {
        const instanceId = this._renderRecaptcha(this.container, {
          sitekey: this.props.sitekey,
          theme: this.props.theme,
          size: this.props.size,
          badge: this.state.invisible ? this.props.badge : null,
          tabindex: this.props.tabindex,
          callback: this.props.onVerify,
          'expired-callback': this.props.onExpire,
          'error-callback': this.props.onError,
          isolated: this.state.invisible ? this.props.isolated : null,
          hl: this.state.invisible ? null : this.props.hl
        })

        this.setState(
          {
            instanceId,
            rendered: true
          },
          () => {
            if (this.props.onRender) {
              this.props.onRender()
            }
            resolve()
          }
        )
      } else {
        return reject(new Error('Recaptcha is not ready for rendering yet.'))
      }
    })
  }

  reset = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (this.state.rendered) {
        this._resetRecaptcha()
        return resolve()
      }
      reject(new Error('This recaptcha instance did not render yet.'))
    })
  }

  execute = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (!this.state.invisible) {
        return reject(new Error('Manual execution is only available for invisible size.'))
      }
      if (this.state.rendered) {
        this._executeRecaptcha()
        resolve()
      }
      return reject(new Error('This recaptcha instance did not render yet.'))
    })
  }

  getResponse = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      if (this.state.rendered) {
        const response = this._getResponseRecaptcha()
        return resolve(response)
      }
      reject(new Error('This recaptcha instance did not render yet.'))
    })
  }

  render = (): JSX.Element => {
    const container = <div id={this.props.id} className={this.props.className} ref={(e) => (this.container = e)} />

    return this.props.children
      ? this.props.children({
          renderExplicitly: this.renderExplicitly,
          reset: this.reset,
          execute: this.execute,
          getResponse: this.getResponse,
          recaptchaComponent: container
        })
      : container
  }
}

export default Reptcha
