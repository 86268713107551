import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby-plugin-intl'

import { formatTitle } from '../../utils'
import * as S from '../../styles/results'

const Results = ({ formatMessage, searchResults, lastSearch, error }) => (
  <div className="search-results">
    <S.Summary>
      {searchResults.length} {`${formatMessage({ id: 'search.text' })}: ${lastSearch}`}
    </S.Summary>

    {searchResults.length && !error ? (
      <div>
        {searchResults.map((result) => {
          const {
            id,
            attributes: { title, field_metatags, body, path }
          } = result

          return (
            <S.ResultItem key={id}>
              <S.ResultHeader>{formatTitle({ title: field_metatags?.title }) || formatTitle({ title })}</S.ResultHeader>

              <div
                dangerouslySetInnerHTML={{
                  __html: field_metatags?.description || body?.value
                }}
              />

              <Link to={path?.alias || '/'} css={S.resultButton}>
                {`${formatMessage({ id: 'search.see_more' })}`}
              </Link>
            </S.ResultItem>
          )
        })}
      </div>
    ) : (
      <S.NoResult>{`${formatMessage({ id: 'search.no_results_text' })}`}</S.NoResult>
    )}
  </div>
)

Results.propTypes = {
  formatMessage: PropTypes.func.isRequired,
  lastSearch: PropTypes.string,
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        title: PropTypes.string,
        field_metatags: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string
        }),
        body: PropTypes.shape({ value: PropTypes.string }),
        path: PropTypes.shape({ alias: PropTypes.string })
      }).isRequired
    })
  ),
  error: PropTypes.bool
}

export default memo(Results)
