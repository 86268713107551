import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { frame } from 'src/themes'

import { AnswerStylesController } from '../../types'

import { media, sizes, breakpoints } from 'src/themes'

const controlButtonColor = '#e49569'

export const MainHeader = styled.h1`
  ${frame({ color: '#f0956f' })};

  display: inline-block;

  ${media({
    fontSize: ['8vw', '5vw', '2rem']
  })};
`

export const QuestionText = styled.h2`
  font-weight: bold;
  margin: 0 auto;

  ${media({
    fontSize: ['6vw', '3vw', '1.55rem'],
    maxWidth: ['70%', '60%', '70%']
  })};
`

export const AnswersWrapper = styled.section<AnswerStylesController>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${sizes.size4};

  @media (min-width: ${breakpoints.fullhd}) {
    padding: 3.5rem 0 5rem;
  }

  ${({ withoutImages, simpleVariant }) =>
    withoutImages &&
    css`
      justify-content: flex-start;
      padding: 0;

      ${media({
        margin: [
          simpleVariant ? '1rem 0 3rem' : '1rem 0',
          undefined,
          '3.5rem 0 5rem',
          undefined,
          undefined,
          '3.5rem 6rem 5rem'
        ],
        justifyContent: ['center', undefined, simpleVariant ? 'center' : 'flex-start']
      })};
    `}
`

export const Hint = styled.p`
  margin: 0 auto 3%;
  text-align: left;

  ${media({
    maxWidth: ['90%', undefined, undefined, '61%'],
    fontSize: [undefined, '3.5vw', '2.5vw', '0.9rem']
  })};
`

export const ControlButton = styled.button<AnswerStylesController>`
  border-color: ${controlButtonColor};
  color: ${controlButtonColor};
  height: 1.75rem;
  padding-bottom: 0;
  padding-top: 0;

  ${media({
    margin: ['15px 1%', undefined, '15px'],
    width: ['42%', undefined, '20%']
  })};

  ${({ primary }) =>
    primary &&
    css`
      background: rgba(228, 149, 105, 0.3);
    `}

  &:hover {
    background-color: ${controlButtonColor};
    color: white;
  }
`
