import { IntlShape } from 'gatsby-plugin-intl'
import { AnswerData, QuestionData } from '../types'

const answers2 = (intl: IntlShape, INTL_TAG: string): AnswerData[] => [
  {
    questionId: 2,
    id: 'a2',
    text: intl.formatMessage({ id: `${INTL_TAG}.hair_texture.answers.straight` }),
    image: 'quiz-straight-img'
  },
  {
    questionId: 2,
    id: 'b2',
    text: intl.formatMessage({ id: `${INTL_TAG}.hair_texture.answers.wavy` }),
    image: 'quiz-wavy-img'
  },
  {
    questionId: 2,
    id: 'c2',
    text: intl.formatMessage({ id: `${INTL_TAG}.hair_texture.answers.curly` }),
    image: 'quiz-curly-img'
  },
  {
    questionId: 2,
    id: 'd2',
    text: intl.formatMessage({ id: `${INTL_TAG}.hair_texture.answers.coily` }),
    image: 'quiz-coily-img'
  }
]

export default (intl: IntlShape, INTL_TAG: string): QuestionData => ({
  id: 2,
  text: intl.formatMessage({ id: `${INTL_TAG}.hair_texture.question` }),
  answers: answers2(intl, INTL_TAG)
})
