import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'

import useTracking from 'src/hooks/useTracking'
import { createPageData } from 'src/utils/tracking'
import { getMetaTitle, getSortedData, getDistinguishedData } from 'src/utils/shared'
import { Divider, SitemapWrapper } from './styles/styled'

const Sitemap = ({ pageContext, data }) => {
  const { locale } = useIntl()

  const { title, field_breadcrumb: breadcrumbs, field_metatag_normalized } = data.nodePage

  const categories = [...data.content.nodes]

  const dataStructure = getSortedData(categories)
  const { withSubs, noSubs } = getDistinguishedData(dataStructure)

  const pageTitle = title
  const pageBreadcrumb = breadcrumbs
  const pageSEO = field_metatag_normalized

  useTracking({
    data: createPageData({
      pageId: pageTitle,
      breadCrumbs: `home:${locale}:sitemap`,
      pageType: pageTitle.toLowerCase(),
      instanceName: pageTitle.toLowerCase(),
      primaryCategory: pageTitle.toLowerCase(),
      language: locale,
      pageTitle: getMetaTitle(pageSEO),
      pageName: pageContext.slug
    })
  })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={pageBreadcrumb}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={pageSEO} lang={pageContext.intl?.language} />
      <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
        <div className="columns has-margin-bottom-2">
          <h1 className="column is-3-tablet is-2-widescreen title__page">{title}</h1>
        </div>
        <SitemapWrapper className="container is-max-widescreen columns">
          <div className="column is-centered has-margin-left-4__nonmobile">
            {withSubs.map((category) => (
              <div className="has-margin-top-1 has-margin-bottom-3" key={category.id}>
                <div className="has-margin-bottom-2">
                  <div className="is-inline-block">
                    <Link className="is-size-5 has-text-weight-bold" to={`/${locale}${category.link.uri_alias}`}>
                      {category.title}
                    </Link>
                  </div>
                </div>
                <ul className="rows">
                  {category.children.map((subCategory) => (
                    <li className="row has-margin-left-1__nonmobile has-margin-bottom-1" key={subCategory.id}>
                      <div className="is-inline-block">
                        <Link className="is-size-6" to={`/${locale}${subCategory.link.uri_alias}`}>
                          {subCategory.title}
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <Divider />
          <div className="column has-margin-left-4__nonmobile">
            <div className="rows">
              {noSubs.map((category) => (
                <div className="has-margin-top-1 has-margin-bottom-2" key={category.id}>
                  <div className="is-inline-block">
                    <Link className="is-size-5 has-text-weight-bold" to={`/${locale}${category.link.uri_alias}`}>
                      {category.title}
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </SitemapWrapper>
      </Hero>
    </Layout>
  )
}

export default Sitemap

Sitemap.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.shape({
    nodePage: PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.shape({
        alias: PropTypes.string.isRequired
      }),
      body: PropTypes.shape({ value: PropTypes.string }),
      field_breadcrumb: PropTypes.arrayOf(PropTypes.object),
      field_metatag_normalized: PropTypes.arrayOf(PropTypes.object)
    }),
    content: PropTypes.shape({
      nodes: PropTypes.shape({
        title: PropTypes.string,
        langcode: PropTypes.string,
        map: PropTypes.func,
        link_override: PropTypes.shape({
          uri: PropTypes.string,
          title: PropTypes.string
        }),
        link: PropTypes.shape({
          uri: PropTypes.string,
          uri_alias: PropTypes.string,
          options: PropTypes.shape({
            fa_icon: PropTypes.object,
            attributes: PropTypes.shape({
              target: PropTypes.object
            })
          })
        }),
        id: PropTypes.string,
        weight: PropTypes.number
      })
    })
  })
}

export const query = graphql`
  query Sitemap($slug: String, $langKey: String) {
    nodePage(field_component: { eq: "sitemap" }, path: { alias: { eq: $slug } }, langcode: { eq: $langKey }) {
      ...PageDataFragment
    }
    content: allMenuLinkContentMenuLinkContent(
      filter: { menu_name: { eq: "sitemap" }, enabled: { eq: true }, langcode: { eq: $langKey } }
      sort: { fields: external, order: ASC }
    ) {
      nodes {
        title
        langcode
        link_override {
          uri
          title
        }
        link {
          uri
          uri_alias
          options {
            fa_icon
            attributes {
              target
            }
          }
        }
        id
        weight
      }
    }
  }
`
