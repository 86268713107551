import layoutIcons from 'src/assets/images/layout-icons.svg'
import { mapLocaleToISOCountry } from './utils'

export const buildHomeSchema = (url, locale) => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url,
  parentOrganization: 'Wella Company',
  foundingDate: '1st of September 2019',
  brand: 'weDo/ Professional',
  logo: {
    '@type': 'ImageObject',
    url: `${process.env.GATSBY_SITE_URL}${layoutIcons}#icon-logo-wedo`
  },
  areaServed: {
    '@type': 'geoShape',
    addressCountry: mapLocaleToISOCountry(locale)
  },
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Petit-Lancy, Switzerland',
    postalCode: '1213',
    streetAddress: 'Chemin Louis Hubert 1-3'
  }
})
