import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import Reptcha from './Reptcha'

import { isBrowser } from 'src/utils/shared'

const Recaptcha = ({ lang = 'en', onVerify, onExpire, sitekey, isInvalid, errorMessage }, ref) =>
  isBrowser && (
    <div className="field">
      <Reptcha sitekey={sitekey} onVerify={onVerify} onExpire={onExpire} hl={lang} ref={ref} />
      {isInvalid && <p className="help is-danger">{errorMessage}</p>}
    </div>
  )

export default forwardRef(Recaptcha)

Recaptcha.propTypes = {
  lang: PropTypes.string,
  onVerify: PropTypes.func.isRequired,
  onExpire: PropTypes.func.isRequired,
  sitekey: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool,
  errorMessage: PropTypes.string
}
