import React, { FC } from 'react'

import Hero from 'src/components/Hero'
import BlogCard from 'src/components/BlogCard'

import { BlogListProps } from './types'

const BlogList: FC<BlogListProps> = ({ list = [], header }) => {
  if (!list.length) {
    return null
  }

  return (
    <Hero hero="blog-cards recent-blog-cards">
      <div className="has-margin-bottom-2">
        <div className="is-uppercase has-text-weight-bold">{header}</div>
      </div>
      <div data-test="weblog-recent-section" className="columns is-multiline is-mobile">
        {list?.map(({ id, ...blog }) => (
          <div className="column is-half-mobile is-one-third-tablet is-one-quarter-widescreen" key={id}>
            <BlogCard {...blog} key={id} isFrameless />
          </div>
        ))}
      </div>
    </Hero>
  )
}

export default BlogList
