import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from 'src/layouts'
import SEO from 'src/components/SEO'
import Hero from 'src/components/Hero'

import useTracking from 'src/hooks/useTracking'
import { createPageData } from 'src/utils/tracking'
import { getMetaTitle, formImageBaseData } from 'src/utils/shared'

import Form from './sections/Form'

import * as S from './styles/styled'
import { formImageStyles } from 'src/themes'

import type { SubscribePageProps } from './types'

const SubscribePage: FC<SubscribePageProps> = ({ pageContext, data }) => {
  const intl = useIntl()

  const {
    nodePage: {
      field_breadcrumb: breadcrumbs,
      field_metatag_normalized: metaTags,
      title,
      body: { value: desc }
    },
    contactImgLocalFile,
    contactImgPopupLocalFile,
    countriesList
  } = data

  const contactImage = {
    alt: intl.formatMessage({ id: 'images.subscribe.contact' }),
    localFile: contactImgLocalFile
  }

  useTracking({
    data: createPageData({
      pageId: 'subscribe',
      breadCrumbs: 'subscribe',
      pageType: 'subscribe page',
      instanceName: 'subscribe page',
      primaryCategory: 'subscribe',
      language: intl.locale,
      pageTitle: getMetaTitle(metaTags),
      pageName: pageContext.slug
    })
  })

  return (
    <Layout pageUrls={pageContext.pageUrls} breadcrumbs={breadcrumbs}>
      <SEO pageUrls={pageContext.pageUrls} fetchedMetaTags={metaTags} lang={pageContext.intl.language} />
      <div className="wedo-form-page">
        <Hero heroBody="has-padding-top-2 has-padding-bottom-0 has-padding-bottom-2__nonmobile">
          <div className="columns">
            <h1 className="column is-3-tablet is-2-widescreen title__page">{title}</h1>
            <div className="column is-5-tablet is-4-widescreen" dangerouslySetInnerHTML={{ __html: desc }} />
            <div className="column is-4 is-hidden-mobile">
              <GatsbyImage css={formImageStyles} {...formImageBaseData({ image: contactImage })} />
            </div>
          </div>
        </Hero>
        <Hero hero="is-fourth" heroBody="has-padding-top-2 has-padding-bottom-2" customCSS={S.hero}>
          <div className="is-hidden-tablet">
            <GatsbyImage css={formImageStyles} {...formImageBaseData({ image: contactImage })} />
          </div>
          <div className="columns is-centered">
            <div className="column is-offset-3-tablet is-offset-0-widescreen is-9-tablet is-8-widescreen">
              <div>
                <Form
                  intl={intl}
                  countries={countriesList?.countries}
                  language={pageContext.intl.language}
                  contactImgPopup={{
                    localFile: contactImgPopupLocalFile,
                    alt: intl.formatMessage({ id: 'images.subscribe.modal' })
                  }}
                />
              </div>
            </div>
          </div>
        </Hero>
      </div>
    </Layout>
  )
}

export default SubscribePage

export const query = graphql`
  query SubscribePage($langKey: String!, $slug: String!) {
    nodePage(path: { alias: { eq: $slug } }, field_component: { eq: "subscribe" }, langcode: { eq: $langKey }) {
      ...PageDataFragment
    }
    countriesList {
      countries {
        label
        value
        id
      }
    }
    contactImgLocalFile: file(relativePath: { eq: "contact_photo_v3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 50
          webpOptions: { quality: 55 }
          outputPixelDensities: [0.25, 0.5, 0.6, 0.75, 1]
          formats: [JPG, WEBP]
        )
      }
    }
    contactImgPopupLocalFile: file(relativePath: { eq: "contact_popup2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 50
          webpOptions: { quality: 55 }
          outputPixelDensities: [0.25, 0.5, 0.6, 0.75, 1]
          formats: [JPG, WEBP]
        )
      }
    }
  }
`
