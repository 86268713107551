export const getProperShops = (shops, country) => shops.filter(({ field_wtb_country }) => field_wtb_country === country)

export const getSortedShops = ({ countriesList, shops }) =>
  countriesList?.reduce((total, country) => {
    const properShops = getProperShops(shops, country)

    return [...total, { country, shops: properShops }]
  }, [])

export const getListToRender = ({ hasCountryInList, shopsList }) => {
  if (!hasCountryInList) {
    return shopsList
  }

  return shopsList?.filter(
    ({
      relationships: {
        field_wtb_retailer_reference: { name }
      }
    }) => !name
  )
}

export const getShopsPerCountry = (shopsList) => {
  if (!shopsList) {
    return []
  }

  return shopsList?.filter(
    ({
      field_wtb_country,
      relationships: {
        field_wtb_retailer_reference: { name }
      }
    }) => field_wtb_country && name
  )
}

export const INTL_TAG = 'product_page'
