import React, { useState, useCallback, useMemo, memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, useIntl } from 'gatsby-plugin-intl'

import classNames from 'classnames'

import { quizMarkets } from '../../consts/consts'

import { getMenuItems } from 'src/utils/layout'
import { isBrowser } from 'src/utils/shared'
import usePositionY from 'src/hooks/usePositionY'

import SocialMedia from './SocialMedia'
import CountrySelector from './CountrySelector'
import Search from './Search'

const HTML_TAG_CLASS = isBrowser && document.documentElement.classList

const Header = ({ navItems, socialMediaData, isNavAlpha, pageUrls, sfPath, searchPath, iconLogo }) => {
  const [showMenu, toggleMenu] = useState(false)
  const [{ isListOpened, selectedList }, setClickedList] = useState({ isListOpened: false, selectedList: '' })
  const intl = useIntl()

  let isAlphaAndScrolled = false
  if (isNavAlpha) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const positionY = usePositionY()
    isAlphaAndScrolled = (isBrowser && isNavAlpha && positionY < 100) || (!isBrowser && isNavAlpha)
  }

  useEffect(() => {
    return () => {
      if (HTML_TAG_CLASS.contains('is-clipped')) {
        HTML_TAG_CLASS.remove('is-clipped')
      }
    }
  }, [])

  const navClassName = useMemo(
    () =>
      classNames({
        'navbar is-primary is-fixed-top': true,
        'is-active': showMenu,
        'is-alpha': isAlphaAndScrolled
      }),
    [showMenu, isAlphaAndScrolled]
  )

  // home
  const { bannerTitle: homeTitle } = getMenuItems({ locale: intl.locale, navItems, bannerUri: 'entity:node/1' })
  // products
  const { bannerTitle: productsBanner, bannerLink: productsLink, items: productsItems } = getMenuItems({
    locale: intl.locale,
    navItems,
    bannerUri: 'entity:node/2'
  })

  // blogs page
  const { bannerTitle: blogsBanner, bannerLink: blogsLink } = getMenuItems({
    locale: intl.locale,
    navItems,
    bannerUri: 'entity:node/9'
  })

  // natural haircare
  const { bannerTitle: hairCareBanner, bannerLink: hairCareLink } = getMenuItems({
    locale: intl.locale,
    navItems,
    bannerUri: process.env.GATSBY_ENV_TYPE === 'production' ? 'entity:node/133' : 'entity:node/131'
  })

  // our story
  const { bannerTitle: ourStoryBanner, bannerLink: ourStoryLink, items: ourStoryItems } = getMenuItems({
    locale: intl.locale,
    navItems,
    bannerUri: 'entity:node/10'
  })

  // we talk
  const { bannerTitle: weTalkBanner, bannerLink: weTalkLink } = getMenuItems({
    locale: intl.locale,
    navItems,
    bannerUri: 'entity:node/74'
  })

  // contact us
  const { bannerTitle: contactUsBanner, bannerLink: contactUsLink } = getMenuItems({
    locale: intl.locale,
    navItems,
    bannerUri: 'entity:node/15'
  })

  const handleListClick = ({ newListName }) =>
    setClickedList({
      isListOpened: selectedList !== newListName ? true : !isListOpened,
      selectedList: newListName
    })

  const handleBurgerClick = useCallback(() => {
    HTML_TAG_CLASS.toggle('is-clipped')
    if (isListOpened) {
      setClickedList({ isListOpened: false, selectedList: '' })
    }
    toggleMenu(!showMenu)
  }, [showMenu, isListOpened])

  const handleProductsClick = () => handleListClick({ newListName: 'Products' })
  const handleOurStoryClick = () => handleListClick({ newListName: 'OurStory' })
  const handleCountrySelectorClick = () => handleListClick({ newListName: 'CountrySelector' })

  return (
    <nav className={navClassName}>
      <div className="container is-fluid">
        <div className="navbar-brand">
          <span
            className={`navbar-burger ${showMenu ? 'is-active' : ''}`}
            data-target="navMenu"
            onClick={handleBurgerClick}
          >
            <span />
            <span />
            <span />
          </span>
          <SocialMedia className="social-top" data={socialMediaData} />
          <Link to="/" className="navbar-item is-hidden-desktop">
            <svg fill="#fff" className="icon-logo-wrapper">
              <use className="icon-logo-wedo" xlinkHref={iconLogo} />
            </svg>
          </Link>
          <Search locale={intl.locale} searchPath={searchPath} formatMessage={intl.formatMessage} />
        </div>
        <div className="navbar-logo is-hidden-touch">
          <Link to="/" className="navbar-item">
            <svg fill="#fff" className="icon-logo-wrapper">
              <use className="icon-logo-wedo" xlinkHref={iconLogo} />
            </svg>
          </Link>
          <Link to="/" className="is-hidden-desktop navbar-item is-active">
            {homeTitle}
          </Link>
        </div>
        <div className="navbar-end is-hidden-touch">
            <Search locale={intl.locale} searchPath={searchPath} formatMessage={intl.formatMessage} />
          {intl.locale === 'de-de' ? 
            <a href="https://salons.wedoact.com/salon-search?wedo-professional" className="navbar-link is-hidden-touch is-arrowless is-nowrap">Salonfinder</a> 
            :
            <Link
              to={sfPath}
              className="navbar-link is-hidden-touch is-arrowless is-nowrap"
              data-test="nav-store-locator-link">
              {intl.formatMessage({ id: 'menu.salonfinder' })}
            </Link>
          }


          <CountrySelector
            isSelectorOpen={isListOpened && selectedList === 'CountrySelector'}
            onListClick={handleCountrySelectorClick}
            pageUrls={pageUrls}
          />
          <div className="is-hidden-desktop header-social navbar-item">
            <SocialMedia data={socialMediaData} />
            <p className="has-text-weight-bold">@wedo_act</p>
          </div>
        </div>
        <div className={`navbar-menu ${showMenu ? 'is-active ' : ''}`}>
          <div className="navbar-start">
            <Link to="/" className="is-hidden-desktop navbar-item is-active">
              {homeTitle}
            </Link>
            {productsBanner && (
              <div
                className={`navbar-item has-dropdown is-hoverable ${isListOpened && selectedList === 'Products' ? 'is-opened' : ''
                  }`}
              >
                <Link data-test="nav-products" to={productsLink} className="navbar-link is-hidden-touch">
                  {productsBanner}
                </Link>

                <div className="navbar-link is-hidden-desktop" onClick={handleProductsClick}>
                  {productsBanner}
                </div>
                <div className="navbar-dropdown">
                  {productsItems.map(({ id, link, title }) => (
                    <Link key={id} to={link.uri_alias} className="navbar-item">
                      {title}
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {quizMarkets.includes(intl.locale) && (
              <Link to="/hair-quiz/" className="navbar-item">
                {intl.formatMessage({ id: 'menu.hair_quiz' })}
              </Link>
            )}

            {ourStoryBanner && (
              <div
                className={`navbar-item has-dropdown is-hoverable ${isListOpened && selectedList === 'OurStory' ? 'is-opened' : ''
                  }`}
              >
                <Link to={ourStoryLink} className="navbar-link is-hidden-touch">
                  {ourStoryBanner}
                </Link>
                <div className="navbar-link is-hidden-desktop" onClick={handleOurStoryClick}>
                  {ourStoryBanner}
                </div>

                <div className="navbar-dropdown">
                  {ourStoryItems.map(({ id, link, title }) => (
                    <Link key={id} to={link.uri_alias} className="navbar-item">
                      {title}
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {hairCareBanner && (
              <Link to={hairCareLink} className="navbar-item">
                {hairCareBanner}
              </Link>
            )}

            {blogsBanner && (
              <Link to={blogsLink} className="navbar-item">
                {blogsBanner}
              </Link>
            )}

            {weTalkBanner && (
              <Link to={weTalkLink} className="navbar-item">
                {weTalkBanner}
              </Link>
            )}

            {contactUsBanner && (
              <Link to={contactUsLink} className="navbar-item">
                {contactUsBanner}
              </Link>
            )}
          </div>
          <div className="navbar-end is-hidden-desktop">
            {intl.locale === 'de-de' ?
              <a href="https://salons.wedoact.com/salon-search?wedo-professional" className="navbar-item is-arrowless">Salonfinder</a>
              :
              <Link to={sfPath} className="navbar-item is-arrowless">
                {intl.formatMessage({ id: 'menu.salonfinder' })}
              </Link>
            }

            <CountrySelector
              isSelectorOpen={isListOpened && selectedList === 'CountrySelector'}
              onListClick={handleCountrySelectorClick}
              pageUrls={pageUrls}
            />
            <div className="is-hidden-desktop header-social navbar-item">
              <SocialMedia data={socialMediaData} />
              <p className="has-text-weight-bold">@wedo_act</p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default memo(Header)

Header.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  socialMediaData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      link: PropTypes.shape({
        uri: PropTypes.shape.isRequired,
        options: PropTypes.shape({
          fa_icon: PropTypes.string.isRequired,
          attributes: PropTypes.shape({
            target: PropTypes.string
          })
        }).isRequired
      }).isRequired
    })
  ).isRequired,
  isNavAlpha: PropTypes.bool,
  pageUrls: PropTypes.arrayOf(
    PropTypes.shape({
      hreflang: PropTypes.string,
      href: PropTypes.string
    })
  ).isRequired,
  sfPath: PropTypes.string,
  searchPath: PropTypes.string,
  locale: PropTypes.string,
  iconLogo: PropTypes.string.isRequired
}
