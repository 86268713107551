export const metaTags = [
  {
    attributes: {
      content: 'Page not found on the WeDo Professionals website',
      name: 'title'
    }
  },
  {
    attributes: {
      content: "Sorry, but we can't find the page you're looking for. It might have been moved or deleted.",
      name: 'description'
    }
  }
]
