import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

import { colors } from 'src/themes'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Loader = styled.div`
  margin: 20px auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${colors.orange};
  background: linear-gradient(to right, ${colors.orange} 10%, rgba(0, 0, 0, 0) 42%);
  position: relative;
  animation: ${spin} 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: ${colors.white};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`
