import React, { FC, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import _debounce from 'lodash.debounce'

import Quiz from 'src/components/Quiz'

import { WeDoQuizProps } from 'src/components/Quiz/types'


const HairQuiz: FC<WeDoQuizProps> = ({ data }) => {
  const intl = useIntl()
  const [screenNumber, setScreenNumber] = useState()

  useEffect(() => {
    const postBodySize = () => {
      window.parent.postMessage(
        {
          type: 'resize',
          value: window.document.body.offsetHeight + 60
        },
        '*'
      )
    }
    const trackBodyHeight = _debounce(postBodySize)

    window.addEventListener('resize', trackBodyHeight)

    const sendTimedOutHeight = setTimeout(() => {
      postBodySize()
    }, 100)

    return () => {
      window.removeEventListener('resize', trackBodyHeight)
      clearTimeout(sendTimedOutHeight)
    }
  }, [screenNumber])

  return (
    <>
      <Quiz iframeController={setScreenNumber} isIframe data={data} intl={intl} />
    </>
  )
}

export default HairQuiz

export const query = graphql`
  query WeDoQuizIFrame(
    $langKey: String!
    $productVariantHeight: Int! = 483
    $productVariantWidth: Int! = 364
    $productVariantQuality: Int! = 80
    $isRelatedProductsIngredient: Boolean! = false
    $includeProductCategoryDrupalId: Boolean! = true
  ) {
    allNodeProduct(filter: { langcode: { eq: $langKey } }, sort: { fields: drupal_id }) {
      ...RelatedProductsFragment
    }
    # QUIZ IMAGE MOBILE
    startScreenImgMobile: file(relativePath: { eq: "quiz-start-screen--mobile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 75
          width: 769
          webpOptions: { quality: 78 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    # QUIZ IMAGE DESKTOP
    startScreenImgDesktop: file(relativePath: { eq: "quiz-start-screen--desktop.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 80
          width: 1920
          webpOptions: { quality: 82 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }

    quizImages: allFile(filter: { relativePath: { regex: "/quiz//" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 80
            width: 300
            webpOptions: { quality: 82 }
            formats: [AUTO, WEBP]
            outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
          )
        }
      }
    }
  }
`
