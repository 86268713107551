import React, { FC } from 'react'
import { Link } from 'gatsby-plugin-intl'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as S from './styled'
import { formImageBaseData } from 'src/utils/shared'

import { BlogCardProps } from './types'

const BlogCard: FC<BlogCardProps> = ({ 
  title, 
  path, 
  relationships, 
  isBigCard, 
  isFrameless, 
  ratio, 
  field_po_image: image_opts
}) => {
  const {
    field_po_category: {
      title: category,
      field_pc_color: { color }
    },
    field_po_image
  } = relationships

  const image = formImageBaseData({ image: field_po_image, useNewPlugin: true, aspectRatio: ratio })

  return (
    <Link to={path.alias} data-test="weblog-category-card" css={S.animatedLink}>
      <GatsbyImage {...image} alt={image_opts?.alt}/>
      <S.ContentWrapper isBigCard={isBigCard}>
        <S.Category data-test="blog-card-header" isBigCard={isBigCard}>
          /{category}
        </S.Category>
        <S.TitleWrapper>
          <S.TitleFrame isFrameless={isFrameless}>
            <S.Title isBigCard={isBigCard} isFrameless={isFrameless}>
              {title}
            </S.Title>
          </S.TitleFrame>
        </S.TitleWrapper>
        <S.Border isBigCard={isBigCard} color={color} />
      </S.ContentWrapper>
    </Link>
  )
}

export default BlogCard
