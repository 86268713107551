import React, { memo } from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import FontCenturyGothicRg2 from 'src/styles/fonts/Century_Gothic_Rg.woff2'
import FontCenturyGothicIt2 from 'src/styles/fonts/Century_Gothic_It.woff2'
import FontCenturyGothicItBd2 from '../../styles/fonts/Century_Gothic_ItBd.woff2'
import { buildFontPreloadData, getPageUrl, addConnectedUrls } from 'src/utils/shared'

import { SEOProps, DefaultMeta } from './types'

const FONTS = buildFontPreloadData([FontCenturyGothicRg2, FontCenturyGothicIt2, FontCenturyGothicItBd2])

const SEO = ({ fetchedMetaTags, lang, ogData, pageUrls = [{}], customMetaTags = [{}], schema }: SEOProps) => {
  const {
    site: { siteMetadata: defaultMeta }
  }: DefaultMeta = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const completedPageUrls = addConnectedUrls({ pageUrls, connections: [['en-au', 'en-nz']] })

  const hrefLangs = completedPageUrls.length
    ? completedPageUrls
        .filter(({ hreflang }) => hreflang !== 'en' && hreflang !== 'ru-ru')
        .map(({ href, hreflang, connectedHreflang }) => ({
          rel: 'alternate',
          href: getPageUrl({ href, hreflang, connectedHreflang }),
          hreflang
        }))
    : []

  const url = hrefLangs.find((hreflang) => hreflang.hreflang === lang)?.href
  const canonical = url ? { rel: 'canonical', key: url, href: url } : {}

  const defaultLang = hrefLangs.find(({ hreflang }) => hreflang === 'en-gb')
  const langsWithDefault = hrefLangs.length ? [...hrefLangs, { ...defaultLang, hreflang: 'x-default' }] : []

  const ogImages = ogData.img
    ? [
        { name: 'og:image', content: ogData.img },
        { name: 'twitter:image', content: ogData.img }
      ]
    : []

  const fetchedDescription = fetchedMetaTags.find(({ attributes }) => attributes.name === 'description')
  const description = fetchedDescription?.attributes.content || defaultMeta.description

  const fetchedTitle = fetchedMetaTags.find(({ attributes }) => attributes.name === 'title')
  const title = fetchedTitle?.attributes.content || defaultMeta.title

  const fetchedKeywords = fetchedMetaTags.find(({ attributes }) => attributes.name === 'keywords')
  const keywords = fetchedKeywords?.attributes.content || defaultMeta.title

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: description
        },
        {
          name: 'keywords',
          content: keywords
        },
        {
          property: `og:title`,
          content: ogData.title || title
        },
        {
          property: `og:description`,
          content: ogData.description || description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: 'weDo'
        },
        {
          name: `twitter:title`,
          content: ogData.title || title
        },
        {
          name: `twitter:description`,
          content: ogData.description || description
        },
        {
          name: 'google-site-verification',
          content: '1N5K3oI2XWeGDe691prmk7hk6UtHvWIDQd7qE9Smepk'
        },
        ...ogImages,
        ...customMetaTags
      ]}
      link={[...langsWithDefault, ...FONTS, canonical]}
    >
      {schema && process.env.GATSBY_WC3_SCHEMA && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
    </Helmet>
  )
}

SEO.whyDidYouRender = {
  logOnDifferentValues: true
}

SEO.defaultProps = {
  lang: `en-gb`,
  fetchedMetaTags: [{ attributes: { name: '', content: '' } }],
  ogData: {}
}

export default memo(SEO)
