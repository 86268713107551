import React, { FC } from 'react'

import { getProgressPercentage } from '../utils'

import * as S from './styles/progressBar'

import { ProgressBarProps } from './types/progressBar'

const INTL_TAG = 'quiz.progress_bar'

const ProgressBar: FC<ProgressBarProps> = ({ cap, currentCheckpoint, intl, isIframe }) => {
  const progressPercentage = getProgressPercentage({ cap, currentCheckpoint })

  return (
    <S.BarWrapper>
      <S.BarContainer isIframe={isIframe}>
        <S.BarProgress progressPercentage={progressPercentage} />
      </S.BarContainer>
      <S.ProgressText>
        {progressPercentage}% {intl.formatMessage({ id: `${INTL_TAG}` })}
      </S.ProgressText>
    </S.BarWrapper>
  )
}

export default ProgressBar
