import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { SerializedStyles } from '@emotion/utils'

import { colors, mq, sizes, transition, zIndexes, breakpoints } from 'src/themes'

import { ArrowDirectionController, Direction } from './types'

const photoDotSize = '85px'
const thumbArrowMargin = '9px'

export const PhotoDot = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: ${photoDotSize};
  height: ${photoDotSize};
  background: ${colors.light};
  transition: ${transition};

  .swiper-slide-active {
    opacity: 0.4;
  }

  .swiper-slide-thumb-active {
    box-sizing: border-box;
    border: 1px solid lightgrey;
    opacity: 1;
  }
`

const arrow = ({ direction }: ArrowDirectionController) => css`
  ${mq(direction)}

  &:hover {
    transform: translate(${direction.left ? '-5px' : '5px'}, -50%);
  }
`

export const swiperStyle = ({ productBannerDesktop }: { productBannerDesktop: boolean }): SerializedStyles => css`
  ${productBannerDesktop &&
  css`
    display: flex;
  `};
  position: relative;

  .swiper-banner {
    height: 100vh;
  }

  .swiper-button-next {
    width: 20px;
    height: 20px;
    z-index: 20;
  }

  .swiper-pagination {
    position: absolute;
    text-align: center;
    margin: 0 auto;
    height: 20px;
    z-index: 2;
    bottom: 110px;
    width: 100%;
  }

  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    margin: 0 5px;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    z-index: 2;
    position: relative;
    background-color: ${colors.white};

    &::before {
      font-family: inherit;
      color: ${colors.white};
      display: hidden;
      width: 20px;
      height: 20px;
      margin: 0 auto;
      position: absolute;
      top: 0px;
      left: 0px;
      font-size: 21px;
      line-height: 21px;
      opacity: 0.75;
      content: '\\2022';
    }
  }

  .swiper-pagination-bullet-active {
    &::before {
      display: inline-block;
      color: ${colors.orange};
    }
  }

  .swiper-container {
    display: flex;

    .swiper-arrow {
      font-size: ${sizes.size5};
      color: ${colors.dark};
      transition: ${transition};
      opacity: 0.8;
      z-index: ${zIndexes.zIndex10};
      position: absolute;
      top: 50%;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
      transform: translateY(-50%);
      cursor: pointer;
      border: none;

      &:hover {
        color: ${colors.dark};
        opacity: 1;
      }

      &.swiper-prev {
        ${arrow({ direction: { left: ['-1rem', '-1.5rem'] } })}
      }

      &.swiper-prev-one-third {
        ${arrow({ direction: { left: ['-1rem', '28%'] } })}
      }

      &.swiper-next {
        ${arrow({ direction: { right: ['-1rem', '-1.5rem'] } })}
      }

      &.swiper-next-one-third {
        ${arrow({ direction: { right: ['-1rem', '28%'] } })}
      }

      &::before {
        content: '';
        display: none;
      }
    }
  }

  .swiper-wrapper {
    .swiper-slide {
      height: auto;
      transition: opacity 0.2s ease-out;
      opacity: 0.4;
      header,
      footer,
      h3,
      .media-content {
        opacity: 0;
      }
    }

    .swiper-slide-visible {
      opacity: 1;
      header,
      footer,
      h3,
      .media-content {
        transition: all 0.2s ease-out;
        opacity: 1;
      }
    }

    .swiper-product {
      @media (max-width: ${breakpoints.tablet}) {
        margin: auto;
      }
      @media (max-width: ${breakpoints.widescreen}) {
        height: 60%;
      }
    }

    .swiper-slide-active {
      opacity: 1;
      .media-content,
      h3 {
        transition: all 0.2s ease-out;
        opacity: 1;
      }
    }
  }

  .swiper-transition {
    transition-property: all;
    transition: all 2s ease-out;
  }

  .swiper-button-disabled {
    display: none !important;
  }

  .swiper-dots {
    width: ${photoDotSize};
    height: ${photoDotSize};

    &:hover {
      opacity: 0.8;
    }
  }

  .dots-container {
    width: 85px;
    height: 520px;
    float: left;
    display: flex;
    z-index: 2;

    .swiper-slide-active {
      opacity: 0.4;
    }

    .swiper-slide-thumb-active {
      overflow: hidden;
      box-sizing: border-box;
      border: 1px solid lightgrey;
      opacity: 1;
    }
  }

  .swiper-dots {
    width: ${photoDotSize};
    height: ${photoDotSize};

    &:hover {
      opacity: 0.8;
    }
  }
`

export const mainSwiperStyles = ({
  isProductCarousel,
  isDesktop
}: {
  isProductCarousel: boolean
  isDesktop: boolean
}): SerializedStyles => css`
  ${isProductCarousel && isDesktop
    ? {
        overflow: 'hidden',
        alignItems: 'flex-start',
        marginLeft: '10px',
        marginTop: '25px'
      }
    : {
        overflow: 'visible'
      }}
`

export const thumbsCarouselWrapperStyles = css`
  display: flex;
  flex-direction: column;
`

export const navButtonStyles = ({
  direction,
  productGalleryNav
}: {
  direction: Direction
  productGalleryNav?: boolean
}): SerializedStyles => css`
  width: 0.875em;
  cursor: pointer;

  ${productGalleryNav &&
  css`
    width: 85px;
    height: 16px;

    ${
      direction === 'up' &&
      css`
        margin-bottom: ${thumbArrowMargin};
      `
    }

    ${
      direction === 'down' &&
      css`
        margin-top: ${thumbArrowMargin};
      `
    }

    @media(max-width: ${breakpoints.tablet}) {
      position: absolute;
      width: 20px;
      top: 50%;
      z-index: ${zIndexes.zIndex10};

      ${
        direction === 'right' &&
        css`
          right: 0;
          margin-right: -3.5%;
        `
      }
      ${
        direction === 'left' &&
        css`
          left: 0;
          margin-left: -3.5%;
        `
      }}
    }
  `}
`

export const navButtonWrapper = css`
  display: block;
  display: flex;
  justify-content: center;
`
