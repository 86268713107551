import { useState, useEffect } from 'react'
import _debounce from 'lodash.debounce'

import { isBrowser } from 'src/utils/shared'

const usePositionY = () => {
  const [positionY, setPositionY] = useState(isBrowser && window.pageYOffset)

  const handleScroll = () => setPositionY(isBrowser && window.pageYOffset)

  useEffect(() => {
    window.addEventListener('scroll', _debounce(handleScroll, 100))

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return positionY
}

export default usePositionY
