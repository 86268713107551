import dispatch from 'src/utils/tracking/dispatch'

const contactCompleteEvent = ({ action }) => {
  const eventName = 'contactComplete'

  const detail = {
    eventName,
    eventAction: 'Contact-us-submit',
    eventType: 'userEvent',
    eventInfo: {
      action: action
    }
  }

  dispatch({
    eventName,
    detail
  })
}

export default contactCompleteEvent
