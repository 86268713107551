import React from 'react'
import PropTypes from 'prop-types'
import Hero from 'src/components/Hero'
import ProductCard from 'src/components/ProductCard'

const RelatedProducts = ({ data, intl }) => {
  return (
    <Hero>
      <div className="has-margin-bottom-4">
        <div className="is-uppercase has-text-weight-bold">{intl.formatMessage({ id: 'shared.you_may_like' })}...</div>
      </div>
      <div className="is-block">
        <div data-test="weblog-recent-section" className="columns is-multiline is-mobile">
          {data.map(({ index, ...card }) => (
            <div className="column is-half-mobile is-one-third-tablet is-one-quarter-widescreen" key={index}>
              <ProductCard {...card} isClickable intl={intl}/>
            </div>
          ))}
        </div>
      </div>
    </Hero>
  )
}

export default RelatedProducts

RelatedProducts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  intl: PropTypes.object
}
