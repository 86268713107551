import dispatch from 'src/utils/tracking/dispatch'

const trackAddToBasketClickEvent = ({
  currency,
  price,
  sku,
  type,
  name,
  colour,
  size,
  retailerName,
  retailerUrl,
  priCategory
}) => {
  const eventName = 'buyNow'

  const detail = {
    eventInfo: {
      eventName,
      eventAction: 'Buy-Now-Intent',
      currency,
      eventType: 'userEvent',
      type,
      productInfo: {
        sku,
        name,
        priCategory,
        variant: {
          size,
          colour
        }
      },
      retailerInfo: {
        retailerName,
        retailerUrl,
        price
      }
    }
  }

  dispatch({
    eventName,
    detail
  })
}

export default trackAddToBasketClickEvent
