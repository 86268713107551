import { css } from '@emotion/react'

import { backgrounds, transition } from 'src/themes'

export const background = css`
  .modal {
    .modal-background {
      background: ${backgrounds.blackAlpha};
      transition: ${transition};
    }
  }
`
