import { graphql } from 'gatsby'

import home from './home'

export default home

export const query = graphql`
  query HomePage(
    $langKey: String!
    $productVariantHeight: Int! = 483
    $productVariantWidth: Int! = 364
    $productVariantQuality: Int! = 80
    $isRelatedProductsIngredient: Boolean! = false
    $includeProductCategoryDrupalId: Boolean! = false
  ) {
    # Banner
    allNodeHomepage(filter: { langcode: { eq: $langKey } }) {
      nodes {
        langcode
        field_hreflang {
          href
          hreflang
        }
        field_metatag_normalized {
          attributes {
            content
            name
          }
        }
        relationships {
          field_sections {
            id
            langcode
            link: field_hi_link {
              title
              uri_alias
              uri
            }
            text: field_hi_text {
              value
            }
            video: field_hi_video {
              uri
            }
            bgVideo: field_hi_video_on_background {
              uri
            }
            field_hi_image {
              alt
            }
            field_hi_image_mobile {
              alt
            }
            relationships {
              field_hi_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 80
                      webpOptions: { quality: 82 }
                      formats: [JPG, WEBP]
                      outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1, 2]
                    )
                  }
                }
              }
              field_hi_image_mobile {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      quality: 75
                      webpOptions: { quality: 78 }
                      formats: [JPG, WEBP]
                      outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1, 2]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
    # What We Do
    allNodeProduct(
      filter: { field_p_show_on_homepage: { eq: true }, status: { eq: true }, langcode: { eq: $langKey } }
      sort: { fields: created, order: DESC }
      limit: 4
    ) {
      ...RelatedProductsFragment
    }
    # BAMBOO IMAGES
    mobileBambooImg: file(relativePath: { eq: "homepage-bamboo--mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 768
          quality: 60
          webpOptions: { quality: 64 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    tabletBambooImg: file(relativePath: { eq: "homepage-bamboo--tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1215
          quality: 70
          webpOptions: { quality: 72 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    desktopBambooImg: file(relativePath: { eq: "homepage-bamboo--desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          quality: 75
          webpOptions: { quality: 78 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
          sizes: "100vw"
        )
      }
    }
    # PRODUCTS IMAGES
    mobileProductsImg: file(relativePath: { eq: "homepage-products--mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 768
          quality: 60
          webpOptions: { quality: 64 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    tabletProductsImg: file(relativePath: { eq: "homepage-products--tablet.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1215
          quality: 70
          webpOptions: { quality: 72 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    desktopProductsImg: file(relativePath: { eq: "homepage-products--desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          quality: 75
          webpOptions: { quality: 78 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
          sizes: "100vw"
        )
      }
    }
    # WHAT WE CREATE IMAGES
    mobileWwcImg: file(relativePath: { eq: "homepage-wwc-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 640
          quality: 60
          webpOptions: { quality: 64 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.25, 0.35, 0.5, 0.75, 1]
        )
      }
    }
    desktopWwcImg: file(relativePath: { eq: "homepage-wwc-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1024
          quality: 70
          webpOptions: { quality: 72 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.25, 0.35, 0.5, 0.75, 1]
        )
      }
    }

    # NATURAL HAIR CARE HP IMAGES
    mobileNhcImg: file(relativePath: { eq: "homepage-nhc-mobile-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 75
          width: 769
          height: 525
          webpOptions: { quality: 78 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    desktopNhcImg: file(relativePath: { eq: "homepage-nhc-desktop-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          quality: 80
          width: 1920
          webpOptions: { quality: 82 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }

    # HANDS IMAGES
    mobileHandsImg: file(relativePath: { eq: "homepage-hands--mobile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 768
          quality: 60
          webpOptions: { quality: 64 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.25, 0.35, 0.5, 0.75, 1]
        )
      }
    }
    desktopHandsImg: file(relativePath: { eq: "homepage-hands--desktop.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1600
          quality: 70
          webpOptions: { quality: 72 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.25, 0.35, 0.5, 0.75, 1]
        )
      }
    }
    # MACADAMIA IMAGE
    wwaMacadamiaImg: file(relativePath: { eq: "homepage-wwa-macadamia.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          width: 231
          quality: 60
          webpOptions: { quality: 64 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    # QUIZ IMAGE MOBILE
    mobileQuizImg: file(relativePath: { eq: "homepage-quiz--mobile.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 75
          width: 769
          height: 525
          webpOptions: { quality: 78 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    # QUIZ IMAGE DESKTOP
    desktopQuizImg: file(relativePath: { eq: "homepage-quiz--desktop.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FIXED
          quality: 80
          width: 1920
          webpOptions: { quality: 82 }
          formats: [AUTO, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }

    # WHO WE ARE IMAGE
    wwaPhotoImg: file(relativePath: { eq: "homepage-wwa-photo-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 75
          webpOptions: { quality: 75 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    # WEDO IMAGES
    wedoReadMoreBlockLeftImg: file(relativePath: { eq: "homepage-wedo-readmore1b.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 60
          webpOptions: { quality: 64 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    wedoReadMoreBlockRightImg: file(relativePath: { eq: "homepage-wedo-readmore2b.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 60
          webpOptions: { quality: 64 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }
    weDoVideoThumbnail: file(relativePath: { eq: "wedo-video-placeholder.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 70
          webpOptions: { quality: 72 }
          formats: [JPG, WEBP]
          outputPixelDensities: [0.5, 0.6, 0.7, 0.8, 1]
        )
      }
    }

    # MadeFrom Carousel
    allNodeIngredient(filter: { field_i_show_on_homepage: { eq: true }, langcode: { eq: $langKey } }, limit: 4) {
      nodes {
        title
        id
        path {
          alias
        }
        langcode
        field_i_image {
          alt
        }
        relationships {
          field_i_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  quality: 70
                  width: 346
                  webpOptions: { quality: 72 }
                  formats: [JPG, WEBP]
                  outputPixelDensities: [0.5, 0.6, 0.75, 1, 2]
                  aspectRatio: 1
                )
              }
            }
          }
        }
      }
    }
    # Links
    links: allMenuLinkContentMenuLinkContent(
      filter: { menu_name: { eq: "main" }, enabled: { eq: true } }
      sort: { fields: weight, order: ASC }
    ) {
      edges {
        node {
          langcode
          link {
            uri_alias
            uri
            options {
              attributes {
                target
              }
            }
            parent
          }
        }
      }
    }
    blogTexts: allNodePage(filter: { field_component: { eq: "lets-act" }, langcode: { eq: $langKey } }) {
      nodes {
        title
        body {
          value
        }
        langcode
      }
    }
  }
`
