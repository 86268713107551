import styled from '@emotion/styled'
import { colors } from 'src/themes'

export const Divider = styled.div`
  background-color: ${colors.orange};
  width: 0.1em;
`

export const SitemapWrapper = styled.div`
  margin: 0 auto;
`
