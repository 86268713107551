import { IntlShape } from 'gatsby-plugin-intl'
import { QuestionData } from '../types'

import question1 from './question1'
import question2 from './question2'
import question3 from './question3'
import question4 from './question4'
import question5 from './question5'
export { default as productData } from './products'

const INTL_TAG = 'quiz.questions'

export const questionData = (intl: IntlShape): QuestionData[] => [
  question1(intl, INTL_TAG),
  question2(intl, INTL_TAG),
  question3(intl, INTL_TAG),
  question4(intl, INTL_TAG),
  question5(intl, INTL_TAG)
]
